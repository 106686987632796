import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'semantic-ui-react'

const ImageSlider = ({ images }) => {

    // Shuffle images every time for unique map
    let shuffledImages = images
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    const [currentIndex, setCurrentIndex] = useState(0);
    const imageRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const nextIndex = (currentIndex + 1) % shuffledImages.length;
            setCurrentIndex(nextIndex);

            if (nextIndex % 7 === 0) {
                imageRef.current.scrollLeft = 0;
            } else {
                imageRef.current.scrollLeft += imageRef.current.offsetWidth;
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentIndex, shuffledImages.length]);


    return (
        <section id='HomePartnersSection'>
            <Container>
                <h1 className="ovo">
                    SPEDxchange Partners
                    &nbsp;
                </h1>

                <div className="image-slider-container">
                    <div className="image-slider" ref={imageRef}>
                        {shuffledImages.map((image, index) => (
                            <img
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                onClick={() => window.location.href = 'https://www.spedxchange.com/partners'}
                            />
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ImageSlider;
