import axios from 'axios'
import { HEADER_JSON } from '../../common/constants/apiConstants'
import {
  FETCH_ORG,
  FETCH_JOBS,
  FETCH_JOB,
  FETCH_EVENTS,
  UPDATE_JOBS,
  UPDATE_JOB,
  UPDATE_JOB_PROP,
  CLEAR_JOB,
  CLEAR_ORG,
  DELETE_JOB, FETCH_ANALYTIC
} from './AdminConstants'
import { LOGIN_SUCCESS } from '../../common/ui/auth/AuthContantants'
import { fetchOrganization, fetchUser } from '../../common/ui/auth/AuthActions'
import { asyncActionStart, asyncActionFinish, asyncActionError } from '../../common/actions/async/asyncActions'
import { toastr } from 'react-redux-toastr'

// Register Org User
export const registerOrg = (org, history) => {
  return async (dispatch) => {
    const body = JSON.stringify(org)
    try {
      const userToken = await axios.post('/api/organization/register', body, HEADER_JSON)
      const token = userToken.data.token;
      localStorage.setItem('token', token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: token,
      })
      dispatch(fetchUser())
      history.push('/admin')
    } catch (error) {
      console.log(error)
    }
  }
}

export const fetchOrg = (id) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const org = await axios.get(`/api/organization/${id}`)
      dispatch({ type: FETCH_ORG, payload: org.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchOrgAnalytics = (id) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const orgAnalytics = await axios.get(`/api/organization/analytics/${id}`)
      let orgAnalyticArr = [];
      for (let x = 0 ; x < orgAnalytics.data.length; x++) {
        let currAnalytic = orgAnalytics.data[x];
        if (currAnalytic.organization == id) {
          orgAnalyticArr.push(currAnalytic)
        }
      }
      dispatch({ type: FETCH_ANALYTIC, payload: orgAnalyticArr })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchEvents = (org) => {
  return async (dispatch) => {
    try {
      // const events = await axios.get(`/api/ga/events/update`)
      const body = JSON.stringify({ name: org })
      const events = await axios.post(`/api/ga/events/name`, body, HEADER_JSON)
      const totals = getTotals(events.data)
      const payload = {
        totals: totals,
        events: events.data
      }
      dispatch({ type: FETCH_EVENTS, payload: payload })
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const getTotals = (events) => {
  const result = {
    'Job Preview': { totalTime: 0, month: 0, week: 0, day: 0 },
    'Job Click': { totalTime: 0, month: 0, week: 0, day: 0 },
    'Job Apply Click': { totalTime: 0, month: 0, week: 0, day: 0 }
  }
  const views = [...new Set(events.map((e) => e.event))]
  views.forEach((view) => {
    const viewEvents = events.filter((e) => e.event === view)
    const periods = [...new Set(viewEvents.map((e) => e.period))]
    periods.forEach((period) => {
      result[view][period] = viewEvents
        .filter((i) => i.period === period)
        .map((e) => e.total)
        .reduce((sum, tot) => sum + tot)
    })
  })
  return result
}

export const fetchJobs = (orgId) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('admin-job-list'))
      const jobs = await axios.get(`/api/job/list/${orgId}`)
      dispatch({ type: FETCH_JOBS, payload: jobs.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchJob = (id, history) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-job'))
      const job = await axios.get(`/api/job/${id}`)
      await dispatch({ type: FETCH_JOB, payload: job.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
      if (history) {
        history.push(`/admin/job/new`)
      }
    }
  }
}

export const createJob = (job, history) => {
  const body = JSON.stringify(job)
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-job'))
      const newJob = await axios.post(`/api/job`, body, HEADER_JSON)
      await dispatch({ type: FETCH_JOB, payload: newJob.data })
      await dispatch(fetchOrganization(job.organization))
      dispatch(asyncActionFinish())
      if (history) {
        history.push(`/admin/job/${newJob.data._id}`)
      }
      toastr.success('Success!', 'Job Updated')
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const updateJobs = (jobs) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_JOBS,
      payload: jobs
    })
  }
}

export const updateJob = (job) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_JOB,
      payload: job
    })
  }
}

export const updateJobProp = (prop, value) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_JOB_PROP, payload: { prop: prop, value: value } })
  }
}

export const clearOrg = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ORG })
  }
}

export const clearJob = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_JOB })
  }
}

export const saveJob = (job) => {
  const body = JSON.stringify(job)
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const savedJob = await axios.put(`/api/job/${job._id}`, body, HEADER_JSON)
      await dispatch({ type: FETCH_JOB, payload: savedJob.data })
      await dispatch(fetchOrganization(job.organization))
      dispatch(asyncActionFinish())
      toastr.success('Success!', 'Job Updated')
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const deleteJob = (job) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      await axios.delete(`/api/job/${job._id}`)
      dispatch({ type: DELETE_JOB, payload: job._id })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}
