import React, { Component } from 'react';
import { Icon, Button } from 'semantic-ui-react';

export class GiveawayPage extends Component {
  handleSocialClick = (type) => {
    const url = window.location.protocol + '//' + window.location.host + '/scholarships/teacher';
    if (type === 'twitter') {
      window.open(
        `https://twitter.com/intent/tweet?text=SPEDxchange:%202020%20Special%20Education%20Teacher%20Scholarship%20$1,000&amp;url=${url}`,
        'twitter-share-dialog',
        'width=600,height=480'
      );
    }
    if (type === 'linkedin') {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}&amp;title=Special%20Education%20Teacher%20Scholarship%20$1,000`,
        'linkedin-share-dialog',
        'width=600,height=480'
      );
    }
    if (type === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        'facebook-share-dialog',
        'width=600,height=480'
      );
    }
  };

  setEmailLink = () => {
    const url = window.location.protocol + '//' + window.location.host + '/scholarships/teacher';
    return `mailto:?subject=2020%20Special%20Education%20Teacher%20Scholarship%20$1,000&body=I thought you might be interested in reading this SPED Talk article.%0D%0A%0D%0A2020%20Special%20Education%20Teacher%20Scholarship%20$1,000%0D%0A${url}%0D%0A%0D%0A`.replace(
      / /g,
      '%20'
    );
  };

  render() {
    return (
      <div className='resources'>
        <div>
          <h5 className='section-head mb-3'>SPEDxchange Weekly Giveaway</h5>
          <div className='article-wrap'>
            <div className='article'>
              <div>
                <div>
                  <img src='/assets/img/scholarship.png' alt='Special Education Teacher Scholarship' />
                </div>
              </div>
              <div className='article-figure'>
                <div className='share'>SHARE</div>
                <Icon link circular name='twitter' onClick={() => this.handleSocialClick('twitter')} />
                <Icon link circular name='linkedin' onClick={() => this.handleSocialClick('linkedin')} />
                <Icon link circular name='facebook' onClick={() => this.handleSocialClick('facebook')} />
                <a href={this.setEmailLink()}>
                  <Icon link circular name='envelope outline' />
                </a>
              </div>
              <h1 className='text-primary my-2'>
                <strong>Giveaway Title Here</strong>
              </h1>
              <p>
                Giveaway copy here. Given hair from hiding findings. Follow in of the phase. Far of happening, that
                readiness makers have being with spot. Have of presentations. Bale achievements on turned about room.
                This like…. Funds parent, mail been similar was text temple high met be goals may every and the with
                quietly with a succeeded the release an music. Advised been municipal a at himself long and question to
                unmolested work and what caching office don't the they minutes all will employees, up partially she else
                pay the high both view for thought, past is transmitting seven her phase they ocean. Millions or.
              </p>
              <Button color='green' className='mb-3'>
                Sign up to be automatically entered every week!
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GiveawayPage;
