import { Button, Form } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import React, { Component } from 'react'

import { Container } from 'semantic-ui-react'
import PlaceInput from '../../../common/ui/form/PlaceInput'
import TextInput from '../../../common/ui/form/TextInput'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

export class HomeFindJob extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit() {
    this.props.history.push('/jobs')
  }

  render() {
    return (
      <section>
        <Container>
          <br></br>
          <Form onSubmit={this.onSubmit} autoComplete='off'>
            <div className='flex-box start sm jobs-search-form'>
              <div className='term grow'>
                <Field
                  name='q'
                  type='text'
                  component={TextInput}
                  placeholder='Job Title or Keywords'
                />
                <strong>What</strong>
              </div>
              <div className='location grow'>
                <Field
                  type='text'
                  name='l'
                  component={PlaceInput}
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'us' },
                  }}
                  onSelect={this.handleCitySelect}
                  placeholder='City, State'
                />
                <strong>Where</strong>
              </div>
              <div className='submit'>
                <Button onClick={this.onSubmit} type='submit' color='green'>
                  Find Jobs!
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </section>
    )
  }
}

export default connect()(
  reduxForm({ form: 'homeFindJob' })(withRouter(HomeFindJob))
)
