import React, { Component } from 'react'

const generateUUID = () => {
  return `${new Date().getTime()}`
}

export class EditableSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovering: false,
      hover: null,
      el: this.props.el || generateUUID(),
    }
    this.handleHover = this.handleHover.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  handleEditClick() {
    this.props.handleEdit()
    const h = this.props.isEditMode ? this.state.el : null
    this.setState({ ...this.state, hover: h })
  }

  handleRemoveClick() {
    this.props.handleRemove()
    setTimeout(() => {
      const h = this.props.isEditMode ? this.state.el : null
      this.setState({ ...this.state, hover: h })
    }, 300)
  }

  handleHover(el, hovering) {
    this.setState({ ...this.state, hovering: hovering, hover: el })
  }

  render() {
    const { children, isEditMode, removable, hideEditMode } = this.props
    const { el, hovering, hover } = this.state
    const removeClass = removable ? 'removable ' : ''
    const editClass = hideEditMode ? 'editable ' : 'editable enabled '
    return (
      <section
        className={
          (hovering && hover === el) || isEditMode
            ? `${removeClass}${editClass} active`
            : `${removeClass}${editClass}`
        }>
        {hideEditMode && (
          <div
            title='Edit'
            className='edit-trigger'
            onClick={this.handleEditClick}
            onMouseEnter={() => this.handleHover(el, true)}
            onMouseLeave={() =>
              this.handleHover(isEditMode ? el : null, false)
            }></div>
        )}
        {removable && (
          <div
            title='Remove'
            className='remove-trigger'
            onClick={this.handleRemoveClick}
            onMouseEnter={() => this.handleHover(el, true)}
            onMouseLeave={() =>
              this.handleHover(isEditMode ? el : null, false)
            }></div>
        )}
        {children}
      </section>
    )
  }
}

export default EditableSection
