import axios from 'axios'
import { SubmissionError } from 'redux-form'
import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR,
} from '../../actions/async/asyncConstants'
import {
  USER_LOADED,
  AUTH_ERROR,
  LOAD_CLIENTS,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE,
  TOGGLE_FORGOT_PASSWORD,
  FETCH_SCHOLARSHIP_APPLICATION,
  SET_RECAPTCHA_TOKEN,
  SET_REGISTER_STEP,
  SET_EMPLOYER,
  UPDATE_PROFILE,
  USER_LOADING,
} from './AuthContantants'
import { HEADER_JSON } from '../../constants/apiConstants'
import { closeModal } from '../../ui/modal/ModalActions'
import { toastr } from 'react-redux-toastr'
import setAuthToken from '../../util/setAuthToken'
import * as FileSaver from 'file-saver'
import {
  CLEAR_ORG,
  FETCH_ORG,
} from '../../../feature/admin/AdminConstants'

export const initUser = {
  title: '',
  headline: '',
  summary: '',
  location: '',
  phone: '',
  experience: [],
  education: [],
  certifications: [],
  linkedin: '',
  twitter: '',
  instagram: '',
  facebook: '',
  website: '',
  visible: false,
  relocate: false,
  isJobSeeker: false,
  isPrivatePractive: false,
  isSpedAdministrator: false,
  isSpedProfessional: false,
  isSpedStudent: false,
  isSpedTeacher: false,
}

export const toTitleCase = (str) => {
  if (!str) return ''
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const tranformClientList = (list) => {
  if (!list) return null
  const retList = []
  for (let item of list) {
    const o = { key: item._id, value: item._id, text: item.name }
    retList.push(o)
  }
  return retList
}

export const testUser = (user) => {
  if (!user) return initUser
  const tmp = Object.assign({}, user)
  delete tmp['created']
  delete tmp['updated']
  delete tmp['__v']
  return tmp
}

export const fetchUser = () => {
  return async (dispatch) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token)
    } else {
      dispatch({ type: AUTH_ERROR })
      return
    }
    try {
      const fetch = await axios.get('/api/auth/user')
      const user = testUser(fetch.data?.user)
      dispatch({ type: USER_LOADED, payload: { ...initUser, ...user } })
      if (
        !sessionStorage.init &&
        user.organization &&
        user.organization.length > 0
      ) {
        sessionStorage.setItem('init', user.organization)
      }
      if (sessionStorage.init) {
        dispatch(fetchClientList())
        dispatch(fetchOrganization(sessionStorage.init))
      }
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
      })
    }
  }
}

export const fetchOrganization = (organizationId) => {
  return async (dispatch) => {
    try {
      const org = await axios.get(`/api/organization/${organizationId}`)
      sessionStorage.setItem('init', organizationId)
      dispatch({ type: FETCH_ORG, payload: org.data })
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const fetchClientList = () => {
  return async (dispatch) => {
    try {
      const clientList = await axios.get('/api/organization/clients')
      const data = tranformClientList(clientList.data)
      dispatch({
        type: LOAD_CLIENTS,
        payload: data,
      })
    } catch (error) {
      console.error(error.message)
    }
  }
}

// Register User
export const registerUser = (user, history) => {
  return async (dispatch) => {
    const userData = {
      displayName: toTitleCase(user.displayName),
      email: user.email.toLowerCase(),
      title: user.title,
      password: user.password,
      isEmployer: user.isEmployer,
    }
    const body = JSON.stringify(userData)
    try {
      const userToken = await axios.post('/api/users', body, HEADER_JSON)
      const token = userToken.data.token
      localStorage.setItem('token', token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: token,
      })
      dispatch(fetchUser())
      dispatch(closeModal())
      if (user.isEmployer) {
        history.push('/admin')
      }
    } catch (error) {
      throw new SubmissionError({
        _error: 'Sign Up Failed',
      })
    }
  }
}

export const updateUserProfile = (profile) => {
  return async (dispatch) => {
    const initUser = {
      title: '',
      headline: '',
      summary: '',
      location: '',
      phone: '',
      activities: [],
      experience: [],
      education: [],
      certifications: [],
      linkedin: '',
      twitter: '',
      instagram: '',
      facebook: '',
      website: '',
      visible: false,
      relocate: false,
    }
    const profileCopy = Object.assign({}, profile)
    delete profileCopy['fileData']
    delete profileCopy['file']
    try {
      if (profile.file && profile.file !== null) {
        let formData = new FormData()
        formData.append('file', profile.fileData)
        await axios.post(`/api/upload/profile/user/${profile._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        const parts = profile.fileData.name.split('.')
        const ext = parts[parts.length - 1]
        profileCopy.avatar = `https://spedxchange.s3.us-east-2.amazonaws.com/user/${profile._id}/avatar.${ext}`
      }
      const body = { ...initUser, ...profileCopy }
      await axios.put('/api/users/profile', JSON.stringify(body), HEADER_JSON)
      await dispatch(fetchUser())
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const addAdmin = (values) => {
  return async (dispatch) => {
    const adminData = {
      displayName: toTitleCase(values.displayName),
      email: values.email.toLowerCase().trim(),
      title: values.title,
      phone: values.phone,
      organization: values.organization,
    }
    const body = JSON.stringify(adminData)
    try {
      await axios.post('/api/users/add/admin', body, HEADER_JSON)
      await dispatch(fetchOrganization(values.organization))
      // dispatch(welcomeAdmin())
    } catch (error) {
      throw new SubmissionError({
        _error: 'Add Admin Failed',
      })
    }
  }
}

export const updateAdmin = (admin, org) => {
  return async (dispatch) => {
    try {
      await axios.post(
        '/api/users/edit/admin',
        JSON.stringify(admin),
        HEADER_JSON
      )
      await dispatch(fetchOrganization(org))
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const deleteAdmin = (admin) => {
  return async (dispatch) => {
    try {
      await axios.post(
        '/api/users/delete/admin',
        JSON.stringify(admin),
        HEADER_JSON
      )
      await dispatch(fetchOrganization(admin.organization))
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const addLocation = (location) => {
  return async (dispatch) => {
    const body = JSON.stringify(location)
    try {
      await axios.post('/api/users/add/location', body, HEADER_JSON)
      await dispatch(fetchOrganization(location.organization))
    } catch (error) {
      throw new SubmissionError({
        _error: 'Add Admin Failed',
      })
    }
  }
}

export const updateLocation = (location, org) => {
  return async (dispatch) => {
    try {
      await axios.post(
        '/api/users/edit/location',
        JSON.stringify(location),
        HEADER_JSON
      )
      await dispatch(fetchOrganization(org))
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const deleteLocation = (location) => {
  return async (dispatch) => {
    try {
      await axios.post(
        '/api/users/delete/location',
        JSON.stringify(location),
        HEADER_JSON
      )
      await dispatch(fetchOrganization(location.organization))
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const resumeDownload = (userData) => {
  return async (dispatch) => {
    const uuid = new Date().getUTCMilliseconds()
    fetch('/api/users/resume', {
      method: 'POST',
      body: JSON.stringify(userData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => {
        return resp.blob()
      })
      .then((blob) => {
        FileSaver.saveAs(blob, `${userData.lastName}-resume-${uuid}.docx`)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const updateProfile = (prop, value) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PROFILE, payload: { prop: prop, value: value } })
  }
}

export const transformUserInfo = (data) => {
  return {
    firstname: data.firstName,
    lastname: data.lastName,
    email: data.email,
    phone: data.phone,
    sped_organization_id: data.sped_organization_id,
  }
}

export const welcomeUser = () => {
  return async () => {
    try {
      const userInfo = await axios.get('/api/auth/user')
      const crmInfo = transformUserInfo(userInfo.data)
      const body = JSON.stringify(crmInfo)
      await axios.post('/api/crm/contact', body, HEADER_JSON)
    } catch (error) {
      console.error(error.message)
    }
  }
}

export const welcomeAdmin = (admin) => {
  return async () => {
    try {
      const userInfo = await axios.get('/api/getAdmin')
      const crmInfo = transformUserInfo(userInfo.data)
      const body = JSON.stringify(crmInfo)
      await axios.post('/api/crm/contact', body, HEADER_JSON)
    } catch (error) {
      console.error(error.message)
    }
  }
}

// Login User
export const login = (creds) => {
  return async (dispatch) => {
    try {
      dispatch({type: USER_LOADING})
      const body = JSON.stringify(creds)
      const userToken = await axios.post('/api/auth/login', body, HEADER_JSON)
      localStorage.setItem('token', userToken.data.token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: userToken.data,
      })
      dispatch(fetchUser())
      dispatch(closeModal())
    } catch (error) {
      dispatch({ type: AUTH_ERROR })
      throw new SubmissionError({
        _error: 'Login Failed',
      })
    }
  }
}

// Logout / Clear Profile
export const signOut = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PROFILE })
    dispatch({ type: CLEAR_ORG })
    dispatch({ type: LOGOUT })
  }
}

// Request Reset Password Email
export const requestResetInstructions = (form) => {
  return async (dispatch) => {
    try {
      const body = JSON.stringify(form)
      dispatch({ type: ASYNC_ACTION_START, payload: 'request-password-reset' })
      const resp = await axios.post(
        '/api/auth/request-reset',
        body,
        HEADER_JSON
      )
      const msg = `Check your email inbox at for the password reset instructions.`
      const err = `No account for ${form.email} was found.`
      const confirmMessage = !resp.data || !resp.data.success ? err : msg
      dispatch(closeModal())
      dispatch({ type: ASYNC_ACTION_FINISH })
      toastr.confirm(confirmMessage, {
        okText: 'Close',
        disableCancel: true,
      })
      dispatch({ type: TOGGLE_FORGOT_PASSWORD })
      return null
    } catch (error) {
      dispatch({ type: ASYNC_ACTION_ERROR })
      throw new SubmissionError({
        _error: 'Request Failed',
      })
    }
  }
}

// Update Password
export const updatePassword = (form) => {
  return async (dispatch) => {
    try {
      const body = JSON.stringify(form)
      dispatch({ type: ASYNC_ACTION_START, payload: 'update-password' })
      await axios.post('/api/auth/reset', body, HEADER_JSON)
      dispatch({ type: ASYNC_ACTION_FINISH })
      dispatch(closeModal())
      toastr.success('Success', 'Your password has been updated')
    } catch (error) {
      dispatch({ type: ASYNC_ACTION_ERROR })
      throw new SubmissionError({
        _error: error.message,
      })
    }
  }
}

export const toggleForgotPassword = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_FORGOT_PASSWORD })
  }
}

export const submitScholarshipApplication = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ASYNC_ACTION_START, payload: 'submit-scholarship' })
      const body = JSON.stringify(form)
      await axios.post('/api/auth/submit-scholarship', body, HEADER_JSON)
      dispatch({ type: ASYNC_ACTION_FINISH })
      dispatch(closeModal())
      toastr.success('Success', 'Your Application has been submitted!')
    } catch (error) {
      dispatch({ type: ASYNC_ACTION_ERROR })
      throw new SubmissionError({
        _error: error.message,
      })
    }
  }
}

export const fetchScholarshipApplication = (scholarshipName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ASYNC_ACTION_START, payload: 'fetch-scholarship' })
      const body = JSON.stringify({ scholarshipName: scholarshipName })
      const application = await axios.post(
        '/api/auth/scholarship-application',
        body,
        HEADER_JSON
      )
      dispatch({ type: ASYNC_ACTION_FINISH })
      if (application.data.essay) {
        dispatch({
          type: FETCH_SCHOLARSHIP_APPLICATION,
          payload: application.data,
        })
      }
    } catch (error) {
      dispatch({ type: ASYNC_ACTION_ERROR })
      throw new SubmissionError({
        _error: error.message,
      })
    }
  }
}

// Recaptcha
export const setRecaptchaToken = (token) => {
  return (dispatch) => {
    dispatch({ type: SET_RECAPTCHA_TOKEN, payload: token })
  }
}

// Set Register Step
export const setRegisterStep = (step) => {
  return (dispatch) => {
    dispatch({ type: SET_REGISTER_STEP, payload: step })
  }
}

// Set Employer State
export const setIsEmployer = (isEmployer) => {
  return (dispatch) => {
    dispatch({ type: SET_EMPLOYER, payload: isEmployer })
  }
}
