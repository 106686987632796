import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { toggleMobileNav, toggleSearchBar, navItemClick } from '../navActions'
import { openModal } from '../../modal/ModalActions'
import { signOut } from '../../auth/AuthActions'

import GuestMenu from './menus/GuestMenu'
import AuthMenu from './menus/AuthMenu'
import logo from './spedxchange-brand.svg'

class NavBar extends Component {
  handleLogin = () => {
    this.props.openModal('LoginModal')
  }

  handlePostJob = () => {
    this.props.history.push('/pricing')
  }

  handleSignUp = () => {
    this.props.openModal('RegisterModal')
  }

  handleSignOut = () => {
    this.props.signOut()
  }

  handleMenuNavigation = (path) => {
    this.props.history.push(path)
  }

  toggleNav = () => {
    this.props.toggleMobileNav()
  }

  render() {
    // if(!this.props.auth.loaded) return <></>
    const { auth } = this.props
    const showSearch = false
    return (
      <div className='app-header'>
        <div className='flex-box'>
          <Link className='brand' to='/'>
            <img src={logo} alt='SPEDxchange' />
          </Link>
          <div className='flex-box grow nav-content'>
            {auth.authenticated ? (
              <AuthMenu
                auth={auth}
                onNav={this.handleMenuNavigation}
                signOut={this.handleSignOut}
              />
            ) : (
              <GuestMenu
                postJob={this.handlePostJob}
                login={this.handleLogin}
                register={this.handleSignUp}
              />
            )}
            {showSearch && (
              <button className='square' onClick={this.props.toggleSearchBar}>
                <Icon name='search' />
              </button>
            )}
            <button
              id='MobileNavButton'
              className='square mobile'
              onClick={this.toggleNav}>
              <Icon id="MobileNavButtonBars" name='bars' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  auth: state.auth,
  activeNavItem: state.nav.activeNavItem,
  isMobileNavOpen: state.nav.isMobileNavOpen,
})

const actions = {
  toggleSearchBar,
  toggleMobileNav,
  navItemClick,
  openModal,
  signOut,
}

export default connect(mapState, actions)(withRouter(NavBar))
