
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { fetchLocalJobs } from './actions/jobsActions'
import { geocodeByAddress } from 'react-places-autocomplete'
import { reduxForm, Field } from 'redux-form'
import { combineValidators, isRequired } from 'revalidate'
import { Form, Button, Grid, Segment } from 'semantic-ui-react'
import JobFilters from './components/JobFilters'
import JobDetails from './components/JobDetails'
import JobsListLocalItem from './JobsListLocalItem'
import TextInput from '../../common/ui/form/TextInput'
import PlaceInput from '../../common/ui/form/PlaceInput'

import * as qs from 'query-string'

const mapState = (state) => ({
  backfillJobs: state.jobs.backfillJobs,
  localJobs: state.jobs.localJobs,
  searchLocation: state.async.searchLocation,
})

const actions = {
  fetchLocalJobs,
}

const validate = combineValidators({
  l: isRequired({
    message: 'City, State or Zip is required',
  }),
  q: isRequired({
    message: 'Job Title or Keywords are required',
  }),
})

export class JobsMain extends Component {
  state = { query: '', job: '' }

  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleCitySelect = this.handleCitySelect.bind(this)
    this.fetchAllJobs = this.fetchAllJobs.bind(this)
  }

  componentDidMount() {
    let query = qs.parse(this.props.location.search)
    if (!query.q) query.q = `Special Education`
    if (!query.l)
      query.l = `${this.props.searchLocation.city}, ${this.props.searchLocation.state}`
    if (!query.d) query.d = `50`
    if (!query.j) query.d = ''
    this.props.change('q', query.q)
    this.props.change('l', query.l)
    this.props.change('d', query.d)
    this.props.change('j', query.j)
    this.setState({
      query: qs.stringify({ q: query.q, l: query.l, d: query.d }),
      job: query.j,
    })
    this.fetchAllJobs(query)
  }

  componentDidUpdate(prev) {
    if (prev.location.search !== this.props.location.search) {
      let query = qs.parse(this.props.location.search)
      if (!query.q) query.q = `Special Education`
      if (!query.l)
        query.l = `${this.props.searchLocation.city}, ${this.props.searchLocation.state}`
      if (!query.d) query.d = `50`
      if (!query.j) query.d = ''
      this.props.change('q', query.q)
      this.props.change('l', query.l)
      this.props.change('d', query.d)
      this.props.change('j', query.j)
      this.setState({
        query: qs.stringify({ q: query.q, l: query.l, d: query.d }),
        job: query.j,
      })
      this.fetchAllJobs(query)
    }
  }

  fetchAllJobs(values) {
    const { q, l, d, j } = values
    if (!q || !l) {
      return
    }
    const params = {
      q: q.trim(),
      l: l.trim(),
      d: d || '50',
      j: j || '',
    }
    this.props.fetchLocalJobs(params)
  }

  onSubmit(values) {
    let { q, l, d, j } = values
    if (!q) q = `Special Education`
    if (!l)
      l = `${this.props.searchLocation.city}, ${this.props.searchLocation.state}`
    if (!d) d = `50`
    if (!j) j = ``

    const params = {
      q: q.trim(),
      l: l.trim(),
      d: d,
      j: j,
    }
    this.props.history.push(`/jobs?${qs.stringify(params)}`)
  }

  handleCitySelect(selectedCity) {
    geocodeByAddress(selectedCity).then(() => {
      this.props.change('l', selectedCity.replace(', USA', ''))
    })
  }

  render() {
    const { localJobs } = this.props
    return (
        <>
          <Form
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              autoComplete='off'>
            <div className='flex-box start sm jobs-search-form'>
              <div className='term grow'>
                <Field
                    name='q'
                    type='text'
                    component={TextInput}
                    placeholder='Job Title or Keywords'
                />
                <strong>What</strong>
              </div>
              <div className='location grow'>
                <Field
                    type='text'
                    name='l'
                    component={PlaceInput}
                    options={{
                      types: ['(cities)'],
                      componentRestrictions: { country: 'us' },
                    }}
                    onSelect={this.handleCitySelect}
                    placeholder='City, State'
                />
                <strong>Where</strong>
              </div>
              <div className='submit'>
                <Button type='submit' color='green'>
                  Find Jobs!
                </Button>
              </div>
            </div>
          </Form>
          <JobFilters />
          <Grid stackable reversed='mobile'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {localJobs && localJobs.length > 0 && (
                  <>
                    <div className='job-list mb-5'>
                      {localJobs.map((job) => (
                          <JobsListLocalItem
                              key={job._id}
                              job={job}
                              q={this.state.query}
                              active={job._id === this.state.job}
                          />
                      ))}
                    </div>
                  </>
              )}
              {(!localJobs || localJobs.length < 1) && (
                  <div>No Search Results</div>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='sticky-job'>
                {this.state.job && this.state.job.length > 0 && (
                    <Segment>
                      <JobDetails jobId={this.state.job}></JobDetails>
                    </Segment>
                )}
                <div>
                  <hr />
                  <Link to='/profile'><Button type='submit' color='green'>
                    Create my Resume
                  </Button>
                  </Link>
                  <br />
                  Try it today! Update your profile and download a formatted resume.
                  <hr />
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </>
    )
  }
}

export default connect(
    mapState,
    actions
)(reduxForm({ form: 'jobSearchForm', validate })(withRouter(JobsMain)))

