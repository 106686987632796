/* eslint no-useless-escape: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ReCaptcha } from 'react-recaptcha-v3'
import { Form, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import { combineValidators, isRequired, composeValidators, createValidator } from 'revalidate'
import { addAdmin, setRecaptchaToken } from '../AuthActions'
import { openModal, closeModal } from '../../modal/ModalActions'
import TextInput from '../../form/TextInput'
import PhoneInput from '../../form/PhoneInput'

const mapState = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  org: state.admin.org,
  user: state.auth.currentUser
})

const actions = {
  addAdmin,
  setRecaptchaToken,
  openModal,
  closeModal
}

const isPhoneLength = createValidator(
  (message) => (value) => {
    if (value && value.length !== 14) {
      return message
    }
  },
  'Invalid email address'
)

const isValidEmail = createValidator(
  (message) => (value) => {
    if (
      value &&
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        value
      )
    ) {
      return message
    }
  },
  'Invalid email address'
)

const validate = combineValidators({
  displayName: isRequired({ message: 'Your Name is required' }),
  email: composeValidators(isRequired({ message: 'Email is required' }), isValidEmail({ message: 'Invalid Email' }))(),
  title: isRequired({ message: 'Title is required' }),
  phone: composeValidators(
    isRequired({ message: 'Phone is required' }),
    isPhoneLength({ message: 'Invalid Phone Number' })
  )()
})

export class RegisterAdminForm extends Component {
  onAddAdmin = (values) => {
    // console.log('values: ', values)
    // console.log('user: ', this.props.user)
    // console.log('org: ', this.props.org)
    // this.props.addAdmin(values)
  }

  verifyCallback = (recaptchaToken) => {
    this.props.setRecaptchaToken(recaptchaToken)
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props
    return (
      <Form className='register-form' onSubmit={handleSubmit(this.onAddAdmin)} autoComplete='off'>
        <ReCaptcha
          sitekey='6LdfOb8UAAAAAJg87yIa2NJwxwP8ZkJJg18XGG1M'
          action='signup'
          verifyCallback={this.verifyCallback}
        />
        <label>Name</label>
        <Field type='text' name='displayName' component={TextInput} required />
        <label>Email</label>
        <Field type='text' name='email' component={TextInput} required />
        <label>Title</label>
        <Field type='text' name='title' component={TextInput} required />
        <label>Phone</label>
        <Field type='text' name='phone' component={PhoneInput} required />
        <div className='pt-3'>
          <Button disabled={pristine || invalid || submitting} loading={submitting} color='green'>
            Add Administrator
          </Button>
        </div>
      </Form>
    )
  }
}

export default connect(mapState, actions)(reduxForm({ form: 'registerForm', validate })(withRouter(RegisterAdminForm)))
