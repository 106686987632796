import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from './common/ui/auth/AuthGuard'

import About from './feature/about/About'
import Admin from './feature/admin/Admin'
import AdminJobForm from './feature/admin/component/jobs/AdminJobForm'
import AdminJobList from './feature/admin/component/jobs/AdminJobList'
import AdminLanding from './feature/admin/AdminLanding'
import AdminLocationList from './feature/admin/component/locations/AdminLocationList'
import AdminUserList from './feature/admin/component/users/AdminUserList'
import AdministratorsPage from './feature/administrators/AdministratorsPage'
import Appfooter from './common/ui/footer/AppFooter'
import { Container } from 'semantic-ui-react'
import Custom404 from './feature/custom404/Custom404'
import PartnersPage from "./feature/partners/PartnersPage";
import DistrictDetailsPage from './feature/report/component/districts/DistrictDetailsPage'
import DistrictsMain from './feature/report/component/districts/DistrictsMain'
import ExternalNewsForm from './feature/report/component/news/ReportNewsExternalForm'
import ReportNewsBloggerForm from "./feature/report/component/news/ReportNewsBloggerForm";
import GiveawayPage from './feature/giveaway/GiveawayPage'
import { Helmet } from 'react-helmet'
import HomePage from './feature/home/HomePage'
import JobDetailPage from './feature/jobs/JobDetailPage'
import JobsMain from './feature/jobs/JobsMain'
import LoginForm from './common/ui/auth/login/LoginForm'
import ModalManager from './common/ui/modal/ModalManager'
import NavBar from './common/ui/nav/navBar/NavBar'
import NavMobile from './common/ui/nav/navMobile/NavMobile'
import NewsArticle from './feature/news/article/NewsArticle'
import NewsForm from './feature/report/component/news/ReportNewsForm'
import NewsMain from './feature/news/main/NewsMain'
import PrivacyPolicy from "./feature/legal/PrivacyPolicy";
import ProfessionalsPage from './feature/professionals/ProfessionalsPage'
import ProfilePage from './feature/profile/ProfilePage'
import QuestionCategoryPage from './feature/question/categories/QuestionCategoryPage'
import QuestionDashboard from './feature/question/dashboard/QuestionDashboard'
import QuestionDetail from './feature/question/questionDetail/QuestionDetail'
import QuestionForm from './feature/question/questionForm/QuestionForm'
import PricingPage from "./feature/pricing/PricingPage";
import ReactGA from 'react-ga'
import Report from './feature/report/Report'
import ReportMembersPage from './feature/report/component/member/ReportMembersPage'
import ReportNewsPage from './feature/report/component/news/ReportNewsPage'
import ReportOrganizationJobPage from './feature/report/component/jobs/ReportOrganizationJobPage'
import ReportOrganizationsDetailPage from './feature/report/component/organization/ReportOrganizationsDetailPage'
import ReportOrganizationsPage from './feature/report/component/organization/ReportOrganizationsPage'
import Resources from './feature/resources/Resources'
import SavedJobs from './feature/profile/components/SavedJobs'
import ScholarshipReview from './feature/dashboard/component/ScholarshipReview'
import ScholarshipTeacher from './feature/scholarships/ScholarshipTeacher'
import Scholarships from './feature/scholarships/Scholarships'
import SearchBar from './feature/search/searchBar/SearchBar'
import SideBar from './common/ui/nav/sideBar/SideBar'
import TermsConditions from "./feature/legal/TermsConditions";
import { UserCanAsk } from './common/ui/auth/AuthWrapper'
import { connect } from 'react-redux'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { setSearchLocation } from './common/actions/async/asyncActions'

const mapState = (state) => ({
  auth: state.auth,
  nav: state.nav,
  searchLocation: state.async.searchLocation
})

const mapActions = {
  setSearchLocation
}

const Login = userIsNotAuthenticatedRedir(LoginForm)
const Profile = userIsAuthenticatedRedir(ProfilePage)
const Saved = userIsAuthenticatedRedir(SavedJobs)
const AdminScholarshipReview = userIsAuthenticatedRedir(ScholarshipReview)
const AdminHome = userIsAuthenticatedRedir(Admin)
const AdminJobs = userIsAuthenticatedRedir(AdminJobList)
const AdminUsers = userIsAuthenticatedRedir(AdminUserList)
const AdminLocations = userIsAuthenticatedRedir(AdminLocationList)
const AdminJob = userIsAuthenticatedRedir(AdminJobForm)
const ReportMain = userIsAuthenticatedRedir(Report)
const ReportMembers = userIsAuthenticatedRedir(ReportMembersPage)
const ReportOrganizations = userIsAuthenticatedRedir(ReportOrganizationsPage)
const ReportOrganizationsDetail = userIsAuthenticatedRedir(ReportOrganizationsDetailPage)
const ReportOrganizationsJob = userIsAuthenticatedRedir(ReportOrganizationJobPage)
const ReportNews = userIsAuthenticatedRedir(ReportNewsPage)
const ReportNewsForm = userIsAuthenticatedRedir(NewsForm);
const ReportDistricts = userIsAuthenticatedRedir(DistrictsMain);
const ReportDistrictsDetail = userIsAuthenticatedRedir(DistrictDetailsPage);

const ExtNewsForm = userIsAuthenticatedRedir(ExternalNewsForm);
const BloggerNewsForm = userIsAuthenticatedRedir(ReportNewsBloggerForm);

class App extends Component {
  componentDidMount() {
    loadReCaptcha('6LdfOb8UAAAAAJg87yIa2NJwxwP8ZkJJg18XGG1M')
    this.props.setSearchLocation()
    ReactGA.initialize('UA-148366396-1')
    ReactGA.pageview(this.props.location.pathname + this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname + this.props.location.search !==
      prevProps.location.pathname + prevProps.location.search
    ) {
      ReactGA.pageview(this.props.location.pathname + this.props.location.search)
    }
  }

  render() {
    let seoTags = Object.assign({}, this.props.nav.seoTags)
    let pathName = this.props.location.pathname.split('/')[1] || 'home'
    if (pathName === 'mv') pathName = 'home'
    if (isNotFollow(pathName)) seoTags = notFollowSEO
    return (
      <>
        <Helmet>
          <title>{seoTags.title}</title>
          <meta name='description' content={seoTags.description} />
          <meta name='robots' content={seoTags.robots} />
          <meta name='keywords' content={seoTags.keywords} />
          <meta property='og:title' content={seoTags.title} />
          <meta property='og:description' content={seoTags.description} />
          <meta property='og:image' content={seoTags.image} />
          <meta property='og:url' content={seoTags.url} />
          <meta property='og:type' content={seoTags.type} />
          <meta name='twitter:title' content={seoTags.title} />
          <meta name='twitter:description' content={seoTags.description} />
          <meta name='twitter:image' content={seoTags.image} />
          <meta name='twitter:url' content={seoTags.url} />
          <meta name='twitter:card' content={seoTags.card} />
          <script type='application/ld+json'>{JSON.stringify(seoTags.seoScript)}</script>
        </Helmet>
        <ModalManager />
        <Route exact path='/' component={HomePage} />
        <Route exact path='/mv' component={HomePage} />
        {pathName !== 'home' && (
          <Route
            path='/(.+)'
            render={() => (
              <>
                <NavBar />
                <SearchBar />
                <Container className={`main ${pathName}`}>
                  <div className='content'>
                    <Switch key={this.props.location.key}>
                      <Route path='/login' component={Login} />
                      <Route exact path='/questions' component={QuestionDashboard} />
                      <Route path='/questions/:uid/:slug' component={QuestionDetail} />
                      <Route path='/categories' component={QuestionCategoryPage} />
                      <Route
                        path={['/ask', '/ask/:id', '/ask/:uid/:slug']}
                        component={UserCanAsk(withRouter(QuestionForm))}
                      />
                      <Route exact path='/professionals' component={ProfessionalsPage} />
                      <Route exact path='/administrators' component={AdministratorsPage} />
                      <Route exact path='/partners' component={PartnersPage} />
                      <Route exact path='/giveaway' component={GiveawayPage} />
                      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                      <Route exact path="/terms-conditions" component={TermsConditions} />
                      <Route exact path='/news' component={NewsMain} />
                      <Route path='/user/reset/:token' component={NewsMain} />
                      <Route path='/news/:uid/:slug' component={NewsArticle} />
                      <Route exact path='/scholarships' component={Scholarships} />
                      <Route exact path='/scholarships/teacher' component={ScholarshipTeacher} />
                      <Route path='/resources' component={Resources} />
                      <Route path='/pricing' component={PricingPage} />
                      <Route path='/about' component={About} />
                      <Route exact path='/jobs' component={JobsMain} />
                      <Route path='/jobs/:id' component={JobDetailPage} />
                      <Route exact path='/profile' component={Profile} />
                      <Route exact path='/profile/jobs' component={Saved} />
                      <Route exact path='/postjob' component={AdminLanding} />
                      <Route exact path='/admin' component={AdminHome} />
                      <Route exact path='/admin/jobs' component={AdminJobs} />
                      <Route exact path='/admin/users' component={AdminUsers} />
                      <Route exact path='/admin/locations' component={AdminLocations} />
                      <Route path='/admin/job/:id' component={AdminJob} />
                      <Route exact path='/spedxchange-reports' component={ReportMain} />
                      <Route exact path='/spedxchange-reports/districts' component={ReportDistricts} />
                      <Route exact path='/spedxchange-reports/districts/:id' component={ReportDistrictsDetail} />
                      <Route exact path='/spedxchange-reports/news' component={ReportNews} />
                      <Route exact path='/spedxchange-reports/news/external/:id' component={ExtNewsForm} />
                      <Route exact path='/spedxchange-reports/news/blogger/:id' component={BloggerNewsForm} />
                      <Route exact path='/spedxchange-reports/news/:id' component={ReportNewsForm} />
                      <Route exact path='/spedxchange-reports/members' component={ReportMembers} />
                      <Route exact path='/spedxchange-reports/organizations' component={ReportOrganizations} />
                      <Route
                        exact
                        path='/spedxchange-reports/organizations/:orgId'
                        component={ReportOrganizationsDetail}
                      />
                      <Route
                        exact
                        path='/spedxchange-reports/organizations/:orgId/job/:jobId'
                        component={ReportOrganizationsJob}
                      />
                      <Route exact path='/scholarship-review/:scholarshipName' component={AdminScholarshipReview} />
                      <Route path="*" component={Custom404} />
                    </Switch>
                  </div>
                </Container>
                <NavMobile />
                <Appfooter auth={this.props.auth} />
                {this.props.nav.isSideBarOpen && <SideBar />}
              </>
            )}
          />
        )}
      </>
    )
  }
}

const isNotFollow = (path) => {
  const notFollowPaths = ['spedxchange-reports', 'admin']
  return notFollowPaths.indexOf(path) > -1
}

const notFollowSEO = {
  title: 'SPEDxchange',
  description: 'The Community for Special Education Answers, Resources, and Jobs',
  image: 'https://www.spedxchange.com/assets/img/spedxchange-social.png',
  url: 'https://www.spedxchange.com',
  type: 'website',
  siteName: 'SPEDxchange',
  alt: 'SPEDxchange',
  robots: 'noindex,nofollow',
  seoScript: {}
}

export default connect(mapState, mapActions)(withRouter(App))
