import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Button, Label } from 'semantic-ui-react'
import { fetchLocalJob } from './actions/jobsActions'
import ReactGA from 'react-ga'
import moment from 'moment/moment.js'
import Loading from '../../common/ui/loading/Loading'

export class JobDetailPage extends Component {
  constructor(props) {
    super(props)
    this.onApplyClick = this.onApplyClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchLocalJob(this.props.match.params.id)
  }

  componentDidUpdate(prev) {
    if (this.props.match.params.id !== prev.match.params.id) {
      this.props.fetchLocalJob(this.props.match.params.id)
    }
  }

  onApplyClick() {
    ReactGA.event({
      category: this.props.job.organization.name,
      action: 'Job Apply Click',
      label: `${this.props.job.title} : ${this.props.job._id}`
    })
  }

  render() {
    const { loading, loadingEl } = this.props
    if (
      loading ||
      loadingEl === 'fetch-local-jobs' ||
      !this.props.job ||
      !this.props.job._id ||
      !this.props.org ||
      !this.props.org._id
    )
      return <Loading />
    const { name, location } = this.props.org
    const { remote, jobType, title, description, applyLink, updated } = this.props.job
    ReactGA.event({
      category: this.props.job.organization.name,
      action: 'Job Click',
      label: `${this.props.job.title} : ${this.props.job._id}`
    })
    return (
      <div className='job-post-full'>
        <div className='preview-wrap'>
          <div className='flex-box between'>
            <h4 className='grow pr-5'>{title}</h4>
            <div className='bookmark-section'>
              <Icon link size='large' color='blue' name='bookmark outline' />
              <span className='link'>SAVE</span>
            </div>
          </div>
          <div className='info'>
            <Label>
              <Icon name='building outline' />
              {name}
            </Label>
            {remote ? (
              <Label>
                <Icon name='map marker alternate' />
                Remote, USA
              </Label>
            ) : (
              <>
                {location.city && location.state && location.city.length > 0 && location.state.length > 0 && (
                  <Label>
                    <Icon name='map marker alternate' />
                    {location.city}, {location.state}
                  </Label>
                )}
              </>
            )}
            <Label>
              <Icon name='clock outline' />
              Posted: {moment(updated).from()}
            </Label>
            <Label>
              <Icon name='user outline' />
              {jobType}
            </Label>
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {applyLink && (
            <div className='apply-section'>
              <Button type='button' onClick={this.onApplyClick} href={applyLink} color='blue'>
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.async.loading,
  loadingEl: state.async.elementName,
  job: state.jobs.job,
  org: state.jobs.job.organization
})

const mapDispatchToProps = {
  fetchLocalJob
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailPage)
