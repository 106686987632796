import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from '../../../../common/ui/modal/ModalActions';

import { Modal, Button } from 'semantic-ui-react';

export class AdminJobModal extends Component {
  constructor(props) {
    super(props);
    this.handleJobAction = this.handleJobAction.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  async handleJobAction() {
    const currentJob = {...this.props.job};
    if (this.props.action === 'copy') {
      delete currentJob._id;
      currentJob.title = currentJob.title + '(copy)';
      currentJob.status = 'Draft';
      await this.props.createJob(currentJob, null);
    }
    if (this.props.action === 'archive') {
      currentJob.status = 'Archived';
      await this.props.saveJob(currentJob);
    }
    if(this.props.action === "status"){
      if(currentJob.status === "Published"){
        currentJob.status = "Draft";
      }
      else if(currentJob.status === "Draft"){
        currentJob.status = "Published";
      }
      await this.props.saveJob(currentJob);
    }
    await this.props.fetchOrganization(this.props.admin.org._id);
    this.handleCloseModal();
  }

  onRouteChanged() {
    this.handleCloseModal();
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  render() {
    const { job, action } = this.props;

    // Making text more understandable
    let displayText = "';"
    if(action === "status"){
      displayText = "change the status of";
    }
    else if(action === "archive"){
      displayText = "delete";
    }
    else{
      displayText = action;
    }

    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>
          <span className='capitalize'>{action} Job</span>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>Do you want to {displayText} the job titled:</div>
            <div>
              <strong>{job.title}?</strong>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button positive className='capitalize' onClick={this.handleJobAction}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminJobModal));
