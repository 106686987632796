import { Grid, Icon, Segment } from 'semantic-ui-react'
import React, { Component } from 'react'

import { JobDetails } from '../../jobs/components/JobDetails'
import { JobsListLocalItem } from '../../jobs/JobsListLocalItem'
import { connect } from 'react-redux'
import { fetchSavedJobs } from '../../jobs/actions/jobsActions'

export class SavedJobs extends Component {
  state = { query: '', job: '' }

  constructor(props) {
    super(props)
    this.getSavedJobs = this.getSavedJobs.bind(this)
  }

  componentDidMount() {
    this.getSavedJobs()
  }

  componentDidUpdate(prev) {
    if (prev.location.search !== this.props.location.search) {
      this.getSavedJobs()
    }
  }

  getSavedJobs() {
    let userID = this.props.auth.currentUser._id
    this.props.fetchSavedJobs(userID)
  }

  render() {
    const { savedJobs } = this.props.userSavedJobs

    return (
      <>
        <div>
          <h2>
            <Icon name='heart' />
            <strong>Saved Jobs</strong>
          </h2>
        </div>
        <Grid stackable reversed='mobile'>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            {savedJobs && savedJobs.length > 0 && (
              <>
                <div className='job-list mb-5'>
                  {savedJobs.map((job) => (
                    <JobsListLocalItem
                      key={job._id}
                      job={job}
                      q={this.state.query}
                      active={job._id === this.state.job}
                    />
                  ))}
                </div>
              </>
            )}
            {(!savedJobs || savedJobs.length < 1) && <div>No Saved Jobs.</div>}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className='sticky-job'>
              {this.state.job && this.state.job.length > 0 && (
                <Segment>
                  <JobDetails jobId={this.state.job}></JobDetails>
                </Segment>
              )}
            </div>
          </Grid.Column>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  auth: state.auth,
  loading: state.async.loading,
  loadingEl: state.async.elementName,
  job: state.jobs.job,
  org: state.jobs.job.organization,
  userSavedJobs: state.jobs.userSavedJobs,
})

const mapDispatchToProps = {
  fetchSavedJobs,
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedJobs)
