import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from '../../../../common/ui/modal/ModalActions';
import {saveArticle, deleteArticle} from "../../ReportActions";
import { Modal, Button } from 'semantic-ui-react';

export class ReportNewsModal extends Component {
    constructor(props) {
        super(props);


        this.handleArticleArchiveAction = this.handleArticleArchiveAction.bind(this);
        this.onRouteChanged = this.onRouteChanged.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    handleArticleArchiveAction() {
        const currentArticle = Object.assign({}, this.props.article);
        let time = 100;
        if (this.props.action === 'Published') {
            currentArticle.status = 'Draft';
            this.props.saveArticle(currentArticle);
        }
        else if (this.props.action === 'Draft') {
            currentArticle.status = 'Published';
            this.props.saveArticle(currentArticle);
        } else if (this.props.action === 'Delete'){
            this.props.deleteArticle(currentArticle);
        }
        setTimeout(() => {
            this.props.fetchAllArticles();
        }, time);
        this.handleCloseModal();
    }

    onRouteChanged() {
        this.handleCloseModal();
    }

    handleCloseModal() {
        this.props.closeModal();
    }

    render() {
        const { article, action } = this.props;
        let actionDisplay = "";
        if(action === "Published"){
            actionDisplay = "Draft";
        } else if (action === "Draft"){
            actionDisplay = "Publish";
        } else{
            actionDisplay = "Delete";
        }
        return (
            <Modal size='mini' open={true} onClose={this.handleCloseModal}>
                <Modal.Header>
                    <span className='capitalize'>{action} Article</span>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div>Do you want to <strong>{actionDisplay}</strong> the article titled:</div>
                        <div>
                            <strong>{article.title}?</strong>
                        </div>
                        {actionDisplay === "Delete" &&
                            <div><i>Note: This will <strong>permanently delete</strong> the article.</i></div>
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleCloseModal}>Cancel</Button>
                    {actionDisplay === "Delete" &&
                    <Button negative className='capitalize' onClick={this.handleArticleArchiveAction}>
                        Confirm
                    </Button>
                    }
                    {actionDisplay === "Publish" &&
                    <Button positive className='capitalize' onClick={this.handleArticleArchiveAction}>
                        Confirm
                    </Button>
                    }
                    {actionDisplay === "Draft" &&
                    <Button positive className='capitalize' onClick={this.handleArticleArchiveAction}>
                        Confirm
                    </Button>
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    saveArticle,
    closeModal,
    deleteArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportNewsModal));