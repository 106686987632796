import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { closeModal } from '../../../../common/ui/modal/ModalActions'
import { saveArticle } from '../../ReportActions'
import { saveOrganization, closeOrganizationJobs } from '../../ReportActions'
import { Modal, Button, Dropdown } from 'semantic-ui-react'

export class ReportNewsModal extends Component {
  constructor(props) {
    super(props)

    this.onRouteChanged = this.onRouteChanged.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  handleSelectChange(e, data) {
    // data.value --> the value selected in the Dropdown React Component

    // Status Codes:
    //  1. Past Free Trial User
    //  2. Past Paid Client
    //  3. Current Free Trial User
    //  4. Current Paid Client

    let changeStatusTo = ''
    if (data.value === 'Past Free Trial User') {
      changeStatusTo = '1'
    } else if (data.value === 'Past Paid Client') {
      changeStatusTo = '2'
    } else if (data.value === 'Current Free Trial User') {
      changeStatusTo = '3'
    } else if (data.value === 'Current Paid Client') {
      changeStatusTo = '4'
    }

    // Saving Code
    const currentOrganization = Object.assign({}, this.props.organization)
    let time = 100

    currentOrganization.statusCode = changeStatusTo // Changing the status code

    // Status Codes
    //  1 and 2 represent trial users
    //  3 and 4 represent a client who can utilize all features of SPEDxchange

    if (changeStatusTo === '1') {
      currentOrganization.status = 'trial'
      this.props.saveOrganization(currentOrganization)
    } else if(changeStatusTo === '2') {
      currentOrganization.status = 'trial'
      this.props.saveOrganization(currentOrganization)
      this.props.closeOrganizationJobs(currentOrganization) // Trigger published jobs to close
    } else if (changeStatusTo === '3' || changeStatusTo === '4') {
      currentOrganization.status = 'client'
      this.props.saveOrganization(currentOrganization)
    }
    setTimeout(() => {
      this.props.fetchAllOrgs()
    }, time)
    this.handleCloseModal()
  }

  onRouteChanged() {
    this.handleCloseModal()
  }

  handleCloseModal() {
    this.props.closeModal()
  }

  render() {
    const { organization, action } = this.props

    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>
          <span className='capitalize'>{action} Status</span>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>
              Please select new status for <strong>{organization.name}</strong>.
            </div>
            <div></div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Dropdown
            placeholder='Select Organization Status'
            fluid
            search
            selection
            onChange={this.handleSelectChange}
            options={statusOptions}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({})

const statusOptions = [
  {
    key: 'Past Free Trial User',
    text: 'Past Free Trial User',
    value: 'Past Free Trial User',
  },
  {
    key: 'Past Paid Client',
    text: 'Past Paid Client',
    value: 'Past Paid Client',
  },
  {
    key: 'Current Free Trial User',
    text: 'Current Free Trial User',
    value: 'Current Free Trial User',
  },
  {
    key: 'Current Paid Client',
    text: 'Current Paid Client',
    value: 'Current Paid Client',
  },
]

const mapDispatchToProps = {
  saveArticle,
  saveOrganization,
  closeOrganizationJobs,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportNewsModal))
