import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../common/ui/modal/ModalActions';

import ProfileSection from './ProfileSection/ProfileSection';

export class ProfileAbout extends Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit() {
    this.props.openModal('ModalProfileAbout');
  }

  render() {
    const { displayName, avatar, headline, location, title, phone } = this.props.user;
    return (
      <ProfileSection title='About Me' handleEdit={this.handleEdit}>
        <div className='profile-about text-center'>
          <div className='avatar'>
            <img src={avatar} alt={displayName} />
          </div>
          <div className='profile-name'>{displayName}</div>
          {title && title.length > 0 ? (
            <div className='profile-headline'>{title}</div>
          ) : (
            <div onClick={this.handleEdit}>
              <em>Add a Title</em>
            </div>
          )}
          {headline && headline.length > 0 ? (
            <div className='profile-headline'>{headline}</div>
          ) : (
            <div onClick={this.handleEdit}>
              <em>Add a headline</em>
            </div>
          )}
          {location && location.length > 0 ? (
            <p>{location}</p>
          ) : (
            <div onClick={this.handleEdit}>
              <em>Add a Location</em>
            </div>
          )}
          {phone && phone.length > 0 && phone !== '+1' ? (
            <p>{phone}</p>
          ) : (
            <div onClick={this.handleEdit}>
              <em>Add Phone Number</em>
            </div>
          )}
        </div>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAbout);
