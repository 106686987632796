import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { openModal } from '../../../common/ui/modal/ModalActions';

import ProfileSection from './ProfileSection/ProfileSection';

export class ProfileSocial extends Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit() {
    this.props.openModal('ModalProfileSocial');
  }

  render() {
    // console.log('user: ', this.props.user);
    const { facebook, twitter, linkedin, instagram, website } = this.props.user;
    return (
      <ProfileSection title='Social Information' handleEdit={this.handleEdit}>
        <ul className='section-ul'>
          <li>
            <div className='add-btn' onClick={this.handleEdit}>
              <Icon circular name='linkedin' />{' '}
              {linkedin && linkedin.length > 0 ? <span>{linkedin}</span> : <span>Add Linkedin</span>}
            </div>
          </li>
          <li>
            <div className='add-btn' onClick={this.handleEdit}>
              <Icon circular name='twitter' />{' '}
              {twitter && twitter.length > 0 ? <span>{twitter}</span> : <span>Add Twitter</span>}
            </div>
          </li>
          <li>
            <div className='add-btn' onClick={this.handleEdit}>
              <Icon circular name='instagram' />{' '}
              {instagram && instagram.length > 0 ? <span>{instagram}</span> : <span>Add Instagram</span>}
            </div>
          </li>
          <li>
            <div className='add-btn' onClick={this.handleEdit}>
              <Icon circular name='facebook' />{' '}
              {facebook && facebook.length > 0 ? <span>{facebook}</span> : <span>Add Facebook</span>}
            </div>
          </li>
          <li>
            <div className='add-btn' onClick={this.handleEdit}>
              <Icon circular name='linkify' />{' '}
              {website && website.length > 0 ? <span>{website}</span> : <span>Add Website</span>}
            </div>
          </li>
        </ul>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSocial);
