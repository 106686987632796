export const ACTIVATE_NAV_ITEM = 'UPDATE_ACTIVE_NAV_ITEM'
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
export const TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR'
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR'
export const SET_RETURN_URL = 'SET_RETURN_URL'
export const SET_SEO = 'SET_SEO'

export const NAV_ITEMS = {
  news: {
    name: 'SPED News',
    link: '/news',
  },
  scholarships: {
    name: 'Scholarships',
    link: '/scholarships',
  },
  resources: {
    name: 'Resources',
    link: '/resources',
  },
  about: {
    name: 'About Us',
    link: '/about',
  },
  contact: {
    name: 'Contact Us',
    link: '/contact',
  },
  questions: {
    name: 'SPEDxchange',
    link: '/questions',
  },
  categories: {
    name: 'Categories',
    link: '/categories',
  },
  tags: {
    name: 'Tags',
    link: '/tags',
  },
  people: {
    name: 'People',
    link: '/people',
  },
  jobs: {
    name: 'Jobs',
    link: '/jobs',
  },
}
