import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Checkbox, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment.js';

import { closeModal } from '../../../../common/ui/modal/ModalActions';
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions';

const initEducation = {
  school: '',
  degree: '',
  study: '',
  start: null,
  end: null,
  isCurrent: false
};

class ModalProfileEducation extends Component {
  constructor(props) {
    super(props);
    const { education } = this.props.user;
    const { index, isAdd } = this.props;
    const currentItem = isAdd ? Object.assign({}, initEducation) : Object.assign({}, education[index]);
    this.state = {
      school: currentItem.school,
      degree: currentItem.degree,
      study: currentItem.study,
      start: currentItem.start !== null ? new Date(currentItem.start) : currentItem.start,
      end: currentItem.end !== null ? new Date(currentItem.end) : currentItem.end,
      isCurrent: currentItem.isCurrent
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  handleRemove() {
    let newEducation = Object.assign([], this.props.user.education);
    newEducation.splice(this.props.index, 1);
    const form = {
      ...this.props.user,
      education: newEducation
    };
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  handleSubmit() {
    const { index, isAdd } = this.props;
    const { school, degree, study, start, end, isCurrent } = this.state;

    if (
      !school ||
      school.length < 0 ||
      !degree ||
      degree.length < 0 ||
      !study ||
      study.length < 0 ||
      !start ||
      start.length < 0 ||
      (isCurrent !== true && (!end || end.length < 0))
    ) {
      return;
    }

    let newItems = Object.assign([], this.props.user.education);
    if (isAdd) {
      newItems.push(this.state);
    } else {
      newItems[index] = this.state;
    }
    const sortedItems = newItems.sort((a, b) => b.start - a.start);

    const form = {
      ...this.props.user,
      education: sortedItems
    };

    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  render() {
    let { school, degree, study, start, end, isCurrent } = this.state;
    const { isAdd, isRemove } = this.props;
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>{isAdd === true ? 'Add' : isRemove ? 'Remove' : 'Edit'} Education</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {isRemove === true ? (
              <>
                <div>{school}</div>
                <div>{degree}</div>
                <div>
                  <em>{study}</em>
                </div>
                <div className='mt-2 mb-5'>
                  {moment(start).format('YYYY')} - {isCurrent === true ? 'Current' : moment(end).format('YYYY')}
                </div>
                <div>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='orange' onClick={this.handleRemove}>
                    Remove
                  </Button>
                </div>
              </>
            ) : (
              <Form size='small' onSubmit={this.handleSubmit}>
                <Form.Field
                  name='school'
                  maxLength={64}
                  control={Input}
                  label='School'
                  value={school}
                  onChange={this.handleChange}
                />
                <Form.Field
                  name='degree'
                  maxLength={32}
                  control={Input}
                  label='Degree'
                  value={degree}
                  onChange={this.handleChange}
                />
                <Form.Field
                  name='study'
                  maxLength={32}
                  control={Input}
                  label='Field of Study'
                  value={study}
                  onChange={this.handleChange}
                />
                <Form.Field>
                  <Checkbox
                    label='I currently attend this school'
                    checked={isCurrent}
                    onChange={(e, { checked }) => this.setState({ ...this.state, isCurrent: checked })}
                  />
                </Form.Field>
                <Form.Group unstackable widths={2}>
                  <div className='field'>
                    <label>Start Year</label>
                    <DatePicker selected={start} onChange={(date) => this.setState({ ...this.state, start: date })} />
                  </div>
                  <div className='field'>
                    <label>End Year</label>
                    {isCurrent === true ? (
                      <div style={{ paddingTop: '.75rem' }}>Present</div>
                    ) : (
                      <DatePicker selected={end} onChange={(date) => this.setState({ ...this.state, end: date })} />
                    )}
                  </div>
                </Form.Group>
                <div className='pt-3'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='green' type='submit'>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
});

const actions = {
  closeModal,
  updateUserProfile
};

export default connect(mapState, actions)(ModalProfileEducation);
