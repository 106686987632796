import React, { useState } from 'react'
import { Form, Label, Input } from 'semantic-ui-react'

const normalizeInput = (value, previousValue) => {
  // console.log('value: ', value)
  // console.log('previousValue: ', previousValue)
  if (!value) return value
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  if (!previousValue || value.length !== previousValue.length) {
    if (cvLength < 4) return currentValue
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }
}

const PhoneInput = ({
  input,
  type,
  placeholder,
  maxLength,
  icon,
  iconPosition,
  label,
  labelPosition,
  meta: { touched, error }
}) => {
  const [phone, setPhone] = useState('')

  const handleChange = (e) => {
    let v = normalizeInput(e.target.value, phone)
    setPhone(v)
  }

  return (
    <Form.Field error={touched && !!error}>
      <Input
        {...input}
        type={type}
        icon={icon}
        iconPosition={iconPosition}
        label={label}
        labelPosition={labelPosition}
        placeholder={placeholder}
        maxLength={maxLength}
        value={phone}
        onChange={handleChange}
      />
      {touched && error && (
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  )
}

export default PhoneInput
