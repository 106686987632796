import * as _ from 'lodash'
import {Breadcrumb, Button, Dropdown, Icon, Table} from 'semantic-ui-react'
import {Link, withRouter} from 'react-router-dom'
import React, { Component } from 'react'
import { clearJob, createJob, saveJob, fetchOrgAnalytics } from '../../AdminActions'

import AdminClientSelector from '../AdminClientSelector'
import Loading from '../../../../common/ui/loading/Loading'
import { connect } from 'react-redux'
import { fetchOrganization } from '../../../../common/ui/auth/AuthActions'
import moment from 'moment/moment.js'
import { openModal } from '../../../../common/ui/modal/ModalActions'
import {toastr} from "react-redux-toastr";

export class AdminJobList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: ['Published', 'Draft', 'Paused', 'Closed','Expired'],
    }
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleJobAction = this.handleJobAction.bind(this)
    this.handleJobChangeStatus = this.handleJobChangeStatus.bind(this)
    this.handleNewJob = this.handleNewJob.bind(this)
    this.filterJobs = this.filterJobs.bind(this)
    this.getLocationName = this.getLocationName.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.copyJobLink = this.copyJobLink.bind(this)
  }

  componentDidUpdate() {
    if (!this.props.events && this.props.org?.name) {
      this.props.fetchOrgAnalytics(this.props.org._id)
    }
  }
  // async componentDidMount() {
  //   if (!this.props.admin.org) {
  //     const id = sessionStorage.init
  //     await this.props.fetchOrganization(id)
  //   }
  // }

  copyJobLink(job){
    if(job.status !== "Published"){
      toastr.error('Link cannot be copied', 'Since the job has not been published, there is no URL to be copied')
    } else {
      let jobLink = 'https://spedxchange.com/jobs?&j=' + job._id
      navigator.clipboard.writeText(jobLink).then(() => {
        toastr.success('Job Link Copied!', 'Use Ctrl + V to paste the job link')
      });
    }

  }
  handleJobAction(job, action) {
    this.props.openModal('AdminJobModal', {
      ...this.props,
      job: job,
      action: action,
    })
  }

  handleJobChangeStatus(job){
    this.props.openModal('AdminJobStatusModal', {
      ...this.props,
      job: job,
    })
  }

  handleEditClick(id) {
    this.props.history.push(`/admin/job/${id}`)
  }

  handleNewJob(id) {
    this.props.clearJob()
    this.props.history.push(`/admin/job/new`)
  }

  filterJobs() {
    if (this.state.filter) {
      const predicate = function () {
        const args = _.toArray(arguments)
        return (job) => {
          let equalsJobStatus = _.partial(_.isEqual, job.status)
          return args.some(equalsJobStatus)
        }
      }
      return _.filter(
          this.props.admin.org.jobs,
          predicate(...this.state.filter)
      )
    } else {
      return this.props.admin.org.jobs
    }
  }

  getLocationName(id) {
    const location = this.props.admin.org.locations.find(
        (loc) => loc._id === id
    )
    return !!location ? location.name : ''
  }

  onFilterChange(e) {
    let filterStatus = e.target.textContent
    if(filterStatus === 'All'){
      this.setState({
        filter: ['Published', 'Draft', 'Paused', 'Closed','Expired'],
      })
    } else {
      this.setState({
        filter: [filterStatus],
      })
    }

    this.filterJobs()
    this.setState({ ...this.state, filterOption: e.target.textContent })
  }

  render() {
    const { loading, loadingName, admin, orgAnalytics } = this.props;

    if ((loading && loadingName === "admin-job-list") || !admin || !admin.org)
      return <Loading />;

    const locations =
        admin.org && Array.isArray(admin.org.locations)
            ? admin.org.locations.length
            : 1;

    return (
        <div>
          <AdminClientSelector />
          <div className="admin-nav">
            <div className="flex-box between">
              <div className="flex-box align-center grow">
                <Breadcrumb size="large">
                  <Breadcrumb.Section
                      link
                      onClick={() => this.props.history.push('/admin')}
                  >
                    Dashboard
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section>Jobs</Breadcrumb.Section>
                </Breadcrumb>
              </div>
              <Button content="New Job" primary onClick={this.handleNewJob} />
            </div>
          </div>
          <Dropdown
              placeholder="Filter Job"
              selection
              onChange={this.onFilterChange}
              options={jobFilterOptions}
          />
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>Job Title</Table.HeaderCell>
                <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                {locations > 1 && (
                    <Table.HeaderCell width={2}>Location</Table.HeaderCell>
                )}
                <Table.HeaderCell width={2}>Last Update</Table.HeaderCell>
                <Table.HeaderCell width={1}>Job Clicks</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="right"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Array.isArray(admin.org.jobs) &&
              this.filterJobs(admin.org.jobs).length > 0 &&
              this.filterJobs(admin.org.jobs).map((job, index) => {
                const matchingAnalytics = orgAnalytics.find(
                    (analytics) => analytics.job === job._id
                );

                return (
                    <Table.Row key={job._id}>
                      <Table.Cell>
                        <Link
                            onClick={() => this.copyJobLink(job)}
                            title="Copy Job Link"
                        >
                          {job.title}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{job.status}</Table.Cell>
                      {locations > 1 && (
                          <Table.Cell width={2}>
                            {this.getLocationName(job.location)}
                          </Table.Cell>
                      )}
                      <Table.Cell>{moment(job.updated).from()}</Table.Cell>
                      {matchingAnalytics ? (
                          <Table.Cell>{matchingAnalytics.jobClicks}</Table.Cell>
                      ) : (
                          <Table.Cell>0</Table.Cell>
                      )}
                      <Table.Cell textAlign="right">
                        <Icon
                            link
                            className="ml-2"
                            color="green"
                            name="edit outline"
                            title="Edit Job"
                            onClick={() => this.handleEditClick(job._id)}
                        />
                        <Icon
                            link
                            className="ml-2"
                            color="orange"
                            name="cog"
                            title="Change Job Status"
                            onClick={() => this.handleJobChangeStatus(job)}
                        />
                        <Icon
                            link
                            className="ml-2"
                            color="grey"
                            name="copy outline"
                            title="Duplicate Job"
                            onClick={() =>
                                this.handleJobAction(job, 'copy', index)
                            }
                        />
                        <Icon
                            link
                            className="ml-2"
                            color="orange"
                            name="trash"
                            title="Delete Job"
                            onClick={() =>
                                this.handleJobAction(job, 'archive', index)
                            }
                        />
                      </Table.Cell>
                    </Table.Row>
                );
              })}
              <Table.Row onClick={this.handleNewJob}>
                <Table.Cell>
                  <span className="link">Create New Job</span>
                </Table.Cell>
                <Table.Cell></Table.Cell>
                {locations > 1 && <Table.Cell width={2}></Table.Cell>}
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign="right">
                  <Icon link color="green" name="add" />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
    );
  }
}

const jobFilterOptions = [
  { key: 'All', text: 'All', value: 'All' },
  { key: 'Draft', text: 'Draft', value: 'Draft' },
  { key: 'Published', text: 'Published', value: 'Published' },
  { key: 'Paused', text: 'Paused', value: 'Paused' },
  { key: 'Closed', text: 'Closed', value: 'Closed' },
]

const mapState = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  admin: state.admin,
  orgAnalytics: state.admin.orgAnalytics,
})

const actions = {
  fetchOrganization,
  createJob,
  saveJob,
  clearJob,
  openModal,
  fetchOrgAnalytics,
}

export default connect(mapState, actions)(withRouter(AdminJobList))
