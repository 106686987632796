import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button, Container, Grid, List } from 'semantic-ui-react'

export class HomeSpedSection extends Component {
  render() {
    const { history, adminRef } = this.props
    return (
      <section id='HomeAdminSection' ref={adminRef}>
        <Container>
          <Grid stackable reversed='mobile'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h1 className='ovo'>
                SPEDxchange is transforming and improving the process of connecting special educators and school
                district.
              </h1>
              <p>
                When it's time to hire a special educator, we've got you covered. Our network includes thousands of
                special education professionals located all over the country, from soon-to-be grads to seasoned
                professionals.
              </p>
              <div className='call-out-box'>
                <h4>Schools and SPED Administrators can use SPEDxchange to:</h4>
                <List as='ul' className='mb-4'>
                  <List.Item as='li'>
                    <Link to='/postjob'>Post jobs</Link> to our SPED community
                  </List.Item>
                  <List.Item as='li'>Connect with future SPED graduates</List.Item>
                  <List.Item as='li'>Increase your district's visibility</List.Item>
                </List>
                <Button primary onClick={() => history.push('/resources#Administrator')}>
                  Start Here
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='section-img'></div>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    )
  }
}

export default withRouter(HomeSpedSection)
