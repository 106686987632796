import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { openModal } from '../../ui/modal/ModalActions';

const testAuth = (state, ownProps) => {
  if (state.auth.loading) return true;
  if (state.auth.authenticated) return true;
  ownProps.history.push('/questions');
  return false;
};

export const UserCanAsk = connectedReduxRedirect({
  wrapperDisplayName: 'UserCanAsk',
  allowRedirectBack: true,
  redirectPath: '/questions',
  authenticatedSelector: testAuth,
  redirectAction: newLoc => dispatch => {
    dispatch(openModal('UnauthModal'));
  }
});
