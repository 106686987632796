import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, TextArea, Input, Checkbox, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment.js';

import { closeModal } from '../../../../common/ui/modal/ModalActions';
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions';

const initExperience = {
  title: '',
  company: '',
  location: '',
  start: null,
  end: null,
  isCurrent: false,
  description: ''
};

class ModalProfileExperience extends Component {
  constructor(props) {
    super(props);
    const { experience } = this.props.user;
    const { index, isAdd } = this.props;
    const currentExperience = isAdd ? Object.assign({}, initExperience) : Object.assign({}, experience[index]);
    // console.log('currentExperience: ', currentExperience);
    this.state = {
      title: currentExperience.title,
      company: currentExperience.company,
      location: currentExperience.location,
      start: currentExperience.start !== null ? new Date(currentExperience.start) : currentExperience.start,
      end: currentExperience.end !== null ? new Date(currentExperience.end) : currentExperience.end,
      isCurrent: currentExperience.isCurrent,
      description: currentExperience.description
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  handleRemove() {
    let newExperiences = Object.assign([], this.props.user.experience);
    newExperiences.splice(this.props.index, 1);
    const form = {
      ...this.props.user,
      experience: newExperiences
    };
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  handleSubmit() {
    const { index, isAdd } = this.props;
    const { title, company, location } = this.state;
    if (!title || title.length < 0 || !company || company.length < 0 || !location || title.location < 0) {
      return;
    }
    let newExperience = Object.assign([], this.props.user.experience);
    if (isAdd) {
      newExperience.push(this.state);
    } else {
      newExperience[index] = this.state;
    }
    const sortedExperience = newExperience.sort((a, b) => b.start - a.start);
    const form = {
      ...this.props.user,
      experience: sortedExperience
    };
    // console.log('form: ', form);
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  render() {
    const { title, company, location, start, end, isCurrent, description } = this.state;
    const { isAdd, isRemove } = this.props;
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>{isAdd === true ? 'Add' : isRemove ? 'Remove' : 'Edit'} Experience</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {isRemove === true ? (
              <>
                <div>
                  <strong>{title}</strong>
                </div>
                <div>{company}</div>
                <div>
                  <em>{location}</em>
                </div>
                <div>
                  {moment(start).format('MMMM YYYY')} -{' '}
                  {isCurrent === true ? 'Current' : moment(end).format('MMMM YYYY')}
                </div>
                <div className='mt-2 mb-5'>{description}</div>
                <div>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='orange' type='submit' onClick={this.handleRemove}>
                    Remove
                  </Button>
                </div>
              </>
            ) : (
              <Form size='small' onSubmit={this.handleSubmit}>
                <Form.Field
                  name='title'
                  maxLength={64}
                  control={Input}
                  label='Title'
                  value={title}
                  onChange={this.handleChange}
                />
                <Form.Field
                  name='company'
                  maxLength={64}
                  control={Input}
                  label='Company'
                  value={company}
                  onChange={this.handleChange}
                />
                <Form.Field
                  name='location'
                  maxLength={64}
                  control={Input}
                  label='Location'
                  value={location}
                  onChange={this.handleChange}
                />
                <Form.Field>
                  <Checkbox
                    label='I am currently in this role'
                    checked={isCurrent}
                    onChange={(e, { checked }) => this.setState({ ...this.state, isCurrent: checked })}
                  />
                </Form.Field>
                <Form.Group unstackable widths={2}>
                  <div className='field'>
                    <label>Start Date</label>
                    <DatePicker selected={start} onChange={(date) => this.setState({ ...this.state, start: date })} />
                  </div>
                  <div className='field'>
                    <label>End Date</label>
                    {isCurrent === true ? (
                      <div style={{ paddingTop: '.75rem' }}>Present</div>
                    ) : (
                      <DatePicker selected={end} onChange={(date) => this.setState({ ...this.state, end: date })} />
                    )}
                  </div>
                </Form.Group>
                <Form.Field
                  name='description'
                  maxLength={512}
                  control={TextArea}
                  rows={6}
                  label='Description'
                  value={description}
                  onChange={this.handleChange}
                />
                <div className='pt-3'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='green' type='submit'>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
});

const actions = {
  closeModal,
  updateUserProfile
};

export default connect(mapState, actions)(ModalProfileExperience);
