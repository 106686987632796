import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { openModal } from '../../../common/ui/modal/ModalActions';

import ProfileSection from './ProfileSection/ProfileSection';

export class ProfileSummary extends Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit() {
    this.props.openModal('ModalProfileSummary');
  }

  render() {
    const { summary } = this.props.user;
    return (
      <ProfileSection title='Professional Summary' handleEdit={this.handleEdit}>
        <ul className='section-ul'>
          {summary && summary.length > 0 ? (
            <li className='details'>
              <p>{summary}</p>
            </li>
          ) : (
            <li>
              <div className='add-btn' onClick={this.handleEdit}>
                <Icon circular name='add' />
                Add Professional Summary
              </div>
            </li>
          )}
        </ul>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSummary);
