import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'

export class ReportNewsPreview extends Component {
  render() {
    const { data } = this.props
    let image, guestBloggerImage
    if (data.file2 === null) {
      image =
        'https://spedxchange.s3.us-east-2.amazonaws.com/news/' +
        data.uid +
        '/' +
        data.photoURL +
        '.jpg'
    } else {
      image = data.file2
    }

    if (data.file3 === undefined) {
      guestBloggerImage =
          'https://spedxchange.s3.us-east-2.amazonaws.com/news/' +
          data.uid +
          '/' +
          data.photoURL + "-guest-blogger-image" +
          '.jpg'
    } else {
      guestBloggerImage = data.file3
    }

    return (
      <div className='flex-box md article-wrap'>
        <div className='article grow'>
          <h5 className='section-head'>Featured Story</h5>
          <div>
            <h1>{data.title}</h1>
            <div>
              <img src={image} alt='{data.slug}' />
            </div>
          </div>

          <div className='article-figure'>
            <div className='share'>SHARE</div>
            <Icon
              link
              circular
              name='twitter'
              onClick={() => this.handleSocialClick('twitter')}
            />
            <Icon
              link
              circular
              name='linkedin'
              onClick={() => this.handleSocialClick('linkedin')}
            />
            <Icon
              link
              circular
              name='facebook'
              onClick={() => this.handleSocialClick('facebook')}
            />
            <a href={this.setEmailLink()}>
              <Icon link circular name='envelope outline' />
            </a>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
          <br/>
          <br/>
          {
            data.guestBloggerName.length > 0 &&
            <div>
              <div>
                <img src={guestBloggerImage} alt='{data.slug}' />
              </div>
              <h5>Author: {data.guestBloggerName}</h5>
              <h5>Author Summary: {data.guestBloggerSummary}</h5>
            </div>
          }
        </div>
        <div className='tags'>
          <section>
            <h5 className='section-head purple'>Related Tags</h5>
            <div className='related'>
              {data.tags &&
                data.tags.map((tag) => (
                  <a key={tag._id} href='/'>
                    {tag.text}
                  </a>
                ))}
            </div>
          </section>
        </div>
      </div>
    )
  }
  setEmailLink = () => {
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      '/scholarships/teacher'
    return `mailto:?subject=2020%20Special%20Education%20Teacher%20Scholarship%20$1,000&body=I thought you might be interested in reading this SPED Talk article.%0D%0A%0D%0A2020%20Special%20Education%20Teacher%20Scholarship%20$1,000%0D%0A${url}%0D%0A%0D%0A`.replace(
      / /g,
      '%20'
    )
  }
}

export default ReportNewsPreview
