import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, TextArea, Input, Button, Icon } from 'semantic-ui-react'

import { closeModal } from '../../../../common/ui/modal/ModalActions'
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions'

class ModalProfileAbout extends Component {
  constructor(props) {
    super(props)
    const { _id, avatar, displayName, title, headline, location, phone } = this.props.user
    this.state = {
      _id: _id,
      file: null,
      fileData: null,
      avatar: avatar,
      displayName: displayName,
      title: title,
      headline: headline,
      location: location,
      phone: phone,
      setHeight: '72px',
      setWidth: '72px'
    }
    // console.log('this.props.user: ', this.props.user);
    this.handleChange = this.handleChange.bind(this)
    this.handlePhone = this.handlePhone.bind(this)
    this.handleUploadChange = this.handleUploadChange.bind(this)
    this.handleUploadClick = this.handleUploadClick.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // TODO: Make this a util
  handlePhone(value, previousValue) {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length
    if (!previousValue || value.length !== previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
    }
  }

  handleChange(e) {
    let value = e.target.value
    if (e.target.name === 'phone') {
      value = this.handlePhone(value, this.state.phone)
    }
    this.setState({
      ...this.state,
      [e.target.name]: value
    })
  }

  handleUploadClick() {
    this.inputElement.click()
  }

  handleUploadChange(e) {
    const file = e.target.files[0]
    const isImg = !/^image\/(png|jpe?g|gif)$/.test(file.type) ? false : true
    const isSize = file.size / 1024 < 300 ? true : false
    this.fileErrors = []
    if (!isImg) {
      this.fileErrors.push('The file you selected is not an image file.')
    }
    if (!isSize) {
      this.fileErrors.push('Please select an image file that is less than 300Kb.')
    }
    if (this.fileErrors.length < 1) {
      const image = new Image()
      image.src = window.URL.createObjectURL(file)
      image.onload = () => {
        let setWidth, setHeight
        const width = image.naturalWidth
        const height = image.naturalHeight
        window.URL.revokeObjectURL(image.src)
        // console.log('width: ', width);
        // console.log('height: ', height);
        if (width >= height) {
          setHeight = '72px'
          setWidth = 'auto'
        } else {
          setHeight = 'auto'
          setWidth = '72px'
        }
        this.setState({
          ...this.state,
          setHeight: setHeight,
          setWidth: setWidth,
          file: URL.createObjectURL(file),
          fileData: file
        })
      }
    }
  }

  handleCloseModal() {
    this.props.closeModal()
  }

  handleSubmit() {
    const { _id, file, fileData, avatar, displayName, title, headline, location, phone } = this.state
    const form = {
      ...this.props.user,
      file: file,
      fileData: fileData,
      _id: _id,
      avatar: avatar,
      displayName: displayName,
      title: title,
      headline: headline,
      location: location,
      phone: phone
    }
    // console.log('form: ', form)
    if (!form.displayName || form.displayName.length < 0) {
      return
    }
    this.props.updateUserProfile(form)
    this.props.closeModal()
  }

  render() {
    let { file, avatar, displayName, title, headline, location, phone, setWidth, setHeight } = this.state
    return (
      <Modal closeIcon size='mini' open={true} onClose={this.handleCloseModal} closeOnDimmerClick={false}>
        <Modal.Header>About Me</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='small' onSubmit={this.handleSubmit}>
              <input
                style={{ display: 'none' }}
                type='file'
                ref={(input) => (this.inputElement = input)}
                onChange={this.handleUploadChange}
              />
              <div className='avatar_wrap' onClick={this.handleUploadClick}>
                <div className='avatar'>
                  <img
                    alt='My Avatar'
                    src={file === null ? avatar : file}
                    style={{ width: setWidth, height: setHeight }}
                  />
                </div>
                <Icon circular size='small' name='pencil' />
              </div>
              <Form.Field
                required
                name='displayName'
                maxLength={64}
                control={Input}
                label='Full Name'
                value={displayName}
                onChange={this.handleChange}
              />
              <Form.Field
                name='title'
                maxLength={64}
                control={Input}
                label='Title'
                value={title}
                onChange={this.handleChange}
              />
              <Form.Field
                name='headline'
                maxLength={128}
                control={TextArea}
                label='Headline'
                value={headline}
                onChange={this.handleChange}
              />
              <Form.Field
                name='location'
                maxLength={32}
                control={Input}
                label='Location'
                value={location}
                onChange={this.handleChange}
              />
              <Form.Field
                name='phone'
                maxLength={32}
                control={Input}
                label='Phone'
                value={phone}
                onChange={this.handleChange}
              />
              <Button color='green' type='submit'>
                Save
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
})

const actions = {
  closeModal,
  updateUserProfile
}

export default connect(mapState, actions)(ModalProfileAbout)
