import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, TextArea, Button } from 'semantic-ui-react'
import { closeModal } from '../../../../common/ui/modal/ModalActions'
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions'

const initActivity = {
  description: ''
}

class ModalProfileActivities extends Component {
  constructor(props) {
    super(props)
    const { index, isAdd } = this.props
    const { activities } = this.props.user
    // console.log('activities: ', activities);
    const currentActivity = isAdd ? Object.assign({}, initActivity) : Object.assign({}, activities[index])
    this.state = {
      description: currentActivity.description
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const val = e.target.value.replace(/[\r\n\v]+/g, '')
    this.setState({ ...this.state, [e.target.name]: val })
  }

  handleCloseModal() {
    this.props.closeModal()
  }

  handleRemove() {
    let newActivities = Object.assign([], this.props.user.activities)
    newActivities.splice(this.props.index, 1)
    const form = {
      ...this.props.user,
      activities: newActivities
    }
    this.props.updateUserProfile(form)
    this.props.closeModal()
  }

  handleSubmit() {
    const { index, isAdd } = this.props
    const { description } = this.state
    if (!description || description.length < 0) return

    let newActivities = Object.assign([], this.props.user.activities || [])
    if (isAdd) {
      newActivities.push(this.state)
    } else {
      newActivities[index] = this.state
    }
    // console.log('newActivities: ', newActivities);

    const form = {
      ...this.props.user,
      activities: newActivities
    }

    this.props.updateUserProfile(form)
    this.props.closeModal()
  }

  render() {
    const { description } = this.state
    const { isAdd, isRemove } = this.props
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>{isAdd === true ? 'Add' : isRemove ? 'Remove' : 'Edit'} Activity</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {isRemove === true ? (
              <div>
                <p>
                  <em>{description}</em>
                </p>
                <div className='pt-5'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='orange' type='submit' onClick={this.handleRemove}>
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <Form size='small' onSubmit={this.handleSubmit}>
                <Form.Field
                  name='description'
                  maxLength={512}
                  control={TextArea}
                  rows={6}
                  label='Description'
                  value={description}
                  onChange={this.handleChange}
                />
                <div className='pt-3'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='green' type='submit'>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
})

const actions = {
  closeModal,
  updateUserProfile
}

export default connect(mapState, actions)(ModalProfileActivities)
