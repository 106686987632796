import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Checkbox, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment.js';
import { closeModal } from '../../../../common/ui/modal/ModalActions';
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions';

const initCertification = {
  title: '',
  state: '',
  effectiveDate: null,
  expirationDate: null,
  isPermanent: false
};

class ModalProfileCertifications extends Component {
  constructor(props) {
    super(props);
    const { index, isAdd } = this.props;
    const { certifications } = this.props.user;
    // console.log('certifications: ', certifications);
    const currentCertification = isAdd
      ? Object.assign({}, initCertification)
      : Object.assign({}, certifications[index]);
    this.state = {
      title: currentCertification.title,
      state: currentCertification.state,
      effectiveDate:
        currentCertification.effectiveDate !== null
          ? new Date(currentCertification.effectiveDate)
          : currentCertification.effectiveDate,
      expirationDate:
        currentCertification.expirationDate !== null
          ? new Date(currentCertification.expirationDate)
          : currentCertification.expirationDate,
      isPermanent: currentCertification.isPermanent
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  handleCheckChange(value) {
    this.setState({ ...this.state, isPermanent: value, expirationDate: new Date() });
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  handleRemove() {
    let newCertifications = Object.assign([], this.props.user.certifications);
    newCertifications.splice(this.props.index, 1);
    const form = {
      ...this.props.user,
      certifications: newCertifications
    };
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  handleSubmit() {
    const { index, isAdd } = this.props;
    const { title, state, effectiveDate, expirationDate, isPermanent } = this.state;
    if (
      !title ||
      title.length < 0 ||
      !state ||
      state.length < 0 ||
      !effectiveDate ||
      effectiveDate.length < 0 ||
      (isPermanent !== true && (!expirationDate || expirationDate.length < 0))
    ) {
      return;
    }

    let newCertifications = Object.assign([], this.props.user.certifications);
    if (isAdd) {
      newCertifications.push(this.state);
    } else {
      newCertifications[index] = this.state;
    }
    const sortedCertifications = newCertifications.sort((a, b) => b.effectiveDate - a.effectiveDate);

    const form = {
      ...this.props.user,
      certifications: sortedCertifications
    };
    // console.log('form: ', form);
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  render() {
    const { title, state, effectiveDate, expirationDate, isPermanent } = this.state;
    const { isAdd, isRemove } = this.props;
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>{isAdd === true ? 'Add' : isRemove ? 'Remove' : 'Edit'} Certification/License</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {isRemove === true ? (
              <div>
                <div>
                  <strong>{title}</strong>
                </div>
                <div>
                  <em>{state}</em>
                </div>
                <div>
                  <strong>Effective: </strong>
                  {moment(effectiveDate).format('MMMM DD, YYYY')}
                </div>
                {isPermanent === true ? (
                  <div>
                    <strong>Expiration: </strong>Permanent
                  </div>
                ) : (
                  <div>
                    <strong>Expiration: </strong>
                    {moment(expirationDate).format('MMMM DD, YYYY')}
                  </div>
                )}
                <div className='pt-5'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='orange' type='submit' onClick={this.handleRemove}>
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <Form size='small' onSubmit={this.handleSubmit}>
                <Form.Field
                  name='title'
                  maxLength={64}
                  control={Input}
                  label='Certification/License Title'
                  value={title}
                  onChange={this.handleChange}
                />
                <Form.Field
                  name='state'
                  maxLength={32}
                  control={Input}
                  label='Issuing State'
                  value={state}
                  onChange={this.handleChange}
                />

                <Form.Field>
                  <Checkbox
                    label='This credential does not expire'
                    checked={isPermanent}
                    onChange={(e, { checked }) => this.handleCheckChange(checked)}
                  />
                </Form.Field>

                <Form.Group unstackable widths={2}>
                  <div className='field'>
                    <label>Effective Date</label>
                    <DatePicker
                      selected={effectiveDate}
                      onChange={(date) => this.setState({ ...this.state, effectiveDate: date })}
                    />
                  </div>

                  <div className='field'>
                    <label>Expiration Date</label>
                    {isPermanent === true ? (
                      <div style={{ paddingTop: '.75rem' }}>Permanent</div>
                    ) : (
                      <DatePicker
                        selected={expirationDate}
                        onChange={(date) => this.setState({ ...this.state, expirationDate: date })}
                      />
                    )}
                  </div>
                </Form.Group>
                <div className='pt-3'>
                  <Button color='grey' className='mr-3' onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  <Button color='green' type='submit'>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
});

const actions = {
  closeModal,
  updateUserProfile
};

export default connect(mapState, actions)(ModalProfileCertifications);
