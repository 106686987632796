import { createReducer } from '../../util/ReducerUtil'
import {
  ACTIVATE_NAV_ITEM,
  TOGGLE_MOBILE_NAV,
  TOGGLE_SEARCH_BAR,
  SET_RETURN_URL,
  TOGGLE_SIDE_BAR,
  SET_SEO,
} from './navConstants'

const initialSEO = {
  title: 'SPEDxchange',
  description: 'The Community for Special Education Answers, Resources, and Jobs',
  image: 'https://www.spedxchange.com/assets/img/spedxchange-social.png',
  url: 'https://www.spedxchange.com',
  type: 'website',
  siteName: 'SPEDxchange',
  alt: 'SPEDxchange',
  robots: 'index,follow',
  seoScript: {},
}

const initialState = {
  isMobileNavOpen: false,
  isSearchBarOpen: false,
  isSideBarOpen: true,
  activeNavItem: {
    name: 'SPEDxchange',
    link: '/questions',
  },
  seoTags: initialSEO,
}

const onNavItemClick = (state, payload) => {
  return { ...state, activeNavItem: payload.item }
}

const toggleMobileNav = (state) => {
  return { ...state, isMobileNavOpen: !state.isMobileNavOpen }
}

const toggleSearchBar = (state) => {
  return { ...state, isSearchBarOpen: !state.isSearchBarOpen }
}

const toggleSideBar = (state, payload) => {
  return { ...state, isSideBarOpen: payload }
}

const setReturnUrl = (state, payload) => {
  return { ...state, returnUrl: payload }
}

const setSEO = (state, payload) => {
  return { ...state, seoTags: payload || initialSEO }
}

export default createReducer(initialState, {
  [ACTIVATE_NAV_ITEM]: onNavItemClick,
  [TOGGLE_MOBILE_NAV]: toggleMobileNav,
  [TOGGLE_SEARCH_BAR]: toggleSearchBar,
  [TOGGLE_SIDE_BAR]: toggleSideBar,
  [SET_RETURN_URL]: setReturnUrl,
  [SET_SEO]: setSEO,
})
