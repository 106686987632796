import { Breadcrumb, Icon, Table } from 'semantic-ui-react'
import { QueryClient, QueryClientProvider, useInfiniteQuery } from 'react-query'
import { useEffect, useRef } from 'react'

import { Link } from 'react-router-dom'
import React from 'react'

const queryClient = new QueryClient()
queryClient.removeQueries('districts')

const fetchDistricts = async ({
  pageParam = '/api/districts/page?offset=0&limit=50',
}) => {
  const request = await fetch(pageParam)
  const { results, next } = await request.json()
  return { response: results, nextPage: next }
}

const DistrictsList = () => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery('districts', fetchDistricts, {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    })

  const loadMoreRef = useRef()

  useEffect(() => {
    if (!hasNextPage) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: '0px',
        treshold: 1.0,
      }
    )

    const el = loadMoreRef && loadMoreRef.current

    if (!el) {
      return
    }

    observer.observe(el)
  }, [fetchNextPage, hasNextPage])

  return (
    <div className='App'>
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <>
          <Table unstackable selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>State</Table.HeaderCell>
                <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                <Table.HeaderCell width={2}>Schools</Table.HeaderCell>
                <Table.HeaderCell width={2}>Students</Table.HeaderCell>
                <Table.HeaderCell width={2}>Teachers</Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  textAlign='right'></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.pages.map((group, i) =>
                group.response.map((district) => (
                  <Table.Row key={district._id}>
                    <Table.Cell width={10}>
                      <Link
                        to={`/spedxchange-reports/districts/${district._id}`}
                        title='View School District'>
                        {district.district_name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{district.location.state}</Table.Cell>
                    <Table.Cell>
                      {district.locale_type_id.locale_size}{' '}
                      {district.locale_type_id.locale_type}
                    </Table.Cell>
                    <Table.Cell>{district.school_count}</Table.Cell>
                    <Table.Cell>{district.student_count}</Table.Cell>
                    <Table.Cell>{district.teacher_count}</Table.Cell>
                    <Table.Cell textAlign='right'>
                      <Icon
                        link
                        className='ml-2'
                        color='green'
                        name='pencil square'
                        title='Details'
                        to={`/spedxchange-reports/districts/${district._id}`}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
              <Table.Row>
                <Table.Cell>
                  <div
                    ref={loadMoreRef}
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}>
                    {isFetchingNextPage
                      ? 'Loading more...'
                      : hasNextPage
                      ? 'Load More'
                      : 'Nothing more to load'}
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  )
}

const DistrictsMain = () => {
  return (
    <>
      <div className='admin-nav'>
        <div className='flex-box between'>
          <div className='flex-box align-center grow'>
            <Breadcrumb size='large'>
              <Breadcrumb.Section>
                <Link to={`/spedxchange-reports`} title='View School District'>
                  Management
                </Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section>Public School Districts</Breadcrumb.Section>
            </Breadcrumb>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
      <div>
        <QueryClientProvider client={queryClient}>
          <DistrictsList />
        </QueryClientProvider>
      </div>
    </>
  )
}
export default DistrictsMain
