/* eslint no-useless-escape: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { closeModal } from '../../../common/ui/modal/ModalActions'
import { saveApplicantInfo } from '../actions/jobsActions'
import { Modal, Button, Input } from 'semantic-ui-react'

export class JopApplicantModal extends Component {
  constructor(props) {
    super(props)
    this.onRouteChanged = this.onRouteChanged.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    this.handleCloseModal()
  }

  handleCloseModal() {
    this.props.closeModal()
  }

  handleNameChange(e, data) {
    this.setState({
      nameValue: data.value,
    })
  }

  handleEmailChange(e, data) {
    this.setState({
      emailValue: data.value,
    })
  }

  validateEmail() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.emailValue
      )
    ) {
      return true
    }
    return false
  }

  handleSubmission() {
    if (this.state.nameValue.length > 1 && this.validateEmail()) {
      this.props.saveApplicantInfo(
        this.state.nameValue,
        this.state.emailValue,
        this.props.job._id
      )
      this.props.closeModal()
      window.location.href = this.props.job.applyLink
    } else {
      alert(
        'Please fill in both your full name and email to be able to proceed to the job posting.'
      )
    }
  }

  render() {
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>Ready to apply...</Modal.Header>
        <Modal.Content>
          Please provide your name and email address.
        </Modal.Content>
        <Modal.Content>
          <label>Name: </label>
          <Input onChange={this.handleNameChange} placeholder='John Doe' />
        </Modal.Content>
        <Modal.Content>
          <label>Email: </label>
          <Input
            type='text'
            onChange={this.handleEmailChange}
            placeholder='example@gmail.com'
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSubmission}>
            Apply
          </Button>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
})

const mapDispatchToProps = {
  closeModal,
  saveApplicantInfo,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JopApplicantModal))
