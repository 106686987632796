import React from 'react';

const ProfileSection = ({ title, handleEdit, children }) => {
  return (
    <section>
      <div className='section-header'>
        {title && (
          <h4>
            <strong>{title}</strong>
          </h4>
        )}
        {handleEdit && (
          <span className='link' onClick={handleEdit}>
            Edit
          </span>
        )}
      </div>
      <div className='section-body'>{children}</div>
    </section>
  );
};

export default ProfileSection;
