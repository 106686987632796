import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ReportOrganizationJobPage extends Component {
  render() {
    return <h3>View/Edit Job Coming Soon!</h3>
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReportOrganizationJobPage)
