import { createReducer } from '../../common/util/ReducerUtil'
import {
  REPORT_ARTICLE_FETCH,
  REPORT_ARTICLE_FETCH_ALL,
  REPORT_EVENTS_FETCH,
  REPORT_JOB_FETCH,
  REPORT_JOB_CLEAR,
  REPORT_JOB_SAVE,
  REPORT_ORG_FETCH,
  REPORT_ORG_FETCH_ALL,
  REPORT_USER_FETCH_ALL
} from './ReportContants'

const initialState = {}

const fetchAllArticles = (state, payload) => {
  return {
    ...state,
    articles: payload
  }
}

const fetchArticle = (state, payload) => {
  return {
    ...state,
    article: payload
  }
}

const fetchAllOrgs = (state, payload) => {
  return {
    ...state,
    organizations: payload
  }
}

const fetchOrg = (state, payload) => {
  return {
    ...state,
    org: payload
  }
}

const fetchJob = (state, payload) => {
  return {
    ...state,
    job: payload
  }
}

const clearJob = (state, payload) => {
  return {
    ...state,
    job: payload
  }
}

const saveJob = (state, payload) => {
  return {
    ...state,
    job: payload
  }
}

const fetchEvents = (state, payload) => {
  return {
    ...state,
    events: payload
  }
}

const fetchUsers = (state, payload) => {
  return {
    ...state,
    users: payload
  }
}

export default createReducer(initialState, {
  [REPORT_ARTICLE_FETCH_ALL]: fetchAllArticles,
  [REPORT_ARTICLE_FETCH]: fetchArticle,
  [REPORT_EVENTS_FETCH]: fetchEvents,
  [REPORT_JOB_FETCH]: fetchJob,
  [REPORT_JOB_CLEAR]: clearJob,
  [REPORT_JOB_SAVE]: saveJob,
  [REPORT_ORG_FETCH]: fetchOrg,
  [REPORT_ORG_FETCH_ALL]: fetchAllOrgs,
  [REPORT_USER_FETCH_ALL]: fetchUsers
})
