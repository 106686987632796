import axios from 'axios'
import { FETCH_LATEST_NEWS, FETCH_CURRENT_ARTICLE } from './newsConstants'
import { SET_SEO } from '../../common/ui/nav/navConstants'
import { asyncActionStart, asyncActionFinish, asyncActionError } from '../../common/actions/async/asyncActions'

export const loadArticles = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('loadArticles'))
      const articles = await axios.get('/api/news')
      dispatch({ type: FETCH_LATEST_NEWS, payload: articles.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const loadLatestArticles = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('loadLatestArticles'))
      const articles = await axios.get('/api/news/latest')
      dispatch({ type: FETCH_LATEST_NEWS, payload: articles.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const loadArticle = (uid, slug) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const fetch = await axios.get(`/api/news/${uid}/${slug}`)
      const article = fetch.data[0]
      const seo = {
        title: `SPED News: ${article.title}` || 'SPEDxchange',
        description: article.summary || 'The Community for Special Education Answers, Resources, and Jobs',
        image: `https://spedxchange.s3.us-east-2.amazonaws.com/news/${article.uid}/${article.photoURL}.jpg`,
        url: `https://www.spedxchange.com/news/${article.uid}/${article.slug}`,
        type: 'article',
        siteName: 'SPEDxchange',
        alt: article.title,
        robots: 'index,follow',
      }
      const ldjson = {
        '@context': 'https://schema.org/',
        '@type': 'NewsArticle',
        headline: `SPED News: ${article.title}` || 'SPEDxchange',
        image:
            [`https://spedxchange.s3.us-east-2.amazonaws.com/news/${article.uid}/${article.photoURL}.jpg`],
        datePublished: article.created,
        dateModified: article.updated,
        author: [{
          "@type": "Organization",
          name: "SPEDxchange",
          url: "https://www.spedxchange.com"
        }]
      }
      if (article.guestBloggerName.length) {
        ldjson.author.push(
            {
              "@type": "Person",
              name: article.guestBloggerName,
              url: "https://www.spedxchange.com"
            }
        )
      }
      seo.seoScript = ldjson
      dispatch({ type: SET_SEO, payload: seo })
      dispatch({ type: FETCH_CURRENT_ARTICLE, payload: article })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const setNewsPageSEO = () => {
  return (dispatch) => {
    const seo = {
      title: 'SPEDxchange: SPED News',
      description: 'Special Education News',
      image: 'https://www.spedxchange.com/assets/img/spedxchange-social.png',
      url: `https://www.spedxchange.com/news`,
      type: 'webpage',
      siteName: 'SPEDxchange',
      alt: 'SPEDxchange: News',
      robots: 'index,follow',
    }
    dispatch({ type: SET_SEO, payload: seo })
  }
}
