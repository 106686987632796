import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { closeModal } from '../../../common/ui/modal/ModalActions'
import { Button, Modal } from 'semantic-ui-react'
import { toastr } from 'react-redux-toastr'
import axios from "axios";
import {HEADER_JSON} from "../../../common/constants/apiConstants";

export class ConfirmCancel extends Component {
  constructor(props) {
    super(props)
    this.onRouteChanged = this.onRouteChanged.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    this.handleCloseModal()
  }

  async handleCancelSubscription() {
    const { org } = this.props

    const currentOrganization = Object.assign({}, org)
    currentOrganization.status = 'trial'
    currentOrganization.statusCode = '1'
    currentOrganization.subscriptionType = ''
    currentOrganization.subscriptionEndDate = ''
    currentOrganization.userEmail = this.props.user.email
    currentOrganization.clientName = this.props.user.firstName
    currentOrganization.cancelSubscription = true

    await axios.put(
        `/api/organization/${currentOrganization._id}`,
        currentOrganization,
        HEADER_JSON
    )

    toastr.success(
      'Cancellation Initiated!',
      'Your subscription has been cancelled and you will not be billed for the next period.'
    )

    setTimeout(() => {
      window.location = 'https://spedxchange.com/admin'
    }, 2000)
    this.props.closeModal()
  }

  handleCloseModal() {
    this.props.closeModal()
  }

  render() {
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>Cancel Subscription</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>
              Are you sure you would like to <strong>cancel</strong> your
              SPEDxchange Subscription?
            </div>
            <div>
              <i>
                Note: Doing so will result in your organization's account access
                being limited and switched to a trial account. You will then not be charged for
                the next upcoming billing period.
              </i>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.handleCloseModal}>
            No, Don't Cancel
          </Button>
          <Button color='red' onClick={this.handleCancelSubscription}>
            Yes, Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmCancel))
