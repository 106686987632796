import React, { forwardRef } from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = forwardRef((props, ref) => {
  // console.log('props: ', props);
  return (
    <label className='switch'>
      <input type='checkbox' ref={ref} onChange={props.onCheckChange} checked={props.isChecked} />
      <span className='slider round'></span>
    </label>
  );
});

export default ToggleSwitch;
