import { createReducer } from '../../../common/util/ReducerUtil'
import {FETCH_BACKFILL_JOBS, FETCH_LOCAL_JOBS, FETCH_LOCAL_JOB, FETCH_SAVED_JOBS} from './jobsConstants'

const initialState = {
  job: {},
  backfillJobs: [],
  localJobs: [],
  userSavedJobs: [],
}

const loadBackfilJobs = (state, payload) => {
  return {
    ...state,
    backfillJobs: payload.jobs,
    localJobs: payload.localJobs,
  }
}

const loadLocalJobs = (state, payload) => {
  return {
    ...state,
    localJobs: payload,
  }
}

const fetchLocalJob = (state, payload) => {
  return {
    ...state,
    job: payload,
  }
}

const fetchUserSavedJobs = (state, payload) => {
  return {
    ...state,
    userSavedJobs: payload,
  }
}

export default createReducer(initialState, {
  [FETCH_BACKFILL_JOBS]: loadBackfilJobs,
  [FETCH_LOCAL_JOBS]: loadLocalJobs,
  [FETCH_LOCAL_JOB]: fetchLocalJob,
  [FETCH_SAVED_JOBS]: fetchUserSavedJobs,
})
