import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavBar from '../../common/ui/nav/navBar/NavBar';
import SearchBar from '../../feature/search/searchBar/SearchBar';
import AppFooter from '../../common/ui/footer/AppFooter';
import NavMobile from '../../common/ui/nav/navMobile/NavMobile';

import HomeIntroSection from './components/HomeIntroSection';
import HomeAdminSection from './components/HomeAdminSection';
import HomeMessageSection from './components/HomeMessageSection';
import HomeSpedSection from './components/HomeSpedSection';
import HomeScholarSection from './components/HomeScholarSection';
import HomeNewsSections from './components/HomeNewsSections';
import HomeFindJob from "./components/HomeFindJob";
import ImageSlider from "./components/ImageSlider";
import './components/Home.scss';

export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.spedRef = React.createRef();
    this.adminRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(ref) {
    switch (ref) {
      case 'sped':
        window.scrollTo({
          top: this.spedRef.current.offsetTop - 40,
          behavior: 'smooth'
        });
        break;
      case 'admin':
        window.scrollTo({
          top: this.adminRef.current.offsetTop - 40,
          behavior: 'smooth'
        });
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <>
        <NavBar />
        <SearchBar />
        <HomeIntroSection handleScroll={this.handleScroll} />
          <HomeFindJob></HomeFindJob>
        <HomeAdminSection adminRef={this.adminRef} />
          <ImageSlider images={clients} />
        <HomeMessageSection />
        <HomeSpedSection spedRef={this.spedRef} />
        <HomeNewsSections />
        <HomeScholarSection />
        <div className='footer-wrap'>
          <AppFooter />
        </div>
        <NavMobile />
      </>
    );
  }
}

const clients = [
    {
        src: 'https://www.nashua.edu/cms/lib/NH50000505/Centricity/Template/18/images/default-subpage-logo.png',
        alt: 'Nashua School District',
        link: 'https://www.nashua.edu',
    },
    {
        src: 'https://district.tusd1.org/_theme/images/Shared%20Images/District%20Logos/School%20Footer%20District%20Logos/TUSDBlk.png',
        alt: 'Tucson Unified School District',
        link: 'http://central.tusd1.org',
    },
    {
        src: 'https://b2281138.smushcdn.com/2281138/wp-content/uploads/2021/04/LISD-Logo_resize.png?lossy=1&strip=1&webp=1',
        alt: 'Leander ISD',
        link: 'https://www.leanderisd.org',
    },
    {
        src: 'https://greatoakslegacy.org/wp-content/themes/golcs/assets/images/great-oaks-legacy-charter-school--news-default.png',
        alt: 'Great Oaks Legacy Charter Schools',
        link: 'https://www.greatoakslegacy.org',
    },
    {
        src: 'https://images.squarespace-cdn.com/content/v1/59c31631a9db09b82ad732b7/1507126452295-N3J5J4X0CQR3TWMVPGKV/VOICE+Logo+Larger+Background+%281%29.jpg',
        alt: 'Voice Charter School',
        link: 'https://www.voicecharterschool.org',
    },
    {
        src: 'https://leadpublicschools.org/wp-content/uploads/2019/06/LEADPublicSchools.png',
        alt: 'LEAD Public Schools',
        link: 'https://leadpublicschools.org',
    },
    {
        src: 'https://chicago.ombudsman.com/wp-content/uploads/2019/09/Ombudsman-Chicago-Alternative-Schools-Logo-scaled.jpg',
        alt: 'Ombudsman Educational Services',
        link: 'https://chicago.ombudsman.com/south/',
    },
    {
        src: 'https://careers.chancelight.com/wp-content/uploads/2018/05/chancelight-logo-adapted-r.svg?fsum=152a4de9ac69',
        alt: 'ChanceLight Education',
        link: 'https://chancelight.com',
    },
    {
        src: 'https://static1.squarespace.com/static/61b940875ff4e70aea37c550/t/61baf074e87b3c39cf13e274/1677015682685/',
        alt: 'KIPP Memphis Public Schools',
        link: 'https://www.kippmemphis.org',
    },
    {
        src: 'https://www.providenceschools.org/cms/lib/RI01900003/Centricity/Domain/236/ppsd_logo.jpg',
        alt: 'Providence School Department',
        link: 'https://www.providenceschools.org',
    },
    {
        src: 'https://yt3.googleusercontent.com/ytc/AL5GRJXdw03pVDjI_1vfyChzskbVj3oohU9wVBgbwfI5=s900-c-k-c0x00ffffff-no-rj',
        alt: 'Lincoln County SD',
        link: 'https://lincoln.k12.or.us/',
    },
    {
        src: 'https://registration.rocklinusd.org/registration/AppSettings/DistrictLogo.png',
        alt: 'Rocklin Unified School District',
        link: 'https://www.rocklinusd.org',
    },
    {
        src: 'https://teachereducation.steinhardt.nyu.edu/wp-content/uploads/sites/13/2022/12/bedford-stuyvesant-new-beginnings-charter-school-logo-2.png',
        alt: 'Bedford Stuyvesant New Beginnings Charter School',
        link: 'https://bsnbcs.org/careers/',
    },
    {
        src: 'https://c.smartrecruiters.com/sr-company-images-prod-aws-dc5/5f5ba9ae70ade110632b7618/d3c99c4d-0421-47a3-893a-bbcb65c587f8/huge?r=s3-eu-central-1&_1600381897596',
        alt: 'KIPP St. Louis',
        link: 'https://www.kippstl.org/careers',
    },
    {
        src: 'https://voa-production.s3.amazonaws.com/uploads/affiliate_site/logo_image_color/8/affiliate-greater-new-york.png',
        alt: 'Volunteers of America - Greater NY/ Bronx Early Learning Center',
        link: 'https://www.voa-gny.org',
    },
    {
        src: 'https://nyc.uncommonschools.org/wp-content/uploads/sites/7/2019/05/US_NewYorkCity_RGB-1.jpg',
        alt: 'Rocklin Unified School District',
        link: 'https://uncommonschools.org',
    },
    {
        src: 'https://s3.amazonaws.com/dm-e-prod/images/nonprofits/120201logo1549313772_400.jpg',
        alt: 'Soaring Heights Charter School',
        link: 'https://www.shcsjc.org',
    },
    {
        src: 'https://storage.googleapis.com/pt04-2/images/tinymce/9e0705ac63b2831f970401af1bcccb354884fc8e/Logo_-_Wide.png',
        alt: 'CCSD 93',
        link: 'https://www.ccsd93.com',
    },
    {
        src: 'https://www.nicepng.com/png/detail/332-3321884_bright-star-charter-schools-bright-star-schools-logo.png',
        alt: 'Bright Star Schools',
        link: 'https://brightstarschools.org',
    },
    {
        src: 'https://nobleschools.org/wp-content/uploads/2021/09/Logo-Blog-Cover-Final.jpg',
        alt: 'Noble Schools',
        link: 'https://nobleschools.org/',
    },
    {
        src: 'https://www.ppsel.org/wp-content/uploads/2018/05/600xhoriz.png',
        alt: 'Pikes Peak School of Expeditionary Learning',
        link: 'https://www.ppsel.org',
    },
    {
        src: 'https://www.pellaschools.org/wp-content/uploads/2020/04/RGB_pcsd_horiz_tag_GWB.png',
        alt: 'Pella Community School District',
        link: 'https://www.pellaschools.org/',
    },
    {
        src: 'https://cdn.schoolblocks.com/organizations/911d2f74-74c4-42a7-be5d-3b5eb2455115/nhex27-rfsd-district-topleft-logo2.png',
        alt: 'Roaring Fork School District',
        link: 'https://www.rfsd.k12.co.us/en-US',
    },
    {
        src: 'https://www.baltimoremagazine.com/wp-content/uploads/2020/09/KIPP-Baltimore-logo__cmyk.jpg',
        alt: 'KIPP Baltimore Schools',
        link: 'https://kippbaltimore.org',
    },
    {
        src: 'https://nyc.uncommonschools.org/wp-content/uploads/sites/7/2019/05/US_ExcellenceGirls_RGB.jpg',
        alt: 'Excellence Girls Charter Schools',
        link: 'http://uncommonschools.org/',
    },
    {
        src: 'https://www.mauryk12.org/cms/lib/TN50010962/Centricity/Template/GlobalAssets/images///logos/MCPS-LOGO-100100-PNG.png',
        alt: 'Mary County Public Schools',
        link: 'https://www.mauryk12.org/',
    },
    {
        src: 'https://cdnsm5-ss10.sharpschool.com/UserFiles/Servers/Server_68892/Image/Logo/SBLogo.png',
        alt: 'South Bend Community School Corporation',
        link: 'https://www.sb.school/',
    },
    {
        src: 'https://resources.finalsite.net/images/f_auto,q_auto,t_image_size_2/v1623270770/wsd3org/qq0rj4nai7tqriun7xsm/WSD3_Logo_Vector_largeJPEG.jpg',
        alt: 'Widefield School District',
        link: 'https://www.wsd3.org/',
    },
    {
        src: 'https://1.bp.blogspot.com/-Y11yhIWnzsA/W69eHlJQ-EI/AAAAAAADeug/0P6P9qzJ8O4i0-YZpyfzsWhkPwY2hUqbACPcBGAYYCw/s1600/FranklinStock_CharterSchool.jpg',
        alt: 'Benjamin Franklin Classical Charter Public School',
        link: 'http://bfccps.org',
    },
    {
        src: 'https://www.aspenacademy.org/uploaded/themes/default_18/images/logo.svg',
        alt: 'Aspen Academy',
        link: 'https://www.aspenacademy.org',
    },

];

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage));
