import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, Input, Dropdown, Button, Breadcrumb } from 'semantic-ui-react'
import { Editor } from '@tinymce/tinymce-react'
import { createJob, updateJobProp, saveJob, clearJob } from '../../AdminActions'
import AdminActivatePage from './AdminActivatePage'
import Loading from '../../../../common/ui/loading/Loading'
import AdminJobPreview from './AdminJobPreview'
import ToggleSwitch from '../../../profile/components/ToggleSwitch/ToggleSwitch'
import AdminClientSelector from '../AdminClientSelector'
import {toastr} from "react-redux-toastr";

export class AdminJobForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      ...emptyJob,
      preview: false,
    }

    this.handleTogglePreview = this.handleTogglePreview.bind(this)
    this.handleRemoteChange = this.handleRemoteChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.onSalaryRangeSelect = this.onSalaryRangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      const id = this.props.match.params.id
      const { org } = this.props
      console.log('org: ', org)
      let job = { ...emptyJob }
      job.organization = org._id
      if (Array.isArray(this.props.admin.org.jobs) && id !== 'new') {
        const findJob =
          this.props.admin.org.jobs.find((j) => j._id === id) || {}
        job = { ...job, ...findJob }
      }
      if (!job.jobAdmin) job.jobAdmin = org.users[0]._id
      if (!job.location) {
        job.location = org.locations[0]._id
        job.name = org.locations[0].name
        job.city = org.locations[0].city
        job.state = org.locations[0].state
      }
      console.log('job: ', job)
      this.setState({ ...this.state, ...job, loaded: true })
      if(this.state.applyLink.includes("@")) {
        this.setState({ applyLink: this.state.applyLink.substring(this.state.applyLink.indexOf(":")+1, this.state.applyLink.length) })
      }
      }, 3000)
  }

  handleTogglePreview(e) {
    this.setState({
      ...this.state,
      preview: !this.state.preview,
    })
  }

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  }

  handleRemoteChange(e) {
    this.setState({ ...this.state, remote: e.target.checked })
  }

  handleSelectChange(e, data) {
    this.setState({ ...this.state, [data.name]: data.value })
  }

  handleLocationChange(e, data) {
    const findLoc = this.props.admin.locationOptions.find(
      (opt) => opt.key === data.value
    )
    this.setState({
      ...this.state,
      [data.name]: data.value,
      loc: findLoc.loc,
      name: findLoc.name,
      city: findLoc.city,
      state: findLoc.state,
    })
  }

  handleEditorChange(content, editor) {
    this.setState({ ...this.state, description: content })
  }

  onSalaryRangeSelect(e) {
    this.setState({ ...this.state, salaryPeriod: e.target.textContent })
  }

  async handleSubmit(event, publish) {
    event.preventDefault()

    const job = {
      _id: this.state._id,
      jobId: this.state.jobId,
      jobAdmin: this.state.jobAdmin,
      jobType: this.state.jobType,
      remote: this.state.remote,
      title: this.state.title,
      summary: this.state.summary,
      description: this.state.description,
      applyLink: this.state.applyLink,
      location: this.state.location,
      loc: this.state.loc,
      status: publish === 'publish' ? 'Published' : 'Draft',
      salaryPeriod: this.state.salaryPeriod,
      salaryAmount: this.state.salaryAmount,
      organization: this.props.admin.org._id,
    }

    if(job.applyLink.includes("@") && job.applyLink.includes(".com")) {
      job.applyLink = "mailto:"+job.applyLink
    }

    this.setState({ ...this.state, status: job.status, loaded: true })

    if (this.props.match.params.id !== 'new') {
      await this.props.saveJob(job)
    } else if(this.state.title !== "" && this.state.summary !== "" && this.state.description !== "" && this.state.applyLink !== "" && this.state.jobType !== ""){
      await this.props.createJob(job, this.props.history)
    } else {
      toastr.error('Error!', 'Input all required information to successfully post the job')
    }
  }

  render() {
    if (!this.props.admin.userOptions || !this.props.admin.locationOptions)
      return <Loading />
    const {
      jobAdmin,
      jobType,
      jobId,
      title,
      summary,
      remote,
      description,
      status,
      salaryAmount,
      applyLink,
      location,
    } = this.state
    const { userOptions, locationOptions } = this.props.admin
    if (this.props.org?.status === 'trial') return <AdminActivatePage />
    return (
      <div>
        <AdminClientSelector />
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/admin')}>
                  Dashboard
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/admin/jobs')}>
                  Jobs
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section>Edit Job</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div className='btn-height'></div>
          </div>
        </div>

        {this.state.loaded ? (
          <Form onSubmit={this.handleSubmit}>
            <div className='job-edit flex-box sm'>
              {this.state.preview ? (
                <div className='grow pr-4'>
                  <AdminJobPreview data={this.state} />
                </div>
              ) : (
                <div className='grow pr-4'>
                  <div className='mb-4'>
                    <label>Title</label>
                    <Form.Input
                      name='title'
                      value={title}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className='mt-4'>
                    <label>Summary</label>
                    <Form.Input
                      name='summary'
                      value={summary}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='mt-4'>
                    <label>Description</label>
                    <Editor
                      initialValue={
                        description && description.length > 0
                          ? description
                          : `<p></p>`
                      }
                      value={description}
                      apiKey='twpt6v84p920kri6p37w1wk4258x70z5e2yjhikzlu6mysb6'
                      onEditorChange={this.handleEditorChange}
                      init={{
                        height: 400,
                        menubar: false,
                        elementpath: false,
                        plugins: ['lists link searchreplace fullscreen paste'],
                        toolbar:
                          'fullscreen | bold italic underline strikethrough | bullist numlist | link ',
                        default_link_target: '_blank',
                        link_assume_external_targets: true,
                        link_title: false,
                        target_list: false,
                      }}
                    />
                  </div>
                </div>
              )}
              <div className='spacer'></div>
              <div className='publish-panel'>
                <label>&nbsp;</label>
                <div className='flex-box between mb-4'>
                  <div className='half'>
                    <Button
                      fluid
                      color='grey'
                      type='button'
                      content={this.state.preview ? 'Edit' : 'Preview'}
                      onClick={this.handleTogglePreview}
                    />
                  </div>
                  <div className='half'>
                    <Button
                      fluid
                      color='blue'
                      content='Save'
                      onClick={(e) =>
                        this.handleSubmit(
                          e,
                          this.state.status === 'Published'
                            ? 'publish'
                            : 'unpublish'
                        )
                      }
                    />
                  </div>
                </div>
                <div className='mb-4'>
                  {status === 'Published' ? (
                    <Button
                      fluid
                      color='grey'
                      content='Unpublish'
                      onClick={(e) => this.handleSubmit(e, 'unpublish')}
                    />
                  ) : (
                    <Button
                      fluid
                      color='green'
                      content='Publish'
                      onClick={(e) => this.handleSubmit(e, 'publish')}
                    />
                  )}
                </div>

                <div className='mb-4'>
                  <ToggleSwitch
                    onCheckChange={this.handleRemoteChange}
                    isChecked={remote}
                  />{' '}
                  &nbsp;This is a Remote Job
                </div>

                <div className='mb-4'>
                  <label>Job ID</label>
                  <Form.Input
                    name='jobId'
                    value={jobId}
                    onChange={this.handleChange}
                  />
                </div>

                {userOptions && userOptions.length > 1 && (
                  <div className='mb-4'>
                    <label>Job Administrator</label>
                    <Form.Select
                      fluid
                      name='jobAdmin'
                      value={jobAdmin}
                      onChange={this.handleSelectChange}
                      options={userOptions}
                    />
                  </div>
                )}

                {locationOptions && locationOptions.length > 1 && (
                  <div className='mb-4'>
                    <label>Location</label>
                    <Form.Select
                      fluid
                      name='location'
                      value={location}
                      onChange={this.handleLocationChange}
                      options={locationOptions}
                    />
                  </div>
                )}

                <div className='mb-4'>
                  <label>Application Link</label>
                  <Form.Input
                    name='applyLink'
                    value={applyLink}
                    onChange={this.handleChange}
                  />
                </div>

                <div className='mb-4'>
                  <label>Job Type</label>
                  <Form.Select
                    fluid
                    name='jobType'
                    value={jobType}
                    onChange={this.handleSelectChange}
                    options={jobTypeOptions}
                  />
                </div>

                <div className='mb-4'>
                  <label>Salary</label>
                  <Input
                    fluid
                    label={
                      <Dropdown
                        defaultValue='Year'
                        options={salaryPeriodOptions}
                        onChange={this.onSalaryRangeSelect}
                      />
                    }
                    icon='dollar sign'
                    iconPosition='left'
                    labelPosition='right'
                    name='salaryAmount'
                    maxLength = "50"
                    value={salaryAmount}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

const emptyJob = {
  _id: 'new',
  jobAdmin: null,
  jobType: 'Full-time',
  jobId: '',
  remote: false,
  title: '',
  name: '',
  city: '',
  state: '',
  summary: '',
  description: '',
  applyLink: '',
  location: null,
  status: 'Draft',
  salaryPeriod: 'Year',
  salaryAmount: '',
}

const jobTypeOptions = [
  { key: 'Full-time', text: 'Full-time', value: 'Full-time' },
  { key: 'Part-time', text: 'Part-time', value: 'Part-time' },
  { key: 'Substitute', text: 'Substitute', value: 'Substitute' },
  { key: 'Intern', text: 'Intern', value: 'Intern' },
  { key: 'Contract', text: 'Contract', value: 'Contract' },
  { key: 'Seasonal', text: 'Seasonal', value: 'Seasonal' },
]

const salaryPeriodOptions = [
  { key: 'Year', text: 'Year', value: 'Year' },
  { key: 'Hour', text: 'Hour', value: 'Hour' },
  { key: 'Day', text: 'Day', value: 'Day' },
]

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.loadingName,
  auth: state.auth,
  admin: state.admin,
  org: state.admin.org,
})

const mapDispatchToProps = {
  createJob,
  updateJobProp,
  saveJob,
  clearJob,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminJobForm))
