import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'
import { fetchOrganization } from '../../../common/ui/auth/AuthActions'
import { fetchEvents } from '../../admin/AdminActions'

export class AdminClientSelector extends Component {
  constructor(props) {
    super(props)
    this.onClientChange = this.onClientChange.bind(this)
  }

  async onClientChange(e, data) {
    const selected = data.options.find((i) => i.key === data.value)
    if (selected) await this.props.fetchEvents(selected.text)
    await this.props.fetchOrganization(data.value)
    if (Object.keys(this.props.match.params).length !== 0) {
      this.props.history.push('/admin')
    }
  }

  render() {
    if (!this.props.clientList) return <></>
    const { auth, clientList } = this.props
    const hasKey = clientList.find(
      (c) => `${c.key}` === `${this.props.org._id}`
    )
    const useKey = hasKey ? hasKey.value : auth.currentUser.organizationId
    return (
      <>
        {clientList && auth.isSiteAdmin && (
          <div className='mb-3 max-width-300'>
            <Dropdown
              fluid
              search
              selection
              onChange={this.onClientChange}
              defaultValue={`${useKey}`}
              options={clientList}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  org: state.admin.org,
  clientList: state.auth.clientList,
})

const mapDispatchToProps = {
  fetchOrganization,
  fetchEvents,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminClientSelector))
