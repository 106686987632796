import React, { Component } from 'react'

export default class DistrictDetailsPage extends Component {
  render() {
    return (
      <>
        <h1>District Details Page</h1>
        <p>coming soon</p>
      </>
    )
  }
}
