import { createReducer } from '../../common/util/ReducerUtil'
import {
  FETCH_JOBS,
  FETCH_JOB,
  UPDATE_JOBS,
  FETCH_EVENTS,
  UPDATE_JOB,
  UPDATE_JOB_PROP,
  CLEAR_JOB,
  CLEAR_ORG,
  FETCH_ORG, FETCH_ANALYTIC
} from './AdminConstants'

const emptyJob = {
  jobId: '',
  jobType: 'Full-time',
  title: '',
  summary: '',
  description: '',
  status: 'Draft',
  salaryPeriod: 'Year',
  salaryAmount: '',
  remote: false
}

const setUserOptions = (options) => {
  const userOptions = []
  if (Array.isArray(options)) {
    for (let option of options) {
      userOptions.push({
        key: option._id,
        text: `${option.firstName} ${option.lastName}`,
        value: option._id,
      })
    }
  }
  return userOptions
}

const setLocationOptions = (options) => {
  const locationOptions = []
  if (Array.isArray(options)) {
    for (let option of options) {
      locationOptions.push({
        key: option._id,
        text: `${option.name}`,
        name: `${option.name}`,
        city: `${option.city}`,
        state: `${option.state}`,
        loc: option.loc,
        value: option._id,
      })
    }
  }
  return locationOptions
}

const initialState = {
  jobs: [],
  job: emptyJob,
  fetchOrgAnalytics: [],
}

const clearOrg = (state, payload) => {
  return {
    ...state,
    org: null,
    jobs: null,
    userOptions: null,
    locationOptions: null
  }
}

const fetchOrg = (state, payload) => {
  return {
    ...state,
    org: payload,
    userOptions: setUserOptions(payload.users),
    locationOptions: setLocationOptions(payload.locations)
  }
}

const fetchJobs = (state, payload) => {
  return {
    ...state,
    jobs: payload || []
  }
}

const fetchJob = (state, payload) => {
  return {
    ...state,
    job: payload
  }
}

const fetchEvents = (state, payload) => {
  return {
    ...state,
    events: payload
  }
}

const updateJobs = (state, payload) => {
  return {
    ...state,
    jobs: payload
  }
}

const updateJob = (state, payload) => {
  return {
    ...state,
    job: payload
  }
}

const updateJobProp = (state, payload) => {
  return {
    ...state,
    job: {
      ...state.job,
      [payload.prop]: payload.value
    }
  }
}

const clearJob = (state, payload) => {
  return {
    ...state,
    job: emptyJob
  }
}

const fetchOrgAnalytics = (state, payload) => {
  return {
    ...state,
    orgAnalytics: payload,
  }
}

export default createReducer(initialState, {
  [FETCH_JOBS]: fetchJobs,
  [FETCH_JOB]: fetchJob,
  [FETCH_EVENTS]: fetchEvents,
  [UPDATE_JOBS]: updateJobs,
  [UPDATE_JOB]: updateJob,
  [UPDATE_JOB_PROP]: updateJobProp,
  [FETCH_ORG]: fetchOrg,
  [CLEAR_JOB]: clearJob,
  [CLEAR_ORG]: clearOrg,
  [FETCH_ANALYTIC]: fetchOrgAnalytics,
})
