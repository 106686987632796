import { createReducer } from '../../../util/ReducerUtil'
import { CONTACT_SUCCESS, CONTACT_RESOURCES_SUCCESS, CONTACT_FAIL, CONTACT_CLOSE } from './contactConstants'

const initialState = {
  submitted: false,
  resoucesContactSubmitted: false
}

const contactSuccess = (state) => {
  return {
    ...state,
    submitted: true
  }
}

const contactResourcesSuccess = (state) => {
  return {
    ...state,
    resoucesContactSubmitted: true
  }
}

const contactFail = (state) => {
  return {
    ...state,
    submitted: false,
    resoucesContactSubmitted: false
  }
}

const contactClose = (state) => {
  return {
    ...state,
    submitted: false
  }
}

export default createReducer(initialState, {
  [CONTACT_SUCCESS]: contactSuccess,
  [CONTACT_RESOURCES_SUCCESS]: contactResourcesSuccess,
  [CONTACT_FAIL]: contactFail,
  [CONTACT_CLOSE]: contactClose
})
