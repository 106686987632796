import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ScrollTo, ScrollArea } from 'react-scroll-to'
import { toggleMobileNav } from '../navActions'
import { openModal } from '../../modal/ModalActions'
import { NAV_ITEMS } from '../navConstants'

const mapState = (state) => ({
  auth: state.auth,
  activeNavItem: state.nav.activeNavItem,
  isMobileNavOpen: state.nav.isMobileNavOpen,
})

const actions = {
  toggleMobileNav,
  openModal,
}

class NavMobile extends Component {
  constructor(props) {
    super(props)

    this.navEl = React.createRef()
    this.toggleNav = this.toggleNav.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  toggleNav() {
    setTimeout(() => {
      this.props.toggleMobileNav()
    }, 250)
  }

  handleItemClick(item) {
    document.removeEventListener('mousedown', this.handleOutsideClick)
    this.toggleNav()
    switch (item) {
      case 'login':
        this.props.openModal('LoginModal')
        break

      case 'signup':
        this.props.openModal('RegisterModal')
        break

      case 'contact':
        this.props.openModal('ContactModal')
        break

      default:
        this.props.history.push(item.link)
        break
    }
  }

  handleOutsideClick(event) {
    const isOpen = this.props.isMobileNavOpen
    const isToggleButton =
      event.target.id === 'MobileNavButton' ||
      event.target.id === 'MobileNavButtonBars'
    if (
      isOpen &&
      !isToggleButton &&
      this.navEl.current &&
      !this.navEl.current.contains(event.target)
    ) {
      this.toggleNav()
    }
  }

  render() {
    const navItems = NAV_ITEMS
    return (
      <ScrollTo>
        {({ scrollTo }) => (
          <ScrollArea
            id='navMobile'
            className={
              this.props.isMobileNavOpen ? 'nav-mobile open' : 'nav-mobile'
            }>
            <ul ref={this.navEl}>
              <li>
                <span onClick={() => this.handleItemClick(navItems.news)}>
                  SPED News
                </span>
              </li>
              <li>
                <span
                  onClick={() => this.handleItemClick(navItems.scholarships)}>
                  Scholarships
                </span>
              </li>
              <li>
                <span onClick={() => this.handleItemClick(navItems.resources)}>
                  Resources
                </span>
              </li>
              <li className='nav-spacer'></li>
              <li>
                <span onClick={() => this.handleItemClick(navItems.questions)}>
                  SPED Q&amp;A
                </span>
              </li>
              <li>
                <span onClick={() => this.handleItemClick(navItems.jobs)}>
                  Find Jobs
                </span>
              </li>
            </ul>
          </ScrollArea>
        )}
      </ScrollTo>
    )
  }
}

export default connect(mapState, actions)(withRouter(NavMobile))
