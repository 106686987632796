import React from 'react'
import { Menu, Image, Dropdown } from 'semantic-ui-react'

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const AuthMenu = ({ signOut, onNav, auth }) => {
  const { currentUser, isAdmin, loaded } = auth
  const name = capitalize(currentUser.firstName)
  const isManager =
    loaded &&
    currentUser &&
    currentUser.email &&
    isAdmin &&
    (currentUser.email.toLowerCase().indexOf('@spedxchange.com') > -1 ||
      currentUser.email.toLowerCase().indexOf('@kevin-crawford.com') > -1 ||
      currentUser.email === 'teamclarkandco@gmail.com')
  return (
    <Menu.Item className='user-menu'>
      <Image
        avatar
        spaced='right'
        src={currentUser.photoURL || '/avatar.png'}
      />
      <Dropdown pointing='top right' text={name}>
        <Dropdown.Menu>
          {isAdmin && (
            <Dropdown.Item
              text='Dashboard'
              icon='dashboard'
              onClick={() => onNav('/admin')}
            />
          )}
          {isManager && (
            <Dropdown.Item
              text='Management'
              icon='chart bar'
              onClick={() => onNav('/spedxchange-reports')}
            />
          )}
          <Dropdown.Item
            text='Profile'
            icon='user'
            onClick={() => onNav('/profile')}
          />
           <Dropdown.Item text='Saved Jobs' icon='heart' onClick={() => onNav('/profile/jobs')} />
          <Dropdown.Divider />
          <Dropdown.Item text='Sign Out' icon='power' onClick={signOut} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}

export default AuthMenu
