import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Container } from 'semantic-ui-react'

export class HomeScholarSection extends Component {
  render() {
    const { history } = this.props
    return (
      <section id='HomeScholarSection'>
        <Container>
          <div className='scholarship-info-box'>
            <div className='general'>
              <h3 className='ovo'>SPED Scholarship Alerts</h3>
              <p>
                We've made it simple for you to find funding for your education. Sign up for our newsletter to stay up
                to date with the latest scholarship opportunities.
              </p>
              <div>
                <Button primary onClick={() => history.push('/scholarships')}>
                  View Scholarships
                </Button>
              </div>
            </div>
            <div className='spacer'></div>
            <div className='spedx'>
              <h3 className='ovo'>2022 SPEDxchange Scholarship Winner</h3>
              <p>
                <strong>Introducing the SPEDxchange 2022 Scholarship Recipient: Hannah Lium</strong>
                <br />
                Thank you to everyone who applied to the 2022 Special Education Teacher Scholarship. We’re excited to introduce this year’s SPEDxchange $1K scholarship winner, Hannah Lium!
              </p>
              <div>
                <Button primary onClick={() => history.push('/news/039448/introducing-the-spedxchange-2022-scholarship-recipient-hannah-lium')}>
                  Read more about Hannah
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default withRouter(HomeScholarSection)
