import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import {Breadcrumb, Button, Dropdown, Icon, Table} from 'semantic-ui-react'
import { fetchAllOrgs } from '../../ReportActions'
import {openModal} from "../../../../common/ui/modal/ModalActions";
import {fetchOrganization} from "../../../../common/ui/auth/AuthActions";
import * as _ from 'lodash'

export class ReportOrganizationsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter : ['0', '1', '2', '3', '4']
    }

    this.handleNewOrg = this.handleNewOrg.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterList = this.filterList.bind(this)
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this)
  }

  componentDidMount() {
    if (!Array.isArray(this.props.organizations)) {
      this.props.fetchAllOrgs()
    }
  }

  handleOrganizationChange(organization, action) {
    if(action.toLowerCase() !== "legacy"){
      this.props.openModal('ReportOrganizationModal', { ...this.props, organization: organization, action: action })
    }
    else{
      alert("Legacy partners' status cannot be changed. Please contact site developers for any further questions.");
    }
  }

  handleNewOrg() {
    alert('Coming Soon!')
  }


  filterList() {
    if (this.state.filter) {
      const predicate = function () {
        const args = _.toArray(arguments)
        return (item) => {
          let equalsItemStatus = _.partial(_.isEqual, item.statusCode)
          return args.some(equalsItemStatus)
        }
      }
      return _.filter(this.props.organizations, predicate(...this.state.filter))
    } else {
      return this.props.organizations
    }
  }

  async handleNavigation(org) {
    await this.props.fetchOrganization(org._id)
    this.props.history.push('/admin')
  }

  onFilterChange(e) {
    let filterStatus = e.target.textContent
    let newFilterState;
    if (filterStatus === 'All') {
      this.setState({
        filter: ['0', '1', '2', '3', '4'],
      })
      newFilterState = ['0', '1', '2', '3', '4']
    }
    else {
      const arrayOfStatus = ['Legacy', 'Past Free Trial User', 'Past Paid Client', 'Current Free Trial User', 'Current Paid Client'];
      let state = arrayOfStatus.indexOf(filterStatus);
      newFilterState = [state.toString()]
      this.setState({
        filter: [state.toString()],
      })
    }

    this.setState({ ...this.state, filter: newFilterState })
    this.filterList()
  }

  render() {
    const { organizations } = this.props
    if (!Array.isArray(organizations)) return <div>Loading...</div>

    let oldClientStatus = [];
    let newClientStatus = [];

    let legacyList = [];
    let displayLegacyList = [];

    const sorted = organizations.sort((a, b) => {
      var nameA = a.name.toUpperCase()
      var nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })


    // Populating array with organization status codes
    sorted.forEach(
        organization => {
          if(this.state.filter.includes(organization.statusCode)){
            oldClientStatus.push(organization.statusCode)
          }
        }
    );

    // Populating array with organization legacy status
    sorted.forEach(
        organization => {
          if(this.state.filter.includes(organization.statusCode)){
            legacyList.push(organization.isLegacy)
          }
        }
    );


    // filtering through and setting names corresponding to status code
    for(let x = 0; x < oldClientStatus.length; x++){
      if(oldClientStatus[x] === '1'){
        newClientStatus.push("Past Free Trial User");
      }
      else if(oldClientStatus[x] === '2'){
        newClientStatus.push("Past Paid Client");
      }
      else if(oldClientStatus[x] === '3'){
        newClientStatus.push("Current Free Trial User");
      }
      else if(oldClientStatus[x] === '4'){
        newClientStatus.push("Current Paid Client");
      }
      else if(oldClientStatus[x] === '0'){
        newClientStatus.push("Legacy");
      }
    }

    // filtering through the legacy partners and adding that indication for each member
    for(let y = 0; y < legacyList.length; y++){
      if(legacyList[y]){
        displayLegacyList.push("Yes");
      }
      else{
        displayLegacyList.push("No");
      }
    }


    return (
        <>
          <div className='admin-nav'>
            <div className='flex-box between'>
              <div className='flex-box align-center grow'>
                <Breadcrumb size='large'>
                  <Breadcrumb.Section link onClick={() => this.props.history.push('/spedxchange-reports')}>
                    Management
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right chevron' />
                  <Breadcrumb.Section>Organizations</Breadcrumb.Section>
                </Breadcrumb>
              </div>
              <Button content='New Organization' primary onClick={this.handleNewOrg} />
            </div>
          </div>
          <Dropdown
              placeholder='Filter Organization Status'
              selection
              onChange={this.onFilterChange}
              options={clientFilterOptions}
          />
          <div className='fixed-scroll-table'>
            <Table unstackable selectable compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Legacy</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Members</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Jobs</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign='right'></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(sorted) &&
                this.filterList(sorted).length > 0 &&
                this.filterList(sorted).map((org, index) => (
                    <Table.Row
                        key={org._id}>
                      <Table.Cell width={10}>
                        <Link onClick={() => this.handleNavigation(org)} title='View Organization in Management View'>
                          {org.name}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{newClientStatus[index]}</Table.Cell>
                      <Table.Cell>{displayLegacyList[index]}</Table.Cell>
                      <Table.Cell>{org.users?.length || '0'}</Table.Cell>
                      <Table.Cell>
                        {org.jobs?.filter((i) => i.status !== 'Archived' && i.status !== 'Expired').length || '0'}
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Icon
                            link
                            className='ml-2'
                            color='green'
                            name='pencil square'
                            title='Details'
                            onClick={() => this.props.history.push(`/spedxchange-reports/organizations/${org._id}`)}
                        />
                        <Icon
                            link
                            className='ml-2'
                            color='orange'
                            name='undo'
                            title='Change Client Status'
                            onClick={() => this.handleOrganizationChange(org, newClientStatus[index], index )}
                        />
                      </Table.Cell>
                    </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
    )
  }
}

const clientFilterOptions = [
  { key: 'All', text: 'All', value: 'All' },
  { key: 'Legacy', text: 'Legacy', value: 'Legacy' },
  { key: 'Past Free Trial User', text: 'Past Free Trial User', value: 'Past Free Trial User' },
  { key: 'Past Paid Client', text: 'Past Paid Client', value: 'Past Paid Client' },
  { key: 'Current Free Trial User', text: 'Current Free Trial User', value: 'Current Free Trial User' },
  { key: 'Current Paid Client', text: 'Current Paid Client', value: 'Current Paid Client' },
]

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  users: state.report.users,
  organizations: state.report.organizations
})

const mapDispatchToProps = {
  fetchAllOrgs,
  fetchOrganization,
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportOrganizationsPage))

