import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { reset } from 'redux-form'

import RegisterAdminForm from '../auth/register/RegisterAdminForm'
import { closeModal } from './ModalActions'

const mapState = (state) => ({
  currentModal: state.modals
})

const actions = { closeModal, reset }

class RegisterAdminModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    this.handleCloseModal()
  }

  handleCloseModal = () => {
    if (this.props.currentModal.modalProps && this.props.currentModal.modalProps.fromAuth) {
      this.props.history.goBack()
    }
    this.props.reset('registerForm')
    this.props.closeModal()
  }

  render() {
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>Add Administrator</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <RegisterAdminForm config={this.props.currentModal.modalProps || {}} />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(mapState, actions)(withRouter(RegisterAdminModal))
