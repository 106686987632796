import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import moment from 'moment/moment.js'

export class JobsListLocalItem extends Component {
  render() {
    const { _id, title, organization, location, summary, updated, remote } = this.props.job
    // console.log('q: ', this.props.q)
    ReactGA.event({
      category: organization.name,
      action: 'Job Preview',
      label: title
    })
    return (
      <div className={this.props.active ? 'job-list-item active' : 'job-list-item'}>
        <hr />
        <h3>
          <Link to={`/jobs?${this.props.q}&j=${_id}`} title={title}>
            {title}
          </Link>
        </h3>
        <p>
          <strong>{organization.name}</strong>&nbsp;&nbsp;|&nbsp;{' '}
          {location && location.city && location.state && location.city.length > 0 && location.state.length > 0 && (
            <>
              {remote ? (
                <>
                  <span>Remote, USA</span>
                  &nbsp;&nbsp;|&nbsp;{' '}
                </>
              ) : (
                <>
                  <span>
                    {location.city}, {location.state}
                  </span>
                  &nbsp;&nbsp;|&nbsp;{' '}
                </>
              )}
            </>
          )}
          <span>{moment(updated).format('MMM D, YYYY')}</span>
        </p>
        <p>{summary}</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobsListLocalItem))
