import React, { useState } from 'react'
import axios from 'axios'
// MUI Components
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
// stripe
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

// Util imports
import { makeStyles } from '@material-ui/core/styles'

// Custom Components
import CardInput from './CardInput'
import { HEADER_JSON } from '../../../common/constants/apiConstants'
import { toastr } from 'react-redux-toastr'

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '1vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
})

const HomePage = ({ organization, buttonClick, userName }) => {
  const classes = useStyles()
  // State
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmitAnnualPayment = async (event) => {
    if (!stripe || !elements) {
      // Form hasn't loaded
      return
    }

    setLoading(true) // set loading to true before making API call

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    })

    if (result.error) {
      console.log(result.error.message)
      setLoading(false)
    } else {
      const res = await axios.post('/api/stripe/annual', {
        payment_method: result.paymentMethod.id,
        email: email,
        clientName: userName,
        subscriptionType: 'Annual',
        amount: '2000',
      })
      const { client_secret, status, subscription_id } = res.data

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!')
            console.log(result.error)
            toastr.success('Error Occurred with Payment', result.error)
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setLoading(false)
          }
        })
      } else {
        // console.log('You got the money ANNUAL!');
        let currentOrganization = Object.assign({}, organization)

        currentOrganization.status = 'client'
        currentOrganization.statusCode = '4'
        currentOrganization.subscriptionID = subscription_id
        currentOrganization.subscriptionType = 'annual'

        await axios.put(
          `/api/organization/${currentOrganization._id}`,
          currentOrganization,
          HEADER_JSON
        )
        toastr.success(
          'Successful Annual Subscription Payment!',
          'Your account has now been upgraded, you are being redirected to the Admin Portal.'
        )

        // Redirect page after 2 seconds to give client access to new features
        setTimeout(() => {
          window.location = 'https://spedxchange.com/admin'
        }, 2000)
      }
    }
  }

  const handleSubmitSemiAnnualPayment = async (event) => {
    if (!stripe || !elements) {
      // Form hasn't loaded
      return
    }

    setLoading(true)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    })

    if (result.error) {
      console.log(result.error.message)
      setLoading(false)
    } else {
      const res = await axios.post('/api/stripe/semiannual', {
        payment_method: result.paymentMethod.id,
        email: email,
        clientName: userName,
        subscriptionType: 'SemiAnnual',
        amount: '1199',
      })
      const { client_secret, status, subscription_id } = res.data

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!')
            toastr.success('Error Occurred with Payment', result.error)
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setLoading(false)
          }
        })
      } else {
        let currentOrganization = Object.assign({}, organization)

        currentOrganization.status = 'client'
        currentOrganization.statusCode = '4'
        currentOrganization.subscriptionID = subscription_id
        currentOrganization.subscriptionType = 'monthly'

        await axios.put(
          `/api/organization/${currentOrganization._id}`,
          currentOrganization,
          HEADER_JSON
        )
        toastr.success(
          'Successful Monthly Subscription Payment!',
          'Your account has now been upgraded, you are being redirected to the Admin Portal.'
        )

        // Redirect page after 2 seconds to give client access to new features
        setTimeout(() => {
          window.location = 'https://spedxchange.com/admin'
        }, 2000)
      }
    }
  }

  const handleSubmitQuarterlyPayment = async (event) => {
    if (!stripe || !elements) {
      // Form hasn't loaded
      return
    }

    setLoading(true)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    })

    if (result.error) {
      console.log(result.error.message)
      setLoading(false)
    } else {
      const res = await axios.post('/api/stripe/quarter', {
        payment_method: result.paymentMethod.id,
        email: email,
        clientName: userName,
        subscriptionType: 'Quarterly',
        amount: '650',
      })
      const { client_secret, status, subscription_id } = res.data

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!')
            console.log(result.error)
            toastr.success('Error Occurred with Payment', result.error)
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setLoading(false)
          }
        })
      } else {
        // console.log('You got the money ANNUAL!');
        let currentOrganization = Object.assign({}, organization)

        currentOrganization.status = 'client'
        currentOrganization.statusCode = '4'
        currentOrganization.subscriptionID = subscription_id
        currentOrganization.subscriptionType = 'annual'

        await axios.put(
            `/api/organization/${currentOrganization._id}`,
            currentOrganization,
            HEADER_JSON
        )
        toastr.success(
            'Successful Annual Subscription Payment!',
            'Your account has now been upgraded, you are being redirected to the Admin Portal.'
        )

        // Redirect page after 2 seconds to give client access to new features
        setTimeout(() => {
          window.location = 'https://spedxchange.com/admin'
        }, 2000)
      }
    }
  }

  let noteMessage = "Note: After clicking the subscribe button, there will be a few second delay. Please wait until you are redirected to the job board page";
  let confirmMessage1 = "By confirming your subscription, you allow SPEDxchange to charge your card for this recurring payment of $2000 a year in accordance with their terms. You can always manage your subscription under the Profile tab."
  let confirmMessage2 = "By confirming your subscription, you allow SPEDxchange to charge your card for this recurring payment of $1199 every 6 months in accordance with their terms. You can always manage your subscription under the Profile tab."
  let confirmMessage3 = "By confirming your subscription, you allow SPEDxchange to charge your card for this recurring payment of $650 every 3 months in accordance with their terms. You can always manage your subscription under the Profile tab."
  let stripeMessage = "All payments are handled securely with stripe"
  if (buttonClick === 1) {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <TextField
            label='Email'
            id='outlined-email-input'
            helperText={`Email you'll receive updates and receipts on`}
            margin='normal'
            variant='outlined'
            type='email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <CardInput />
          <div className={classes.div}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={loading}
              onClick={handleSubmitAnnualPayment}>
              Subscribe Annually
            </Button>
          </div>
          <i>{confirmMessage1}</i>
          <br></br>
          <i>{noteMessage}</i>
          <center>
            <img src='https://static.beheerpaneel.nl/user/roomraccoo/gfx/2560px-Stripe_Logo%2C_revised_2016.svg.png' width="150" height="75" alt={"stripe logo"}/>
          </center>
          <center>
            <i>{stripeMessage}</i>
          </center>
        </CardContent>
      </Card>
    )
  } else if (buttonClick === 2) {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <TextField
            label='Email'
            id='outlined-email-input'
            helperText={`Email you'll receive updates and receipts on`}
            margin='normal'
            variant='outlined'
            type='email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <CardInput />
          <div className={classes.div}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={loading}
              onClick={handleSubmitSemiAnnualPayment}>
              Subscribe Semi-Annually
            </Button>
          </div>
          <i>{confirmMessage2}</i>
          <br></br>
          <i>{noteMessage}</i>
          <center>
            <img src='https://static.beheerpaneel.nl/user/roomraccoo/gfx/2560px-Stripe_Logo%2C_revised_2016.svg.png' width="150" height="75" alt={"stripe logo"}/>
          </center>
          <center>
            <i>{stripeMessage}</i>
          </center>
        </CardContent>
      </Card>
    )
  } else {
    return (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <TextField
                label='Email'
                id='outlined-email-input'
                helperText={`Email you'll receive updates and receipts on`}
                margin='normal'
                variant='outlined'
                type='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
            />
            <CardInput />
            <div className={classes.div}>
              <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  disabled={loading}
                  onClick={handleSubmitQuarterlyPayment}>
                Subscribe Quarterly
              </Button>
            </div>
            <i>{confirmMessage3}</i>
            <br></br>
            <i>{noteMessage}</i>
            <center>
              <img src='https://static.beheerpaneel.nl/user/roomraccoo/gfx/2560px-Stripe_Logo%2C_revised_2016.svg.png' width="150" height="75" alt={"stripe logo"}/>
            </center>
            <center>
              <i>{stripeMessage}</i>
            </center>
          </CardContent>
        </Card>
    )
  }
}

export default HomePage
