export const LOGIN_USER = 'LOGIN_USER'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOAD_CLIENTS = 'LOAD_CLIENTS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const TOGGLE_FORGOT_PASSWORD = 'TOGGLE_FORGOT_PASSWORD'
export const FETCH_SCHOLARSHIP_APPLICATION = 'FETCH_SCHOLARSHIP_APPLICATION'
export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN'
export const SET_REGISTER_STEP = 'SET_REGISTER_STEP'
export const SET_EMPLOYER = 'SET_EMPLOYER'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
