import { createReducer } from '../../util/ReducerUtil';
import { ASYNC_ACTION_START, ASYNC_ACTION_FINISH, ASYNC_ACTION_ERROR, SET_SEARCH_LOCATION } from './asyncConstants';

const initialState = {
  loading: false,
  elementName: null,
  searchLocation: { city: 'Chicago', state: 'IL' }
};

const asyncActionStarted = (state, payload) => {
  return {
    ...state,
    loading: true,
    elementName: payload
  };
};

const asyncActionFinished = (state) => {
  return {
    ...state,
    loading: false,
    elementName: null
  };
};

const asyncActionError = (state) => {
  return {
    ...state,
    loading: false,
    elementName: null
  };
};

const setSearchLocation = (state, payload) => {
  return {
    ...state,
    searchLocation: payload
  };
};

export default createReducer(initialState, {
  [ASYNC_ACTION_START]: asyncActionStarted,
  [ASYNC_ACTION_FINISH]: asyncActionFinished,
  [ASYNC_ACTION_ERROR]: asyncActionError,
  [SET_SEARCH_LOCATION]: setSearchLocation
});
