import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { fetchEvents } from '../AdminActions'

export class AdminTrialMessage extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    this.props.history.push(`/admin/job/new`)
  }

  render() {
    const { org } = this.props
    if (org.status === 'trial') {
      return (
        <div className='admin-dashboard'>
          <div className='text-center'>
            <Button color='blue' onClick={this.handleClick}>
              Subscribe
            </Button>
            <strong>
              ⚠ Attention: Your organization is currently using a free trial
              version of SPEDxchange. Subscribe to post jobs and access
              functionality.
            </strong>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.loadingName,
  auth: state.auth,
  org: state.admin.org,
})

const mapDispatchToProps = {
  fetchEvents,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminTrialMessage)
)

//
// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom'
// import {AdminActivatePage} from "./jobs/AdminActivatePage";
// import {Button, Container, List} from "semantic-ui-react";
// import {fetchEvents} from "../AdminActions";
//
// export class AdminTrialMessage extends Component {
//     constructor(props) {
//         super(props);
//         this.handleClick = this.handleClick.bind(this);
//     }
//     handleClick(){
//         this.props.history.push(`/admin/job/new`)
//     }
//
//     render() {
//         const { org } = this.props;
//         if(org.status == "trial"){
//             return (
//                 <section id='PaymentBoxes'>
//                     <Container>
//                         <div className='call-out-box'>
//                             <div className='admin-dashboard'>
//                                 <div className='text-center'>
//                                     <Button
//                                         color='blue'
//                                         onClick={this.handleClick}>
//                                         Subscribe
//                                     </Button>
//                                     <strong>⚠ Attention: Your organization is currently using a free trial version of SPEDxchange. Subscribe to post jobs and access functionality.</strong>
//                                 </div>
//                             </div>
//                         </div>
//                     </Container>
//
//                 </section>
//
//             );
//         }
//         else{
//             return null;
//         }
//
//     }
// }
//
// const mapStateToProps = (state) => ({
//     loading: state.async.loading,
//     loadingName: state.async.loadingName,
//     auth: state.auth,
//     org: state.admin.org
// });
//
// const mapDispatchToProps = {
//     fetchEvents,
// };
//
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminTrialMessage));
