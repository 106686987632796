import axios from 'axios'
import * as qs from 'query-string'
import * as request from 'request-promise-native'
import { parse } from 'node-html-parser'
import {
  FETCH_BACKFILL_JOBS,
  FETCH_LOCAL_JOBS,
  FETCH_LOCAL_JOB, FETCH_SAVED_JOBS,
} from './jobsConstants'
import { SET_SEO } from '../../../common/ui/nav/navConstants'

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from '../../../common/actions/async/asyncActions'

const defaultParams = {
  q: 'Special Education',
  l: 'Chicago, IL, USA',
  r: 50,
  t: 'all',
  action: 'request_for_listings',
  page: 1,
}

const checkNode = (node) => {
  return node ? node.innerHTML.trim() : ''
}

const setExpireDate = (start) => {
  return new Date(+new Date(start) + 60 * 24 * 60 * 60 * 1000)
}

const setClientLogo = (name) => {
  if (name === 'PresenceLearning') {
    return 'https://spedxchange.com/assets/img/presencelearning.png'
  }
  return null
}

export const fetchLocalJob = (id) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-local-jobs'))
      const fetch = await axios.get(`/api/job/${id}`)
      const job = fetch.data
      // console.log('job: ', job)
      const seo = {
        title: `SPED Job: ${job.title}`,
        description: job.summary,
        url: `https://www.spedxchange.com/news/${job._id}`,
        image: `https://spedxchange.com/favicon-150x150.png`,
        type: 'Job Post',
        siteName: 'SPEDxchange',
        alt: 'SPEDxchange Jobs',
        robots: 'index,follow',
      }
      const ldjson = {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        title: job.title,
        description: `<p>${job.summary}</p>`,
        identifier: {
          '@type': 'spedxchange - GA4',
          name: 'SPEDxchange',
          value: '269010846',
        },
        datePosted: job.updated,
        validThrough: setExpireDate(job.updated),
        // employmentType: job.type,
        hiringOrganization: {
          '@type': 'Organization',
          name: job.organization.name,
          sameAs: job.organization.website,
        },
      }
      // job type
      if (job.jobType === "Full-time") {
        ldjson.employmentType = "FULL_TIME"
      } else if (job.jobType === "Part-Time") {
        ldjson.employmentType = "PART_TIME"
      } else {
        ldjson.employmentType = "TEMPORARY"
      }
      // location
      if (job.remote) {
        ldjson.jobLocationType = 'TELECOMMUTE'
        ldjson.applicantLocationRequirements = {
          '@type': 'Country',
          name: 'USA',
        }
      } else {
        ldjson.jobLocation = {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: job.location.street,
            addressLocality: job.location.city,
            addressRegion: job.location.state,
            postalCode: job.location.zip,
            addressCountry: 'US',
          },
        }
      }
      // applicationLocationRequirements
      ldjson.applicantLocationRequirements = {
          "@type": "Country",
          "name": "USA"
      }
      // salary
      if (job.salaryAmount) {
        ldjson.baseSalary = {
          '@type': 'MonetaryAmount',
          currency: 'USD',
          value: {
            '@type': 'QuantitativeValue',
            value: parseInt(job.salaryAmount),
            unitText: job.salaryPeriod,
          },
        }
      }
      // logo
      const logo = setClientLogo()
      if (logo !== null) {
        ldjson.hiringOrganization.logo = logo
      }
      seo.seoScript = ldjson
      dispatch({ type: SET_SEO, payload: seo })
      dispatch({ type: FETCH_LOCAL_JOB, payload: job })
      dispatch(asyncActionFinish())
    } catch (error) {
      window.location.href = "/*"
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchLocalJobs = (params) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-jobs'))
      const qry = params ? params : defaultParams
      const q = qry.q
      const l = qry.l.replace(', USA', '')
      const r = qry.r ? qry.r : 100
      const jobList = await axios.get(`api/job/jobsByLocation/${q}/${l}/${r}`)
      dispatch({ type: FETCH_LOCAL_JOBS, payload: jobList.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchBackfillJobs = (params) => {
  const requestParams = params ? params : defaultParams
  requestParams.r = requestParams.r ? requestParams.r : 50
  requestParams.action = 'request_for_listings'
  requestParams.page = requestParams.page ? requestParams.page : 1
  const queryString = qs.stringify(requestParams)
  const requestUrl = `https://hiteacherhunters.com/ajax/?${queryString}`
  requestParams.q = requestParams.q ? requestParams.q : 'SPED'
  requestParams.l = requestParams.l ? requestParams.l : 'Chicago, IL'
  const localUrl = `api/job/jobsByLocation/${
      requestParams.q
  }/${requestParams.l.replace(', USA', '')}/${requestParams.r}`
  // console.log('requestUrl: ', requestUrl);

  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const jobPage = await request.get(requestUrl)

      // Process Page
      const root = parse(jobPage)
      const jobList = root.querySelectorAll('article')
      let jobs = []
      let job
      for (job of jobList) {
        let data = {}
        data.link = job.querySelector('a').attributes.href
        data.title = job.querySelector('a').structuredText.trim()
        data.company = job
            .querySelector('.listing-item__info--item-company')
            .structuredText.trim()
        data.location = checkNode(
            job.querySelector('.listing-item__info--item-location')
        )
        data.date = job
            .querySelector('.listing-item__date')
            .structuredText.trim()
        jobs.push(data)
      }
      const localJobList = await axios.get(encodeURI(localUrl))

      dispatch({
        type: FETCH_BACKFILL_JOBS,
        payload: { jobs: jobs, localJobs: localJobList.data },
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error.message)
      dispatch(asyncActionError())
    }
  }
}

export const saveApplicantInfo = (
    applicantName,
    applicantEmail,
    jobID,
    uid = null
) => {
  const body = {
    applicant_name: applicantName,
    applicant_email: applicantEmail,
    uid: uid,
  }
  return async (dispatch) => {
    try {
      await axios.put(`/api/job/update/${jobID}`, body)
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

// export const saveJobView = (jobID) => {
//   return async (dispatch) => {
//     try {
//       await axios.put(`/api/jobs/view/${jobID}`)
//     } catch (error) {
//       console.log(error)
//       dispatch(asyncActionError())
//     }
//   }
// }

export const updateUserSaveJob =  (
    jobID,
    userID
) => {
  const body = {
    saveJobID: jobID,
    userID: userID
  }
  return async (dispatch) => {
    try {
      await axios.put(`/api/users/update`, body)
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const removeSavedJob = (
    jobID,
    userID
) => {
  const body = {
    saveJobID: jobID,
    userID: userID
  }
  return async (dispatch) => {
    try {
      await axios.put(`/api/users/remove`, body)
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchSavedJobs = (params) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-saved-jobs'))
      const savedJobsList = await axios.get(`/api/users/savedJobs/` + params)
      dispatch({ type: FETCH_SAVED_JOBS, payload: savedJobsList.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}