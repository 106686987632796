import React, { Component } from 'react'
import { Button, Container } from 'semantic-ui-react'

export class HomeIntroSection extends Component {
  render() {
    const { handleScroll } = this.props
    return (
      <section id='HomeIntroSection'>
        <Container>
          <h1 className='ovo'>SPEDxchange is the Community for Special Education Information, Resources, and Jobs</h1>
          <p>
            Our mission is to make a positive impact on special education services. We build relationships, discover and
            share solutions, and address the special education shortage head on.
          </p>
          <p className='mb-1'>
            <strong>Learn what we can do for:</strong>
          </p>
          <div className='mt-2'>
            <Button onClick={() => handleScroll('admin')}>Schools and SPED Administrators</Button>
          </div>
          <div onClick={() => handleScroll('sped')} className='mt-3'>
            <Button>SPED Professionals</Button>
          </div>
          <div onClick={() => handleScroll('sped')} className='mt-3'>
            <Button>Students Pursuing a SPED Degree</Button>
          </div>
        </Container>
      </section>
    )
  }
}

export default HomeIntroSection
