import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProfileSection from './ProfileSection/ProfileSection';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';
import { updateUserProfile } from '../../../common/ui/auth/AuthActions';

export class ProfileVisible extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.user;
    this.onVisibleChange = this.onVisibleChange.bind(this);
  }

  onVisibleChange(e) {
    const newState = {
      ...this.state,
      visible: e.target.checked
    };
    this.setState(newState);
    // console.log('newState: ', newState);
    this.props.updateUserProfile(newState);
  }

  render() {
    const { visible } = this.state;
    return (
      <ProfileSection className='profile-visible'>
        <ul className='section-ul'>
          <li>
            <ToggleSwitch onCheckChange={this.onVisibleChange} isChecked={visible} /> Let employers find me
          </li>
        </ul>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  updateUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileVisible);
