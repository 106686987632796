export const REPORT_ARTICLE_FETCH = 'REPORT_ARTICLE_FETCH'
export const REPORT_ARTICLE_FETCH_ALL = 'REPORT_ARTICLE_FETCH_ALL'
export const REPORT_ARTICLE_CLEAR = 'REPORT_ARTICLE_CLEAR'
export const REPORT_EVENTS_FETCH = 'REPORT_EVENTS_FETCH'
export const REPORT_JOB_CLEAR = 'REPORT_JOB_CLEAR'
export const REPORT_JOB_FETCH = 'REPORT_JOB_FETCH'
export const REPORT_JOB_SAVE = 'REPORT_JOB_SAVE'
export const REPORT_ORG_FETCH = 'REPORT_ORG_FETCH'
export const REPORT_ORG_FETCH_ALL = 'REPORT_ORG_FETCH_ALL'
export const REPORT_USER_FETCH_ALL = 'REPORT_USER_FETCH_ALL'
