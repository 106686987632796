import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

export class HomeMessageSection extends Component {
  render() {
    return (
      <section id='HomeMessageSection'>
        <Container>
          <h1>
            "We are a community of passionate and knowledgeable special educators striving to make a positive impact on
            the families we serve. Join us to achieve our shared goal."
          </h1>
          <p>
            <strong>John Consalvi</strong> <span className='pipe'>|</span> CCC-SLP <span className='pipe'>|</span>{' '}
            Founder &amp; CEO
          </p>
        </Container>
      </section>
    );
  }
}

export default HomeMessageSection;
