import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const jobTypes = [
  { key: 'all', text: 'All', value: 'All' },
  { key: 'full-time', text: 'Full-time', value: 'Full-time' },
  { key: 'part-time', text: 'Part-time', value: 'Part-time' },
  { key: 'substitute', text: 'Substitute', value: 'Substitute' },
  { key: 'intern', text: 'Intern', value: 'Intern' },
  { key: 'contact', text: 'Contract', value: 'Contract' },
  { key: 'seasonal', text: 'Seasonal', value: 'Seasonal' }
]

const jobDistance = [
  { key: '10-miles', text: '10 Miles', value: '10' },
  { key: '25-miles', text: '25 Miles', value: '25' },
  { key: '50-miles', text: '50 Miles', value: '50' },
  { key: '100-miles', text: '100 Miles', value: '100' }
]

// const jobLocation = [
//   { key: 'remote-school', text: 'In School or Remote', value: 'all' },
//   { key: 'in-school-only', text: 'In School Only', value: 'inschool' },
//   { key: 'remote-only', text: 'Remote Only', value: 'remote' }
// ]

const JobFilters = () => {
  return (
    <div className='job-search-filters'>
      <span>
        Job Type:&nbsp;&nbsp;
        <Dropdown inline options={jobTypes} defaultValue={jobTypes[1].value} />
      </span>
      <span>
        Distance:&nbsp;&nbsp;
        <Dropdown inline options={jobDistance} defaultValue={jobDistance[2].value} />
      </span>
      {/**
       * 
       * REMOVING Location Type until job board is filled
       * Remote jobs will show in all areas to cover note seeing jobs
       * 
       * <span>
       * Location:&nbsp;&nbsp;
       * <Dropdown inline options={jobLocation} defaultValue={jobLocation[0].value} />
       * </span>
       */
       }
    </div>
  )
}

export default JobFilters
