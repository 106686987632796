import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from '../../../../common/ui/modal/ModalActions';

import {Modal, Button, Dropdown} from 'semantic-ui-react';

export class AdminJobStatusModal extends Component {
    constructor(props) {
        super(props);
        this.onRouteChanged = this.onRouteChanged.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    async handleSelectChange(e, data) {
        let newStatus = data.value
        const currentJob = {...this.props.job};
        if (newStatus === 'Publish') {
            currentJob.status = "Published";
        } else if (newStatus === 'Draft') {
            currentJob.status = "Draft";
        } else if (newStatus === 'Pause') {
            currentJob.status = "Paused";
        } else if (newStatus === 'Close') {
            currentJob.status = "Closed";
        }
        await this.props.saveJob(currentJob)
        await this.props.fetchOrganization(this.props.admin.org._id);
        this.handleCloseModal()
    }

    onRouteChanged() {
        this.handleCloseModal();
    }

    handleCloseModal() {
        this.props.closeModal();
    }

    render() {
        const { job } = this.props;

        return (
            <Modal size='mini' open={true} onClose={this.handleCloseModal}>
                <Modal.Header>
                    <span className='capitalize'>Change Job Status</span>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div><strong>Job Status Options</strong></div>
                        <ol>
                            <li><strong>Draft</strong>: Job description is in the works. <strong>Does not appear to candidates.</strong></li>
                            <li><strong>Publish</strong>: Job is live and <strong>appears</strong> to candidates</li>
                            <li><strong>Pause</strong>: Job is put on hold and <strong>no longer appears</strong> for candidates</li>
                            <li><strong>Close</strong>: Job is closed and <strong>no longer appears</strong> for candidates</li>
                        </ol>
                        <div>Please select the new status for the job: </div>
                        <div>
                            <strong>{job.title}</strong>
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleCloseModal}>Cancel</Button>
                    <Dropdown
                        placeholder='Change Job Status'
                        fluid
                        search
                        selection
                        onChange={this.handleSelectChange}
                        options={statusOptions}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const statusOptions = [
    {
        key: 'Draft',
        text: 'Draft',
        value: 'Draft',
    },
    {
        key: 'Publish',
        text: 'Publish',
        value: 'Publish',
    },
    {
        key: 'Pause',
        text: 'Pause',
        value: 'Pause',
    },
    {
        key: 'Close',
        text: 'Close',
        value: 'Close',
    },
]

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminJobStatusModal));
