import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button, Grid, List } from 'semantic-ui-react'
import EmbedYoutube from '../../common/ui/component/EmbedYoutube'
import ResourcesContactForm from './ResourcesContactForm'
import './Resources.scss'

const mapState = (state) => ({})

const actions = {}

export class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = { shouldScroll: true }
    this.spedRef = React.createRef()
    this.adminRef = React.createRef()
    this.studentRef = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.state.shouldScroll) {
      const hash = this.props.location.hash
      switch (hash) {
        case '#Administrator':
          this.handleScroll('admin')
          break

        case '#Professional':
          this.handleScroll('sped')
          break

        case '#Student':
          this.handleScroll('student')
          break

        default:
          break
      }
      this.setState({ shouldScroll: false })
    }
  }

  handleScroll(ref) {
    switch (ref) {
      case 'sped':
        window.scrollTo({
          top: this.spedRef.current.offsetTop + 40,
          behavior: 'smooth'
        })
        break
      case 'admin':
        window.scrollTo({
          top: this.adminRef.current.offsetTop + 40,
          behavior: 'smooth'
        })
        break
      case 'student':
        window.scrollTo({
          top: this.studentRef.current.offsetTop + 40,
          behavior: 'smooth'
        })
        break
      default:
        break
    }
  }

  render() {
    return (
      <>
        <h1 className='ovo'>Which best describes you?</h1>
        <section className='head flex-box center sm'>
          <div>
            <Button primary onClick={() => this.handleScroll('sped')}>
              SPED Professional
            </Button>
          </div>
          <div className='spacer'></div>
          <div>
            <Button primary onClick={() => this.handleScroll('student')}>
              Pursuing a SPED Degree
            </Button>
          </div>
          <div className='spacer'></div>
          <div>
            <Button primary onClick={() => this.handleScroll('admin')}>
              School and SPED Admins
            </Button>
          </div>
        </section>

        {/*<section className='flex-box sm mt-5'>*/}
        {/*  <div className='grow'>*/}
        {/*    <EmbedYoutube embedId='OXpi4btxlhg' />*/}
        {/*  </div>*/}
        {/*  <div className='spacer'></div>*/}
        {/*  <div className='video-description'>*/}
        {/*    <h5>Video: Get involved with SPEDxchange</h5>*/}
        {/*    <p>*/}
        {/*      <strong>Want more? </strong>*/}
        {/*      <br />*/}
        {/*      Our <a href='https://www.youtube.com/channel/UChimMb9m3ASj5X6wxMSTSAA'>YouTube channel</a> features videos*/}
        {/*      for students pursuing SPED degrees, SPED professionals, and educators.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section id='Professional' className='photo-section' ref={this.spedRef}>
          <Grid stackable reversed='mobile'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2 className='ovo'>SPED Professional and Educator Service</h2>
              <p>
                SPEDxchange has a wide variety of opportunities for special education professionals and educators
                looking to expand their knowledge and enhance their services.
              </p>
              <div className='call-out-box'>
                <h4>Here are a few categories of beneficial interest to SPEDucators:</h4>
                <List as='ul'>
                  <List.Item as='li'>
                    <Link to='/jobs'>Job opportunities</Link>
                  </List.Item>
                  <List.Item as='li'>Career coaching</List.Item>
                  <List.Item as='li'>Classroom games/material and social media giveaways</List.Item>
                  <List.Item as='li'>
                    Discussion boards on our <Link to='/questions'>community forums</Link>
                  </List.Item>
                  <List.Item as='li'>
                    Original articles and news from the SPEDxchange team as well as guest contributions
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='section-img sped-professionals'></div>
            </Grid.Column>
          </Grid>
        </section>

        <section className='photo-section' ref={this.studentRef}>
          <Grid stackable>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='section-img sped-student'></div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2 className='ovo'>Students Pursuing SPED Degrees</h2>
              <p>We're here to help you navigate your transition from student into your career in special education.</p>
              <div className='call-out-box'>
                <h4>We offer the following to students looking to gain some free professional guidance:</h4>
                <List as='ul'>
                  <List.Item as='li'>Career coaching</List.Item>
                  <List.Item as='li'>Interview training</List.Item>
                  <List.Item as='li'>
                    <Link to='/jobs'>Job opportunities</Link>
                  </List.Item>
                  <List.Item as='li'>
                    <Link to='/scholarships'>Scholarships</Link>
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
          </Grid>
        </section>

        <section id='Administrator' className='photo-section' ref={this.adminRef}>
          <Grid stackable reversed='mobile'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2 className='ovo'>School and SPED Administrators Service</h2>
              <p>
                We recognize the growing special education shortage nationwide and the school districts struggling to
                find qualified special education staff.
              </p>
              <div className='call-out-box'>
                <h4>SPEDxchange offers school districts:</h4>
                <List as='ul'>
                  <List.Item as='li'>
                    <strong>
                      <Link to='/jobs'>Job Board</Link>:{' '}
                    </strong>{' '}
                    A unique and effective tool to get the right candidates applying for open positions
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='section-img quote'>
                <div>
                  <h5 className='ovo'>
                    Traditional hiring methods and job board "posting and praying" aren't cutting it anymore. Let
                    SPEDxchange increase your success rate of finding the right candidate.
                  </h5>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </section>

        <section className='flex-box sm'>
          <div className='grow'>
            <EmbedYoutube embedId='5ofTAXfZpC8' />
          </div>
          <div className='spacer'></div>
          <div className='video-description'>
            <h5>Video: Directly Hiring SPED Staff</h5>
            <p>We're tackling the special education teacher shortage head-on.</p>
          </div>
        </section>

        <section className='photo-section'>
          <Grid stackable>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2 className='ovo'>Why SPEDxchange?</h2>
              <p>
                <strong>
                  SPEDxchange connects your special education staff vacancies with long-term quality hires.
                </strong>
              </p>
              <div>
                It would help if you placed your job vacancies in front of the candidates that matter most —
                specifically special education professionals as well as experienced and recent graduates. Hard-to-find
                educators are making their plan to find their first jobs a priority. Don't miss them!
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='call-out-box'>
                <h4 className='my-0'>Get social with SPEDxchange</h4>
                <div className='my-2'>
                  <Button circular color='facebook' icon='facebook' href='https://www.facebook.com/SPEDxchange' />
                  <Button circular color='instagram' icon='instagram' href='https://www.instagram.com/spedxchange/' />
                  <Button
                    circular
                    color='linkedin'
                    icon='linkedin'
                    href='https://www.linkedin.com/company/spedxchange'
                  />
                </div>
                <h5 className='my-0'>Connect with the SPEDxchange community and reap the benefits:</h5>
                <List as='ul'>
                  <List.Item as='li'>Special education news and tips</List.Item>
                  <List.Item as='li'>Classroom materials and SPED product giveaways</List.Item>
                  <List.Item as='li'>Interact with other SPED professionals</List.Item>
                  <List.Item as='li'>
                    See the latest SPED <Link to='/jobs'>job opportunities</Link>
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
          </Grid>
        </section>

        <section>
          <ResourcesContactForm />
        </section>
      </>
    )
  }
}

export default connect(mapState, actions)(withRouter(Resources))
