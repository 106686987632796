import React, { useReducer, useEffect } from 'react'
import { Button, Input } from 'semantic-ui-react'
import EditableSection from '../../../../../common/ui/component/EditableSection'

const initialState = {
  loading: true,
  isEditMode: false,
  errors: {},
  _id: 'new',
  displayName: '',
  email: '',
  title: '',
  phone: ''
}

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'currentAdmin':
      const payload = action.payload
      const updateAdmin = { ...state.admin, payload }
      return { ...state, currentAdmin: updateAdmin }
    default:
      if (typeof state[action.type] === 'undefined') throw new Error(`Missing Type: ${action.type}`)
      return { ...state, [action.type]: action.payload }
  }
}

const ReportOrganizationAdministrator = ({ admin, handleUpdate }) => {
  const [state, dispatch] = useReducer(stateReducer, { ...initialState, ...admin })

  useEffect(() => {
    dispatch({ type: 'loading', payload: false })
  }, [])

  const handleEdit = () => {
    dispatch({ type: 'isEditMode', payload: !state.isEditMode })
  }

  const handleChange = (e, { name, value }) => {
    if (name === 'phone') {
      value = handlePhone(value, state.phone)
    }
    const errors = Object.assign({}, state.errors)
    delete errors[name]
    dispatch({ type: 'errors', payload: errors })
    dispatch({ type: name, payload: value })
  }

  const handlePhone = () => {
    // console.log(phone)
  }

  const handleCancel = () => {
    dispatch({ type: 'isEditMode', payload: false })
  }

  const handleSave = () => {
    dispatch({ type: 'isEditMode', payload: false })
    alert('Save feature coming soon!')
  }

  const { _id, displayName, email, title, phone, errors, loading, isEditMode } = state
  if (loading) return <></>
  return (
    <>
      <EditableSection handleEdit={handleEdit} isEditMode={isEditMode} hideEditMode={true}>
        {isEditMode ? (
          <>
            <div className='admin-content'>
              <form className='admin-form'>
                <input type='hidden' name='_id' value={_id} />
                <Input
                  error={errors.displayName}
                  size='mini'
                  label='Name'
                  name='displayName'
                  value={displayName}
                  onChange={handleChange}
                />
                <Input
                  error={errors.email}
                  size='mini'
                  label='Email'
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
                <Input
                  error={errors.title}
                  size='mini'
                  label='Title'
                  name='title'
                  value={title}
                  onChange={handleChange}
                />
                <Input
                  error={errors.phone}
                  size='mini'
                  label='Phone'
                  name='phone'
                  value={phone}
                  onChange={handleChange}
                />
              </form>
              <div className='mt-1'>
                <hr />
                <Button size='mini' positive onClick={handleSave}>
                  Save
                </Button>
                <Button size='mini' onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div key={_id} className='mb-3'>
              <div>
                <strong>{displayName}</strong>
              </div>
              <div>
                <em>{title || 'No Title'}</em>
              </div>
              <div>{email}</div>
              <div>
                <strong>Phone: </strong>
                {phone || 'N/A'}
              </div>
            </div>
          </>
        )}
      </EditableSection>
    </>
  )
}

export default ReportOrganizationAdministrator
