import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button, Container, Grid, List } from 'semantic-ui-react'

export class HomeSpedSection extends Component {
  render() {
    const { history, spedRef } = this.props
    return (
      <section id='HomeSpedSection' ref={spedRef}>
        <Container>
          <Grid stackable reversed='mobile'>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h1 className='ovo'>We share your dedication to speducation.</h1>
              <p>
                There is something extraordinary about a special education teacher, and sometimes, having a network to
                lean on makes all the difference. SPEDxchange is a community you can rely on to find job opportunities,
                seek discussion and advice from other SPED professionals, and learn about the latest news and advances
                in the industry.
              </p>
              <div className='call-out-box'>
                <h4>Current and aspiring SPED professionals can use SPEDxchange to:</h4>
                <List as='ul' className='mb-4'>
                  <List.Item as='li'>
                    Simplify your <Link to='/jobs'>job search</Link>
                  </List.Item>
                  <List.Item as='li'>
                    Find <Link to='/scholarships'>scholarship opportunities</Link>
                  </List.Item>
                  <List.Item as='li'>
                    Explore <Link to='/questions'>science-based discussion</Link>
                  </List.Item>
                </List>
                <Button primary onClick={() => history.push('/resources#Professional')}>
                  Start Here
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className='section-img'></div>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    )
  }
}

export default withRouter(HomeSpedSection)
