import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Breadcrumb, Button } from 'semantic-ui-react'
import { fetchOrg, fetchEvents } from '../../ReportActions'
import {
  fetchOrganization,
  toTitleCase,
} from '../../../../common/ui/auth/AuthActions'
import ReportOrganizationAdministrator from './administrator/ReportOrganizationAdministrator'

export class ReportOrganizationsDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = { reload: true }
    this.onVisitDashboard = this.onVisitDashboard.bind(this)
  }

  async componentDidMount() {
    await this.props.fetchOrg(this.props.match.params.orgId)
    this.setState({ reload: true })
  }

  componentDidUpdate(prev) {
    if (this.props.match.params.orgId !== prev.match.params.orgId) {
      this.props.fetchOrg(this.props.match.params.orgId)
      this.setState({ reload: true })
    }
    if (
      this.state.reload &&
      !this.props.loading &&
      this.state.loadingName !== 'fetch-events' &&
      this.state.loadingName !== 'fetch-all-orgs' &&
      !!this.props.org &&
      !!this.props.org.name
    ) {
      this.props.fetchEvents(this.props.org.name)
      this.setState({ reload: false })
    }
  }

  onVisitDashboard() {
    this.props.fetchOrganization(this.props.match.params.orgId)
    setTimeout(() => {
      this.props.history.push('/admin')
    }, 300)
  }

  render() {
    const { org, events } = this.props
    if (!org || !events) return <div>Loading...</div>
    return (
      <div>
        <div className='flex-box between'>
          <div className='flex-box align-center grow'>
            <Breadcrumb size='large'>
              <Breadcrumb.Section
                link
                onClick={() => this.props.history.push('/spedxchange-reports')}>
                Management
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section
                link
                onClick={() =>
                  this.props.history.push('/spedxchange-reports/organizations')
                }>
                Organizations
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section>Details</Breadcrumb.Section>
            </Breadcrumb>
          </div>
          <Button primary onClick={this.onVisitDashboard}>
            Visit Client Dashboard
          </Button>
        </div>
        <h3 className='mt-3 mb-0'>{org.name}</h3>
        <p>
          <strong>Status: </strong>
          {toTitleCase(org.status)}
          <br />
          <strong>Description: </strong>
          {org.summary || 'N/A'}
        </p>

        <div className='flex-box sm mb-4'>
          <div className='flex-1'>
            <hr className='my-1' />
            <h5 className='my-0'>Locations</h5>
            <hr className='my-1' />
            <div>
              <div>
                <strong>Main Address:</strong>
              </div>
              <div>{org.location.street}</div>
              <div>
                {org.location.city}, {org.location.state} {org.location.zip}
              </div>
              <div>
                <strong>Phone: </strong>
                {org.location.phone || 'N/A'}
              </div>
              {org.locations
                .filter((l) => l._id !== org.location._id)
                .map((loc) => (
                  <div key={loc._id}>
                    <div>{loc.name}</div>
                    <div>{loc.street}</div>
                    <div>
                      {loc.city}, {loc.state} {loc.zip}
                    </div>
                    <div>
                      <strong>Phone: </strong>
                      {org.location.phone || 'N/A'}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='spacer' />
          <div className='flex-1'>
            <hr className='my-1' />
            <h5 className='my-0'>Administrators</h5>
            <hr className='my-1' />
            {org.users &&
              org.users.map((usr) => (
                <ReportOrganizationAdministrator key={usr._id} admin={usr} />
              ))}
          </div>
        </div>

        {/* JOBS */}
        <div className='mb-5'>
          <hr className='my-1' />
          <h5 className='my-0'>Published Jobs</h5>
          <hr className='my-1' />
          {org.jobs.length > 0 ? (
            <>
              {org.jobs
                .filter((j) => j.status === 'Published')
                .map((job) => (
                  <div key={job._id}>
                    <div>{job.title}</div>
                  </div>
                ))}
            </>
          ) : (
            <div>
              <em>No Published Jobs</em>
            </div>
          )}
        </div>

        {/* ANALYTICS */}
        {/* <div className='admin-dashboard mb-4'>
          <hr className='my-1' />
          <h5 className='mt-0 mb-2'>Analytics</h5>
          <Grid stackable columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <section>
                  <h1>{events.totals['Job Click'].month}</h1>
                  <label>Job Views</label>
                  <div>
                    <strong>{events.totals['Job Click'].day}</strong>
                    <span>Today</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Click'].week}</strong>
                    <span>Past 7 Days</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Click'].month}</strong>
                    <span>Past 30 Days</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Click'].totalTime}</strong>
                    <span>Lifetime</span>
                  </div>
                </section>
              </Grid.Column>
              <Grid.Column>
                <section>
                  <h1>{events.totals['Job Apply Click'].month}</h1>
                  <label>Job Clicks</label>
                  <div>
                    <strong>{events.totals['Job Apply Click'].day}</strong>
                    <span>Today</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Apply Click'].week}</strong>
                    <span>Past 7 Days</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Apply Click'].month}</strong>
                    <span>Past 30 Days</span>
                  </div>
                  <div>
                    <strong>{events.totals['Job Apply Click'].totalTime}</strong>
                    <span>Lifetime</span>
                  </div>
                </section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  org: state.report.org,
  events: state.report.events,
})

const mapDispatchToProps = {
  fetchOrg,
  fetchEvents,
  fetchOrganization,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportOrganizationsDetailPage))
