import React from "react";
import Container from "@material-ui/core/Container";

const PartnerComponent = ({ partners }) => {
    const rows = [];

    let shuffledPartners = partners
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    // Split the partners into rows of three each
    for (let i = 0; i < shuffledPartners.length; i += 3) {
        rows.push(shuffledPartners.slice(i, i + 3));
    }

    return (
        <Container style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            {rows.map((row, index) => (
                <div
                    className="row"
                    key={index}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "10px"
                    }}
                >
                    {row.map((image, index) => (
                        <div
                            className="call-out-box"
                            key={index}
                            style={{
                                flex: "1 0 30%",
                                position: "relative",
                                paddingBottom: "10%"
                            }}
                        >
                            <a href={image.link} style={{ display: "block", height: "100%" }}>
                                <img
                                    className="call-out-box__image"
                                    src={image.src}
                                    alt={image.link}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0
                                    }}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            ))}
        </Container>
    );
};

export default PartnerComponent;
