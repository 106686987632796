export const FETCH_JOBS = 'FETCH_JOBS'
export const FETCH_JOB = 'FETCH_JOB'
export const FETCH_ARTICLE = 'FETCH_ARTICLE'
export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const UPDATE_JOBS = 'UPDATE_JOBS'
export const UPDATE_JOB = 'UPDATE_JOB'
export const UPDATE_JOB_PROP = 'UPDATE_JOB_PROP'
export const FETCH_ORG = 'FETCH_ORG'
export const FETCH_ANALYTIC = 'FETCH_ANALYTIC'
export const CLEAR_JOB = 'CLEAR_JOB'
export const CLEAR_ORG = 'CLEAR_ORG'
export const DELETE_JOB = 'DELETE_JOB'
