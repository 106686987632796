import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Button } from 'semantic-ui-react'

export class ReportMembersPage extends Component {
  handleNewMember() {
    alert('Coming Soon!')
  }

  render() {
    return (
      <>
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section link onClick={() => this.props.history.push('/spedxchange-reports')}>
                  Management
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section>Members</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <Button content='New Member' primary onClick={this.handleNewMember} />
          </div>
        </div>
        <div>
          <h3>View/Edit News Articles Coming Soon!</h3>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReportMembersPage)
