/* eslint no-useless-escape: 0 */
import React, { Component } from 'react'
import axios from 'axios'
import AdminActivatePage from '../../../admin/component/jobs/AdminActivatePage'
import { Breadcrumb, Button, Form, Icon } from 'semantic-ui-react'
import {
  clearArticle,
  createArticle,
  saveArticle,
  updateArticle,
} from '../../ReportActions'
import { fetchArticle } from '../../ReportActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReportNewsPreview from './ReportNewsPreview'
import { createUid } from '../../../../common/util/createUid'
import { createSlug } from '../../../../common/util/createSlug'

export class ReportNewsExternalForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...emptyArticle,
      preview: false,
      loaded: false,
    }

    this.isValidURL = this.isValidURL.bind(this)
    this.handleTestLink = this.handleTestLink.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUploadChange = this.handleUploadChange.bind(this)
    this.handleUploadChange2 = this.handleUploadChange2.bind(this)
    this.handleUploadClick = this.handleUploadClick.bind(this)
    this.handleUploadClick2 = this.handleUploadClick2.bind(this)
  }

  async componentDidMount() {
    let article = { ...emptyArticle }
    const id = this.props.match.params.id
    if (id !== 'new') {
      const fetchArticle = await axios.get(`/api/news/${id}`)
      article = {
        ...article,
        ...fetchArticle.data,
        mainTag: fetchArticle.data.tags[0] || '',
        tags: fetchArticle.data.tags,
      }
    }
    this.setState({
      ...this.state,
      ...article,
      loaded: true,
    })
  }

  handleTestLink() {
    const extLink = this.state.externalLink
    const validLink = this.isValidURL(extLink)
    if (extLink.length !== 0 && validLink) {
      window.open(extLink, '_blank')
    } else {
      alert('Insert a valid link.')
    }
  }

  isValidURL(string) {
    // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
    // Validation function for a valid link inserted
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    )
    return res !== null
  }

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  }

  handleRemoteChange(e) {
    this.setState({ ...this.state, remote: e.target.checked })
  }

  handleSelectChange(e, data) {
    this.setState({ ...this.state, [data.name]: data.value })
  }

  handleLocationChange(e, data) {
    const findLoc = this.props.admin.locationOptions.find(
      (opt) => opt.key === data.value
    )
    this.setState({
      ...this.state,
      [data.name]: data.value,
      loc: findLoc.loc,
      name: findLoc.name,
      city: findLoc.city,
      state: findLoc.state,
    })
  }

  handleEditorChange(content, editor) {
    this.setState({ ...this.state, content: content })
  }

  onSalaryRangeSelect(e) {
    this.setState({ ...this.state, salaryPeriod: e.target.textContent })
  }

  handleSubmit(event, publish) {
    const article = {
      uid: this.state.uid,
      file: this.state.file,
      file2: this.state.file2,
      fileData: this.state.fileData,
      fileData2: this.state.fileData2,
      coverImage: this.state.coverImage,
      coverImage2: this.state.coverImage2,
      _id: this.state._id,
      slug: createSlug(this.state.title),
      author: null,
      title: this.state.title,
      summary: this.state.summary,
      externalLink: this.state.externalLink,
      content: this.state.content,
      rawText: this.state.rawText,
      category: this.state.category,
      mainTag: this.state.mainTag,
      tags: this.state.tags,
      status: publish === 'publish' ? 'Published' : 'Draft',
      photoURL: '',
      videoURL: '',
    }

    // this.props.match.params will either contain...
    // 1. id: new
    // 2. article ID

    setTimeout(() => {
      if (this.props.match.params.id !== 'new') {
        this.props.saveArticle(article)
      } else {
        this.props.createArticle(article, this.props.history)
      }
    }, 300)
    this.setState({ ...this.state, status: article.status, loaded: true })
    event.preventDefault()
  }

  handleUploadClick() {
    this.inputElement.click()
  }

  handleUploadClick2() {
    this.inputElement2.click()
  }

  handleUploadChange(e) {
    const file = e.target.files[0]
    const isImg = !/^image\/(png|jpe?g|gif)$/.test(file.type) ? false : true
    const isSize = file.size / 1024 < 1500 ? true : false
    this.fileErrors = []
    if (!isImg) {
      this.fileErrors.push('The file you selected is not an image file.')
    }
    if (!isSize) {
      this.fileErrors.push(
        'Please select an image file that is less than 1.5MB.'
      )
    }
    if (this.fileErrors.length < 1) {
      const image = new Image()
      image.src = window.URL.createObjectURL(file)
      image.onload = () => {
        let setWidth, setHeight
        const width = image.naturalWidth
        const height = image.naturalHeight
        window.URL.revokeObjectURL(image.src)
        if (width >= height) {
          setHeight = '72px'
          setWidth = 'auto'
        } else {
          setHeight = 'auto'
          setWidth = '72px'
        }
        this.setState({
          ...this.state,
          setHeight: setHeight,
          setWidth: setWidth,
          file: URL.createObjectURL(file),
          fileData: file,
        })
        let fileName
        if (this.state.slug === '') {
          let tempSlug = createSlug(this.state.title)
          fileName = tempSlug + '.jpg'
        } else {
          fileName = this.state.slug + '.jpg'
        }
        const myRenamedFile = new File([this.state.fileData], fileName, {
          type: 'image/jpeg',
        })
        this.setState({ ...this.state, fileData: myRenamedFile })
      }
    }
  }

  handleUploadChange2(e) {
    const file2 = e.target.files[0]
    const isImg = !/^image\/(png|jpe?g|gif)$/.test(file2.type) ? false : true
    const isSize = file2.size / 1024 < 1500 ? true : false
    this.fileErrors = []
    if (!isImg) {
      this.fileErrors.push('The file you selected is not an image file.')
      alert('The file you selected is not an image file.')
    }
    if (!isSize) {
      this.fileErrors.push(
        'Please select an image file that is less than 1.5MB.'
      )
      alert('Please select an image file that is less than 1.5MB.')
    }
    if (this.fileErrors.length < 1) {
      const image = new Image()
      image.src = window.URL.createObjectURL(file2)
      image.onload = () => {
        let setWidth, setHeight
        const width = image.naturalWidth
        const height = image.naturalHeight
        window.URL.revokeObjectURL(image.src)
        if (width >= height) {
          setHeight = '72px'
          setWidth = 'auto'
        } else {
          setHeight = 'auto'
          setWidth = '72px'
        }
        this.setState({
          ...this.state,
          setHeight: setHeight,
          setWidth: setWidth,
          file2: URL.createObjectURL(file2),
          fileData2: file2,
        })
        let fileName2
        if (this.state.slug === '') {
          let tempSlug = createSlug(this.state.title)
          fileName2 = tempSlug + '.jpg'
        } else {
          fileName2 = this.state.slug + '.jpg'
        }
        const myRenamedFile2 = new File([this.state.fileData2], fileName2, {
          type: 'image/jpeg',
        })
        this.setState({ ...this.state, fileData2: myRenamedFile2 })
      }
    }
  }

  render() {
    const { title, summary, category, status, tags, mainTag, file, file2 } =
      this.state

    const { externalLink } = this.state

    let allTags = []

    let counter = 0
    let tag

    if (tags) {
      for (tag of tags) {
        if (!allTags.includes(tag.text + ',')) {
          if (counter < tags.length - 1) {
            allTags[counter] = tag.text + ','
            counter++
          } else {
            allTags[counter] = tag.text
            counter++
          }
        }
      }
    }

    // Handling loading up the default image from AWS below.
    // f1 --> the article cover image
    // f2 --> the article inner image
    let f1, f2
    f1 = file
    f2 = file2

    const defaultCoverImage = `https://spedxchange.s3.us-east-2.amazonaws.com/news/${this.state.uid}/${this.state.slug}-cover.jpg`
    const defaultArticleImage = `https://spedxchange.s3.us-east-2.amazonaws.com/news/${this.state.uid}/${this.state.slug}.jpg`

    if (f1 === null) {
      f1 = defaultCoverImage
    }

    if (f2 === null) {
      f2 = defaultArticleImage
    }

    if (this.props.org?.status === 'trial') return <AdminActivatePage />
    return (
      <>
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section
                  link
                  onClick={() =>
                    this.props.history.push('/spedxchange-reports')
                  }>
                  Management
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section
                  link
                  onClick={() =>
                    this.props.history.push('/spedxchange-reports/news')
                  }>
                  Articles
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section>Edit External Article</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div className='btn-height'></div>
          </div>
        </div>
        {this.state.loaded ? (
          <Form>
            <div className='job-edit flex-box sm'>
              {this.state.preview ? (
                <div className='grow pr-4'>
                  <ReportNewsPreview data={this.state} />
                </div>
              ) : (
                <div className='grow pr-4'>
                  <div className='mb-4'>
                    <label>Article Title</label>
                    <Form.Input
                      name='title'
                      defaultValue={title}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className='mt-4'>
                    <label>Summary</label>
                    <Form.Input
                      name='summary'
                      defaultValue={summary}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className='mt-4'>
                    <label>
                      External Link{' '}
                      <Icon circular size='small' name='linkify' />{' '}
                    </label>
                    <Form.Input
                      name='externalLink'
                      defaultValue={externalLink}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              )}
              <div className='spacer'></div>
              <div className='publish-panel'>
                <label>&nbsp;</label>
                <div className='flex-box between mb-4'>
                  <div className='half'>
                    <Button
                      fluid
                      color='grey'
                      type='button'
                      content={'Test Link'}
                      onClick={this.handleTestLink}
                    />
                  </div>
                  <div className='half'>
                    <Button
                      fluid
                      color='blue'
                      content='Save'
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
                <div className='mb-4'>
                  {status === 'Published' ? (
                    <Button
                      fluid
                      color='grey'
                      content='Unpublish'
                      onClick={(e) => this.handleSubmit(e, 'unpublish')}
                    />
                  ) : (
                    <Button
                      fluid
                      color='green'
                      content='Publish'
                      onClick={(e) => this.handleSubmit(e, 'publish')}
                    />
                  )}
                </div>

                <div className='mb-4'>
                  <label>Article Category</label>
                  <Form.Select
                    fluid
                    name='category'
                    value={category.text}
                    onChange={this.handleSelectChange}
                    options={articleTypeOptions}
                  />
                </div>

                <div className='mb-4'>
                  <label>Tags: {allTags}</label>
                  {'\n'}
                  <Form.Input
                    name='mainTag'
                    value={mainTag.text}
                    onChange={this.handleChange}
                  />
                </div>

                <label>
                  <b>
                    <Icon circular size='small' name='image' /> Cover Image
                    Upload
                  </b>
                </label>
                <input
                  style={{ display: 'none' }}
                  type='file'
                  ref={(input) => (this.inputElement = input)}
                  onChange={this.handleUploadChange}
                />
                <div
                  style={{ borderStyle: 'dotted' }}
                  className='rectangle_wrap'
                  onClick={this.handleUploadClick}>
                  <div className='avatar'>
                    <img alt='' src={f1 === null ? f1 : f1} />
                  </div>
                  <Button fluid color='grey' content='Upload Image' />
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <></>
        )}
      </>
    )
  }
}

const emptyArticle = {
  _id: 'new',
  slug: '',
  author: null,
  category: 'Education',
  file: null,
  file2: null,
  fileData: null,
  fileData2: null,
  coverImage: '',
  coverImage2: '',
  uid: createUid(),
  title: '',
  summary: '',
  externalLink: '',
  content: '',
  rawText: '',
  tags: '',
  mainTag: '',
  status: 'Draft',
  photoURL: '',
  videoURL: '',
}
const articleTypeOptions = [
  { key: 'Administration', text: 'Administration', value: 'Administration' },
  { key: 'Education', text: 'Education', value: 'Education' },
  { key: 'Career', text: 'Career', value: 'Career' },
  { key: 'Wellness', text: 'Wellness', value: 'Wellness' },
  { key: 'Job Search', text: 'Job Search', value: 'Job Search' },
  { key: 'SPED Superstar', text: 'SPED Superstar', value: 'SPED Superstar' },
]

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.loadingName,
  auth: state.auth,
  org: state.admin.org,
  job: state.admin.job,
  article: state.report.article,
})

const mapDispatchToProps = {
  createArticle,
  updateArticle,
  saveArticle,
  clearArticle,
  fetchArticle,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportNewsExternalForm))
