import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { openModal } from '../../../common/ui/modal/ModalActions';

import ProfileSection from './ProfileSection/ProfileSection';

export class ProfileActivities extends Component {
  render() {
    const { activities } = this.props.user;
    return (
      <ProfileSection title='Activities'>
        <ul className='section-ul'>
          <li>
            <div
              className='add-btn'
              onClick={() =>
                this.props.openModal('ModalProfileActivities', {
                  index: activities ? activities.length : 0,
                  isAdd: true
                })
              }>
              <Icon circular name='add' /> Add Activity
            </div>
          </li>
          {Array.isArray(activities) &&
            activities.map((item, index) => (
              <li key={`activity-${index}`} className='details'>
                <span
                  className='remove-btn'
                  title='delete'
                  onClick={() => this.props.openModal('ModalProfileActivities', { index: index, isRemove: true })}>
                  <Icon name='trash alternate outline' />
                </span>
                <span
                  className='edit-btn'
                  onClick={() => this.props.openModal('ModalProfileActivities', { index: index, isAdd: false })}>
                  <Icon name='pencil' />
                </span>
                <p>{item.description}</p>
              </li>
            ))}
        </ul>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActivities);
