import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Breadcrumb, Button, Dropdown, Icon, Table} from 'semantic-ui-react'
import moment from 'moment/moment'
import { openModal } from '../../../../common/ui/modal/ModalActions'
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash'
import { fetchAllArticles } from '../../ReportActions'

export class ReportNewsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: ['Published', 'Draft', 'Archived'],
      sort: ['Date'],
    }

    this.handleNewSPEDArticle = this.handleNewSPEDArticle.bind(this)
    this.handleNewExternalArticle = this.handleNewExternalArticle.bind(this)
    this.handleNewBloggerArticle = this.handleNewBloggerArticle.bind(this)
    this.handleEditArticle = this.handleEditArticle.bind(this)
    this.handleArticleArchiveAction = this.handleArticleArchiveAction.bind(this)
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    if (!Array.isArray(this.props.articles)) {
      this.props.fetchAllArticles()
    }
  }

  handleArticleArchiveAction(article, action) {
    this.props.openModal('ReportNewsModal', {
      ...this.props,
      article: article,
      action: action,
    })
  }

  handleNewSPEDArticle(id) {
    // Handler for all SPED articles --> directs towards SPED article UI
    this.props.history.push('/spedxchange-reports/news/new')
  }

  handleNewExternalArticle(id) {
    // Handler for all External articles --> directs towards External article UI
    this.props.history.push('/spedxchange-reports/news/external/new')
  }

  handleNewBloggerArticle(id) {
    // Handler for all External articles --> directs towards Guest Blogger article UI
    this.props.history.push('/spedxchange-reports/news/blogger/new')
  }

  async handleEditArticle(article) {
    let externalLink = article.externalLink
    let guestBloggerName = article.guestBloggerName
    // logic:
    //  1. If external link, open the external article editor interface
    //  2. If guest blogger name, open the guest blogger editor interface
    //  2. If no external link, open SPED editor interface
    if (externalLink.length !== 0) {
      this.props.history.push(
          `/spedxchange-reports/news/external/${article._id}`
      )
    } else if (guestBloggerName !== 0) {
      this.props.history.push(
          `/spedxchange-reports/news/blogger/${article._id}`)
    }
    else {
      this.props.history.push(`/spedxchange-reports/news/${article._id}`)
    }
  }

  filterList() {
    if (this.state.filter) {
      const predicate = function () {
        const args = _.toArray(arguments)
        return (item) => {
          let equalsItemStatus = _.partial(_.isEqual, item.status)
          return args.some(equalsItemStatus)
        }
      }
      return _.filter(this.props.articles, predicate(...this.state.filter))
    } else {
      return this.props.articles
    }
  }


  onSortChange(e) {
    let sortStatus = e.target.textContent
    if (sortStatus == 'Alphabetical') {
      this.setState({ sort: "Alphabetical" });
    } else {
      this.setState({ sort: "Date" });
    }
  }

  onFilterChange(e) {
    let filterStatus = e.target.textContent
    if (filterStatus == "All") {
      this.setState({filter: ['Published', 'Draft', 'Archived']})
    } else if (filterStatus == "Published") {
      this.setState({filter: ['Published']})
    } else if (filterStatus == "Draft") {
      this.setState({filter: ['Draft']})
    }
    this.filterList()
  }

  handleNewArticle(id) {
    this.props.history.push('/spedxchange-reports/news/new')
  }

  render() {
    const { articles } = this.props
    if (!Array.isArray(articles)) return <div>Loading...</div>
    let sorted;
    if (this.state.sort == 'Alphabetical') {
      sorted = articles.sort((a, b) => {
        var nameA = a.title.toUpperCase()
        var nameB = b.title.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
    if (this.state.sort == "Date") {
      sorted = articles.sort((a, b) => {
        var nameA = a.updated
        var nameB = b.updated
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }
        return 0
      })
    }

    return (
        <>
          <div className='admin-nav'>
            <div className='flex-box between'>
              <div className='flex-box align-center grow'>
                <Breadcrumb size='large'>
                  <Breadcrumb.Section
                      link
                      onClick={() =>
                          this.props.history.push('/spedxchange-reports')
                      }>
                    Management
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right chevron' />
                  <Breadcrumb.Section>Articles</Breadcrumb.Section>
                </Breadcrumb>
              </div>
              <Button
                  content='New SPEDxchange Article'
                  primary
                  onClick={this.handleNewSPEDArticle}
              />
              <Button
                  content='New External Article'
                  primary
                  onClick={this.handleNewExternalArticle}
              />
              <Button
                  content='New Guest Blogger Article'
                  primary
                  onClick={this.handleNewBloggerArticle}
              />
            </div>
          </div>
          <Dropdown
              placeholder='Sort Articles'
              selection
              onChange={this.onSortChange}
              options={articleSortOptions}
          />
          <Dropdown
              placeholder='Filter Articles'
              selection
              onChange={this.onFilterChange}
              options={articleFilterOptions}
          />
          <div className='fixed-scroll-table'>
            <Table unstackable selectable compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Category</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Last Updated</Table.HeaderCell>
                  <Table.HeaderCell
                      width={2}
                      textAlign='right'></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(sorted) &&
                this.filterList(sorted).length > 0 &&
                this.filterList(sorted).map((article, index) => (
                    <Table.Row key={article._id}>
                      <Table.Cell width={10}>{article.title}</Table.Cell>
                      <Table.Cell>{article.status}</Table.Cell>
                      <Table.Cell>{article.category.text}</Table.Cell>
                      <Table.Cell>{moment(article.updated).from()}</Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Icon
                            link
                            className='ml-2'
                            color='green'
                            name='pencil square'
                            title='Edit Article'
                            onClick={() => this.handleEditArticle(article)}
                        />
                        <Icon
                            link
                            className='ml-2'
                            color='orange'
                            name='file archive outline'
                            title='Draft Article'
                            onClick={() =>
                                this.handleArticleArchiveAction(
                                    article,
                                    article.status,
                                    index
                                )
                            }
                        />
                        <Icon
                            link
                            className='ml-2'
                            color='orange'
                            name='trash alternate'
                            title='Draft Article'
                            onClick={() =>
                                this.handleArticleArchiveAction(
                                    article,
                                    'Delete',
                                    index
                                )
                            }
                        />
                      </Table.Cell>
                    </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
    )
  }
}

const articleSortOptions = [
  { key: 'Alphabetical', text: 'Alphabetical', value: 'Alphabetical' },
  { key: 'By Date', text: 'By Date', value: 'By Date' },
]

const articleFilterOptions = [
  { key: 'All', text: 'All', value: 'All' },
  { key: 'Published', text: 'Published', value: 'Published' },
  { key: 'Draft', text: 'Draft', value: 'Draft' },
]

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  users: state.report.users,
  articles: state.report.articles,
})

const mapDispatchToProps = {
  fetchAllArticles,
  openModal,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ReportNewsPage))