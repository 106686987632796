/* eslint no-useless-escape: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Icon, Input, Button, Breadcrumb } from 'semantic-ui-react'
import { openModal } from '../../../../common/ui/modal/ModalActions'
import {
  updateLocation,
  fetchOrganization,
  addLocation,
  deleteLocation,
} from '../../../../common/ui/auth/AuthActions'
import Loading from '../../../../common/ui/loading/Loading'
import AdminClientSelector from '../AdminClientSelector'

const initState = {
  errors: {},
  showForm: false,
  _id: '',
  name: '',
  contact: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
}

export class AdminLocationList extends Component {
  constructor(props) {
    super(props)
    this.state = initState
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePhone = this.handlePhone.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleDelete(location) {
    this.props.deleteLocation({
      _id: location._id,
      organization: this.props.org._id,
    })
  }

  handleEdit(location) {
    this.setState({
      errors: {},
      showForm: false,
      _id: location._id,
      name: location.name,
      contact: location.contact,
      street: location.street,
      city: location.city,
      state: location.state,
      zip: location.zip,
      phone: location.phone,
    })
  }

  // TODO: Make this a util
  handlePhone(value, previousValue) {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length
    if (!previousValue || value.length !== previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
  }

  handleChange(e, { name, value }) {
    if (name === 'phone') {
      value = this.handlePhone(value, this.state.phone)
    }
    const errors = Object.assign({}, this.state.errors)
    delete errors[name]
    this.setState({ ...this.state, [name]: value, errors: errors })
  }

  async handleSubmit(e) {
    const { errors, _id, name, street, city, state, zip, phone } = this.state
    const { org } = this.props
    if (!name) errors['name'] = true
    if (!street) errors['street'] = true
    if (!city) errors['city'] = true
    if (!state) errors['state'] = true
    if (!zip) errors['zip'] = true
    if (!phone || phone.length !== 14) errors['phone'] = true
    if (Object.keys(errors).length > 0) {
      this.setState({ ...this.state, errors: errors })
      return
    }
    const activeUser = this.props.user
    const defaultContact = Array.isArray(this.props.org?.users)
      ? this.props.org.users[0]._id
      : activeUser._id
    const contact =
      org._id === activeUser.organizationId ? activeUser._id : defaultContact
    if (_id && _id.length > 0) {
      // console.log('update location')
      await this.props.updateLocation({
        _id,
        name,
        street,
        city,
        state,
        zip,
        phone,
        org,
        contact,
      }, org._id)
    } else {
      await this.props.addLocation({
        name: name,
        street: street,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        contact: contact,
        organization: org._id,
      })
    }
    this.setState(initState)
    this.props.fetchOrganization(org._id)
  }

  render() {
    const { errors, showForm, _id, name, street, city, state, zip, phone } =
      this.state
    const { loading, loadingName, locations } = this.props
    if ((loading && loadingName === 'admin-user-list') || !locations)
      return <Loading />
    return (
      <div>
        <AdminClientSelector />
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/admin')}>
                  Dashboard
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section>Locations</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <Button
              content='New Location'
              primary
              onClick={() => this.setState({ ...initState, showForm: true })}
            />
          </div>
        </div>

        <Card.Group>
          {locations &&
            Array.isArray(locations) &&
            locations.map((location) => (
              <Card key={location._id}>
                {_id === location._id ? (
                  <>
                    <Card.Content className='admin-content'>
                      <form className='admin-form'>
                        <Input
                          error={errors.name}
                          size='mini'
                          label='Name'
                          name='name'
                          value={name}
                          onChange={this.handleChange}
                        />
                        <Input
                          error={errors.street}
                          size='mini'
                          label='Street'
                          name='street'
                          value={street}
                          onChange={this.handleChange}
                        />
                        <Input
                          error={errors.city}
                          size='mini'
                          label='City'
                          name='city'
                          value={city}
                          onChange={this.handleChange}
                        />
                        <Input
                          error={errors.state}
                          size='mini'
                          label='State'
                          name='state'
                          value={state}
                          onChange={this.handleChange}
                        />
                        <Input
                          error={errors.zip}
                          size='mini'
                          label='Zip'
                          name='zip'
                          value={zip}
                          onChange={this.handleChange}
                        />
                        <Input
                          error={errors.phone}
                          size='mini'
                          label='Phone'
                          name='phone'
                          value={phone}
                          onChange={this.handleChange}
                        />
                      </form>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <Button
                          basic
                          color='green'
                          onClick={() => this.handleSubmit()}>
                          Save
                        </Button>
                        <Button
                          basic
                          color='red'
                          onClick={() => this.setState(initState)}>
                          Cancel
                        </Button>
                      </div>
                    </Card.Content>
                  </>
                ) : (
                  <>
                    <Card.Content className='admin-content'>
                      <Card.Header>{location.name}</Card.Header>
                      <Card.Description>
                        {location.street}
                        <br />
                        {location.city}, {location.state} {location.zip}
                        <br />
                        {location.phone ? (
                          <>{location.phone}</>
                        ) : (
                          <em>&nbsp;</em>
                        )}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='flex-box between'>
                        <div>
                          <Icon
                            link
                            color='green'
                            name='edit'
                            onClick={() => this.handleEdit(location)}
                          />
                        </div>
                        {location._id !== this.props.org.location._id && (
                          <div>
                            <Icon
                              link
                              color='orange'
                              name='delete'
                              onClick={() => this.handleDelete(location)}
                            />
                          </div>
                        )}
                      </div>
                    </Card.Content>
                  </>
                )}
              </Card>
            ))}
          <Card>
            {showForm ? (
              <>
                <Card.Content className='admin-content'>
                  <form className='admin-form'>
                    <Input
                      error={errors.name}
                      size='mini'
                      label='Name'
                      name='name'
                      value={name}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.street}
                      size='mini'
                      label='Street'
                      name='street'
                      value={street}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.city}
                      size='mini'
                      label='City'
                      name='city'
                      value={city}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.state}
                      size='mini'
                      label='State'
                      name='state'
                      value={state}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.zip}
                      size='mini'
                      label='Zip'
                      name='zip'
                      value={zip}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.phone}
                      size='mini'
                      label='Phone'
                      name='phone'
                      value={phone}
                      onChange={this.handleChange}
                    />
                  </form>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <Button
                      basic
                      color='green'
                      onClick={() => this.handleSubmit()}>
                      Save
                    </Button>
                    <Button
                      basic
                      color='red'
                      onClick={() => this.setState(initState)}>
                      Cancel
                    </Button>
                  </div>
                </Card.Content>
              </>
            ) : (
              <>
                <Card.Content className='admin-content'>
                  <Card.Header>New Location</Card.Header>
                  <Card.Description>Add a new location.</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <div className='flex-box between'>
                    <div>
                      <Icon
                        link
                        color='green'
                        name='add'
                        onClick={() =>
                          this.setState({ ...initState, showForm: true })
                        }
                      />
                    </div>
                  </div>
                </Card.Content>
              </>
            )}
          </Card>
        </Card.Group>
      </div>
    )
  }
}

const mapState = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  locations: state.admin?.org?.locations,
  org: state.admin?.org,
  user: state.auth?.currentUser,
})

const actions = {
  fetchOrganization,
  openModal,
  updateLocation,
  addLocation,
  deleteLocation,
}

export default connect(mapState, actions)(AdminLocationList)
