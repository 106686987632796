/* eslint no-useless-escape: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Icon, Input, Image, Button, Breadcrumb } from 'semantic-ui-react'
import { openModal } from '../../../../common/ui/modal/ModalActions'
import {
  updateAdmin,
  addAdmin,
  deleteAdmin,
  fetchOrganization,
} from '../../../../common/ui/auth/AuthActions'
import Loading from '../../../../common/ui/loading/Loading'
import AdminClientSelector from '../AdminClientSelector'

const initState = {
  errors: {},
  showNewAdminForm: false,
  _id: '',
  displayName: '',
  email: '',
  title: '',
  phone: '',
}

const isValidEmail = (value) => {
  return (
    value &&
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value
    )
  )
}
export class AdminUserList extends Component {
  constructor(props) {
    super(props)
    this.state = initState
    this.handleEdit = this.handleEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePhone = this.handlePhone.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEdit(admin) {
    this.setState({
      errors: {},
      showNewAdminForm: false,
      _id: admin._id,
      displayName: admin.displayName,
      email: admin.email,
      title: admin.title,
      phone: admin.phone,
    })
  }

  async handleDelete(admin) {
    try {
      console.log('admin', admin)
      this.props.deleteAdmin(admin)
    } catch (error) {
      this.props.history.push('/admin')
    }
  }

  // TODO: Make this a util
  handlePhone(value, previousValue) {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length
    if (!previousValue || value.length !== previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
  }

  handleChange(e, { name, value }) {
    if (name === 'phone') {
      value = this.handlePhone(value, this.state.phone)
    }
    const errors = Object.assign({}, this.state.errors)
    delete errors[name]
    this.setState({ ...this.state, [name]: value, errors: errors })
  }

  async handleSubmit(e) {
    try {
      const { errors, _id, displayName, email, title, phone } = this.state
      const { org } = this.props
      if (!displayName) errors['displayName'] = true
      if (!email || !isValidEmail(email)) errors['email'] = true
      if (!phone || phone.length !== 14) errors['phone'] = true
      if (!title) errors['title'] = true
      if (Object.keys(errors).length > 0) {
        this.setState({ ...this.state, errors: errors })
        return
      }
      if (_id && _id.length > 0) {
        await this.props.updateAdmin({ _id, displayName, email, title, phone }, org._id)
      } else {
        await this.props.addAdmin({
          displayName: displayName,
          email: email,
          title: title,
          phone: phone,
          organization: org._id,
        })
      }
      this.setState(initState)
      await this.props.fetchOrganization(org._id)
    } catch (error) {
      this.props.history.push('/admin')
    }
  }

  render() {
    const { errors, showNewAdminForm, _id, displayName, email, title, phone } =
      this.state
    const { loading, loadingName, currentUser, users } = this.props
    if (
      (loading && loadingName === 'admin-user-list') ||
      !currentUser ||
      !users
    )
      return <Loading />
    return (
      <div>
        <AdminClientSelector />
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/admin')}>
                  Dashboard
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section>Administrators</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <Button
              content='New Administrator'
              primary
              onClick={() =>
                this.setState({ ...initState, showNewAdminForm: true })
              }
            />
          </div>
        </div>

        <Card.Group>
          {users &&
            Array.isArray(users) &&
            users
              .sort((x, y) =>
                x._id === currentUser._id
                  ? -1
                  : y._id === currentUser._id
                  ? 1
                  : 0
              )
              .map((user) => (
                <Card key={user._id}>
                  {_id === user._id ? (
                    <>
                      <Card.Content className='admin-content'>
                        <form className='admin-form'>
                          <input type='hidden' name='_id' value={_id} />
                          <Input
                            error={errors.displayName}
                            size='mini'
                            label='Name'
                            name='displayName'
                            value={displayName}
                            onChange={this.handleChange}
                          />
                          <Input
                            error={errors.email}
                            size='mini'
                            label='Email'
                            name='email'
                            value={email}
                            onChange={this.handleChange}
                          />
                          <Input
                            error={errors.title}
                            size='mini'
                            label='Title'
                            name='title'
                            value={title}
                            onChange={this.handleChange}
                          />
                          <Input
                            error={errors.phone}
                            size='mini'
                            label='Phone'
                            name='phone'
                            value={phone}
                            onChange={this.handleChange}
                          />
                        </form>
                      </Card.Content>
                      <Card.Content extra>
                        <div className='ui two buttons'>
                          <Button
                            basic
                            color='green'
                            onClick={() => this.handleSubmit()}>
                            Save
                          </Button>
                          <Button
                            basic
                            color='red'
                            onClick={() => this.setState(initState)}>
                            Cancel
                          </Button>
                        </div>
                      </Card.Content>
                    </>
                  ) : (
                    <>
                      <Card.Content className='admin-content'>
                        <Image
                          floated='right'
                          size='mini'
                          alt={user.displayName}
                          src={user.avatar || '/avatar.png'}
                        />
                        <Card.Header>{user.displayName}</Card.Header>
                        <Card.Meta>
                          {user.title ? <>{user.title}</> : <em>&nbsp;</em>}
                        </Card.Meta>
                        <Card.Description>
                          {user.email}
                          <br />
                          {user.phone ? <>{user.phone}</> : <em>&nbsp;</em>}
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <div className='flex-box between'>
                          <div>
                            <Icon
                              link
                              color='green'
                              name='edit'
                              onClick={() => this.handleEdit(user)}
                            />
                          </div>
                          {users.length === 0 ||
                            (user._id !== currentUser._id && (
                              <div>
                                <Icon
                                  link
                                  color='orange'
                                  name='delete'
                                  onClick={() => this.handleDelete(user)}
                                />
                              </div>
                            ))}
                        </div>
                      </Card.Content>
                    </>
                  )}
                </Card>
              ))}
          <Card>
            {showNewAdminForm ? (
              <>
                <Card.Content className='admin-content'>
                  <form className='admin-form'>
                    <input type='hidden' name='_id' value={_id} />
                    <Input
                      error={errors.displayName}
                      size='mini'
                      label='Name'
                      name='displayName'
                      value={displayName}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.email}
                      size='mini'
                      label='Email'
                      name='email'
                      value={email}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.title}
                      size='mini'
                      label='Title'
                      name='title'
                      value={title}
                      onChange={this.handleChange}
                    />
                    <Input
                      error={errors.phone}
                      size='mini'
                      label='Phone'
                      name='phone'
                      value={phone}
                      onChange={this.handleChange}
                    />
                  </form>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <Button
                      basic
                      color='green'
                      onClick={() => this.handleSubmit()}>
                      Save
                    </Button>
                    <Button
                      basic
                      color='red'
                      onClick={() => this.setState(initState)}>
                      Cancel
                    </Button>
                  </div>
                </Card.Content>
              </>
            ) : (
              <>
                <Card.Content className='admin-content'>
                  <Image
                    floated='right'
                    size='mini'
                    alt='New Administrator'
                    src='/avatar.png'
                  />
                  <Card.Header>New Administrator</Card.Header>
                  <Card.Meta>Title</Card.Meta>
                  <Card.Description>Add a new administrator.</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <div className='flex-box between'>
                    <div>
                      <Icon
                        link
                        color='green'
                        name='add'
                        onClick={() =>
                          this.setState({
                            ...initState,
                            showNewAdminForm: true,
                          })
                        }
                      />
                    </div>
                  </div>
                </Card.Content>
              </>
            )}
          </Card>
        </Card.Group>
      </div>
    )
  }
}

const mapState = (state) => ({
  loading: state.async?.loading,
  loadingName: state.async?.elementName,
  org: state.admin?.org,
  users: state.admin?.org?.users,
  currentUser: state.auth?.currentUser,
})

const actions = {
  fetchOrganization,
  openModal,
  updateAdmin,
  addAdmin,
  deleteAdmin,
}

export default connect(mapState, actions)(AdminUserList)
