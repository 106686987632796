import React, { Component } from 'react';

export class AdministratorsPage extends Component {
  render() {
    return (
      <>
        <h1 className='mb-1'>
          <strong>Schools and Special Education Administrators</strong>
        </h1>
        <div className='mt-0'>
          <em>
            Gain access to our nationwide community of special education professionals that match your open positions...
          </em>
        </div>
        <div className='flex-box-wrap mt-5'>
          {/* jobs search */}
          <div className='column-3'>
            <h3>
              <strong>It's easy to post jobs to our special education focused community.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/postjob'>Post a Job</a>
            <hr />
          </div>

          {/* Resume */}
          <div className='column-3'>
            <h3>
              <strong>Connect with qualified candidates and future SPED graduates.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/postjob'>Connect With Candidates</a>
            <hr />
          </div>

          {/* Scholarships */}
          <div className='column-3'>
            <h3>
              <strong>Increase your school's visibility with our custom promotions.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/postjob'>Promote Your Jobs</a>
            <hr />
          </div>

          {/* Giveaways */}
          <div className='column-3'>
            <h3>
              <strong>Hire your SPED staff directly and avoid costly agencies.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/postjob'>Let Us Help You Recruit</a>
            <hr />
          </div>

          {/* Question Board */}
          <div className='column-3'>
            <h3>
              <strong>Explore discussions related to special education administration.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/questions'>Join the Discussion</a>
            <hr />
          </div>

          {/* News */}
          <div className='column-3'>
            <h3>
              {/* <strong>Take advantage of our special offer during the COVID 19 pandemic.</strong> */}
              &nbsp;
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            {/* 
            <hr />
            <a href='/news'>See Our Special Offer</a>
            <hr />            
            */}
          </div>
        </div>
      </>
    );
  }
}

export default AdministratorsPage;
