import { ASYNC_ACTION_START, ASYNC_ACTION_FINISH, ASYNC_ACTION_ERROR, SET_SEARCH_LOCATION } from './asyncConstants';
const google = window.google;
export const asyncActionStart = (el) => {
  return {
    type: ASYNC_ACTION_START,
    payload: el
  };
};

export const asyncActionFinish = () => {
  return {
    type: ASYNC_ACTION_FINISH
  };
};

export const asyncActionError = () => {
  return {
    type: ASYNC_ACTION_ERROR
  };
};

export const setSearchLocation = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('set-location'));
      if (typeof navigator.geolocation === 'undefined') {
        dispatch({
          type: SET_SEARCH_LOCATION,
          payload: { city: 'Chicago', state: 'IL' }
        });
        dispatch(asyncActionFinish());
      }
      navigator.geolocation.getCurrentPosition(function (position) {
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          {
            location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              let city = 'Chicago';
              let state = 'IL';
              let country = 'US';
              for (let addrComp of results[0].address_components) {
                if (addrComp.types[0] === 'locality') city = addrComp.long_name;
                if (addrComp.types[0] === 'administrative_area_level_1') state = addrComp.short_name;
                if (addrComp.types[0] === 'country') country = addrComp.short_name;
              }
              if (country !== 'US') {
                dispatch({
                  type: SET_SEARCH_LOCATION,
                  payload: { city: 'Chicago', state: 'IL' }
                });
                dispatch(asyncActionFinish());
              } else {
                dispatch({
                  type: SET_SEARCH_LOCATION,
                  payload: { city: city, state: state }
                });
                dispatch(asyncActionFinish());
              }
            } else {
              dispatch({
                type: SET_SEARCH_LOCATION,
                payload: { city: 'Chicago', state: 'IL' }
              });
              dispatch(asyncActionFinish());
            }
          }
        );
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: SET_SEARCH_LOCATION,
        payload: { city: 'Chicago', state: 'IL' }
      });
      dispatch(asyncActionError());
    }
  };
};
