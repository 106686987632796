import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import moment from 'moment/moment.js';
import { openModal } from '../../../common/ui/modal/ModalActions';

import ProfileSection from './ProfileSection/ProfileSection';

export class ProfileCertification extends Component {
  render() {
    const { certifications } = this.props.user;
    return (
      <ProfileSection title='Certifications/Licences'>
        <ul className='section-ul'>
          <li>
            <div
              className='add-btn'
              onClick={() =>
                this.props.openModal('ModalProfileCertifications', { index: certifications.length, isAdd: true })
              }>
              <Icon circular name='add' /> Add Certification/License
            </div>
          </li>
          {certifications.map((cert, index) => (
            <li key={`cert-${index}`} className='details'>
              <span
                className='remove-btn'
                title='delete'
                onClick={() => this.props.openModal('ModalProfileCertifications', { index: index, isRemove: true })}>
                <Icon name='trash alternate outline' />
              </span>
              <span
                className='edit-btn'
                onClick={() => this.props.openModal('ModalProfileCertifications', { index: index, isAdd: false })}>
                <Icon name='pencil' />
              </span>
              <h6>{cert.title}</h6>
              <p>
                <em>{cert.state}</em>
              </p>
              <p>
                <strong>Effective: </strong>
                {moment(cert.effectiveDate).format('MMM YYYY')}
              </p>
              {cert.isPermanent === true ? (
                <p>
                  <strong>Expiration: </strong>Permanent
                </p>
              ) : (
                <p>
                  <strong>Expiration: </strong>
                  {moment(cert.expirationDate).format('MMM YYYY')}
                </p>
              )}
            </li>
          ))}
        </ul>
      </ProfileSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCertification);
