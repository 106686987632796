import React, { Component } from 'react'
import { connect } from 'react-redux'

import ProfileAbout from './components/ProfileAbout'
// import ProfileProgress from './components/ProfileProgress';
import ProfileSocial from './components/ProfileSocial'
import ProfileSummary from './components/ProfileSummary'
import ProfileExperience from './components/ProfileExperience'
import ProfileEducation from './components/ProfileEducation'
import ProfileCertification from './components/ProfileCertification'
import ProfileVisible from './components/ProfileVisible'
import ProfileRelocate from './components/ProfileRelocate'
import ProfileActivities from './components/ProfileActivities'
import ProfilePayment from "./components/ProfilePayment";
// import ProfileResume from './components/ProfileResume';
// import ProfileOther from './components/ProfileOther';

import { resumeDownload } from '../../common/ui/auth/AuthActions'

import './profile.scss'
import { Button } from 'semantic-ui-react'

export class ProfilePage extends Component {
  render() {
    return (
      <>
        <div className='flex-box xs align-center start-xs'>
          <div className='mr-2 text-left'>
            <Button
              color='green'
              onClick={() => this.props.resumeDownload(this.props.user)}>
              Create My Resume
            </Button>
          </div>
          <div>
            <div className='max-width-270px'>
              <strong>Try it today!</strong> Update your profile and download a
              formatted resume.
            </div>
          </div>
        </div>
        <hr />
        <div className='profile-wrap flex-box md'>
          <div className='grow'>
            <ProfileAbout />
            <ProfileSummary />
            <ProfileSocial />
          </div>
          <div className='spacer'></div>
          <div className='grow'>
            <ProfileExperience />
            <ProfileEducation />
          </div>
          <div className='spacer'></div>
          <div className='grow'>
            <ProfileCertification />
            <ProfileActivities />
            <ProfileVisible />
            <ProfileRelocate />
            <ProfilePayment />
          </div>
        </div>
      </>
    )
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser,
})

const actions = {
  resumeDownload,
}

export default connect(mapState, actions)(ProfilePage)
