import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container, Grid } from 'semantic-ui-react'
import { loadLatestArticles } from '../../news/newsActions'

export class HomeNewsSections extends Component {
  componentDidMount() {
    this.props.loadLatestArticles()
  }
  createImageSrc = (uid, name) => {
    return `https://spedxchange.s3.us-east-2.amazonaws.com/news/${uid}/${name}-cover.jpg`
  }

  handleArticleClick(article) {
    let externalLink = article.externalLink // external link string

    if (externalLink.length !== 0) {
      window.open(externalLink, '_blank')
    } else {
      this.props.history.push({
        pathname: `/news/${article.uid}/${article.slug}`,
      })
    }
  }

  render() {
    const { articles } = this.props
    if (!articles) return <></>
    const mainArticle = articles[0]
    const previewArticles = [articles[1], articles[2], articles[3]]
    return (
      <section id='HomeNewsSection'>
        <Container>
          <hr />
          <h2 className='link' onClick={() => this.props.history.push({ pathname: `/news` })}>
            SPED News: Read the latest
          </h2>
          <hr className='mb-3' />
          <Grid stackable>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {mainArticle && (
                <div className='main-article' onClick={() => this.handleArticleClick(mainArticle)}>
                  <div>
                    <img src={this.createImageSrc(mainArticle.uid, mainArticle.photoURL)} alt={mainArticle.photoURL} />
                  </div>
                  <h3>{articles[0].title}</h3>
                  <p>
                    {articles[0].summary}&nbsp;&nbsp;<span>Read More...</span>
                  </p>
                </div>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {previewArticles.map((article) => (
                <div
                  key={article._id}
                  className='article-preview clearfix'
                  onClick={() => this.handleArticleClick(article)}>
                  <img src={this.createImageSrc(article.uid, article.photoURL)} alt={article.photoURL} />
                  <h3>{article.title}</h3>
                  <p>
                    {article.summary}&nbsp;&nbsp;<span>Read More...</span>
                  </p>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  articles: state.news.articles,
  loading: state.async.loading,
  loadingName: state.async.elementName,
})

const mapDispatchToProps = {
  loadLatestArticles,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeNewsSections))
