import { Breadcrumb, Grid } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { fetchAllArticles, fetchAllOrgs, fetchUsers } from './ReportActions'

import { connect } from 'react-redux'

export class Report extends Component {
  componentDidMount() {
    this.props.fetchAllArticles()
    this.props.fetchAllOrgs()
    this.props.fetchUsers()
  }

  render() {
    const { articles, organizations, users } = this.props
    if (!articles || !organizations || !users) return <div>Loading</div>
    return (
      <div className='admin-dashboard'>
        <div className='admin-nav'>
          <div className='flex-box between'>
            <div className='flex-box align-center grow'>
              <Breadcrumb size='large'>
                <Breadcrumb.Section>Management</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <Grid stackable columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <section>
                <h1>{organizations.length}</h1>
                <label>Organizations</label>
                <div>
                  <strong>{organizations.filter((o) => o.status === 'client').length}</strong>
                  <span>Paid Clients</span>
                </div>
                <div>
                  <strong>{organizations.filter((o) => o.status === 'legacy').length}</strong>
                  <span>Legacy</span>
                </div>
                <div>
                  <strong>{organizations.filter((o) => o.status === 'trial').length}</strong>
                  <span>Trials</span>
                </div>
                <div>
                  <Link to='/spedxchange-reports/organizations'>Manage Organizations</Link>
                </div>
              </section>
            </Grid.Column>
            <Grid.Column>
              <section>
                <h1>{users.length}</h1>
                <label>Members</label>
                <div>
                  <strong>{users.filter((m) => isSped(m)).length}</strong>
                  <span>SPED</span>
                </div>
                <div>
                  <strong>{users.filter((m) => isAdmin(m)).length}</strong>
                  <span>Admin</span>
                </div>
                <div>
                  <strong>{users.filter((m) => isManager(m)).length}</strong>
                  <span>Manager</span>
                </div>
                <div>
                  <Link to='/spedxchange-reports/members'>Manage Members</Link>
                </div>
              </section>
            </Grid.Column>
            <Grid.Column>
              <section>
                <h1>{articles.length}</h1>
                <label>News Articles</label>
                <div>
                  <strong>{articles.filter((a) => a.status === 'Published').length}</strong>
                  <span>Published</span>
                </div>
                <div>
                  <strong>{articles.filter((a) => a.status === 'Draft').length}</strong>
                  <span>Draft</span>
                </div>
                <div>
                  <strong>{articles.filter((a) => a.status === 'Archived').length}</strong>
                  <span>Archived</span>
                </div>
                <div>
                  <Link to='/spedxchange-reports/news'>Manage News Articles</Link>
                </div>
              </section>
            </Grid.Column>
            <Grid.Column>
              <section>
                <h1>18,989</h1>
                <label>Public School Districts</label>
                <div>
                  <strong>0</strong>
                  <span>In Hubspot</span>
                </div>
                <div>
                  <strong>96,702</strong>
                  <span>Public Schools</span>
                </div>
                <div>
                  <strong>0</strong>
                  <span>Archived</span>
                </div>
                <div>
                  <Link to='/spedxchange-reports/districts'>Manage Public School Districts</Link>
                </div>
              </section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

const isAdmin = (member) => {
  const email = member.email.toLowerCase()
  return (
    !!member.organization &&
    email.indexOf('@spedxchange.com') === -1 &&
    email.indexOf('@jamtikmarketing.com') === -1 &&
    email.indexOf('@kevin-crawford.com') === -1
  )
}

const isManager = (member) => {
  const email = member.email.toLowerCase()
  return (
    !!member.organization &&
    (email.indexOf('@spedxchange.com') > -1 ||
      email.indexOf('@jamtikmarketing.com') > -1 ||
      email.indexOf('@kevin-crawford.com') > -1)
  )
}

const isSped = (member) => {
  const email = member.email.toLowerCase()
  return (
    !member.organization &&
    email.indexOf('@spedxchange.com') === -1 &&
    email.indexOf('@jamtikmarketing.com') === -1 &&
    email.indexOf('@kevin-crawford.com') === -1
  )
}

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.elementName,
  articles: state.report.articles,
  users: state.report.users,
  organizations: state.report.organizations
})

const mapDispatchToProps = {
  fetchAllArticles,
  fetchAllOrgs,
  fetchUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report))
