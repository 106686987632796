import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { openModal } from '../../common/ui/modal/ModalActions'
import { setSEO } from '../../common/ui/nav/navActions'

const scholarshipList = [
  {
    title: 'The Alice Mann Civics/ Community Service Scholarship: Promoting Diversity in Civic/ Community Service',
    amount: '$5,000 ',
    deadline: '05/01/2023',
    link: 'https://lwplife.org/2023-lwp-scholarships'
  },
  {
    title:
      'Arizona Teacher Student Loan Program (formerly Math, Science and Special Education Teacher Loan Forgiveness Program)',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://azgrants.az.gov/arizona-teacher-student-loan-program-formerly-math-science-and-special-education-teacher-loan'
  },
  {
    title: 'Bethesda Auxiliary Scholarships ',
    amount: '$3,000 ',
    deadline: '05/13/2023',
    link: 'https://ablelight.org/get-involved/auxiliary/'
  },
  {
    title: 'Edward G. and Helen A. Borgens Elementary Teacher Scholarship',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://dar.academicworks.com'
  },
  {
    title: 'Illinois Special Education Teacher Tuition Waiver (SETTW) Program',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://www.isac.org/students/during-college/types-of-financial-aid/scholarships/illinois-special-education-teacher-tuition-waiver-settw-program.html'
  },
  {
    title: 'Kentucky Teacher Scholarship Program',
    amount: '$5,000 ',
    deadline: '05/01/2023',
    link: 'https://www.kheaa.com/website/kheaa/teacher?main=1'
  },
  {
    title: 'Paraprofessional Teacher Preparation Grant Program',
    amount: '$7,500',
    deadline: 'Varies',
    link: 'https://www.scholarships.com/financial-aid/college-scholarships/scholarship-directory/academic-major/special-education/paraprofessional-teacher-preparation-grant-program'
  },
  {
    title: 'Special Education Services Scholarship',
    amount: 'Varies',
    deadline: 'None',
    link: 'https://www.in.gov/che/'
  },
  {
    title: 'T.E.A.C.H. Early Childhood Minnesota Scholarship Programs',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://www.scholarships.com/financial-aid/college-scholarships/scholarship-directory/academic-major/special-education/t-e-a-c-h-early-childhood-minnesota-scholarship-programs'
  },
  {
    title: 'TEACH Grant',
    amount: '$4,000',
    deadline: 'Varies',
    link: 'https://www.scholarships.com/financial-aid/college-scholarships/scholarship-directory/academic-major/special-education/teach-grant'
  },
  {
    title: 'Tennessee Minority Teaching Fellows Program',
    amount: '$5,000 ',
    deadline: '04/15/2023',
    link: 'https://www.tn.gov/collegepays/money-for-college/loan-forgiveness-programs/minority-teaching-fellows-program.html'
  },
  {
    title: 'Underwood-Smith Teaching Scholars Program',
    amount: '$10,000 ',
    deadline: '12/31/2023',
    link: 'https://www.scholarships.com/financial-aid/college-scholarships/scholarship-directory/academic-major/special-education/underwood-smith-teaching-scholars-program'
  },
  {
    title: 'VSAC-Assisted Scholarships',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://www.vsac.org/pay/student-aid-options/scholarships/vsac-assisted-scholarships'
  },
  {
    title: 'Victoria Soto Memorial Scholarship',
    amount: '$12,000',
    deadline: '4/01/2023',
    link: 'https://www.teamvickisoto.com/scholarships'
  },
  {
    title: 'VSAC-Assisted Scholarships',
    amount: 'Varies',
    deadline: 'Varies',
    link: 'https://www.vsac.org/pay/student-aid-options/scholarships/vsac-assisted-scholarships'
  },
]

const seo = {
  title: `SPED Scholarships`,
  description: 'Scholarships for Special Educators',
  image: `https://www.spedxchange.com/assets/img/scholarship.png`,
  url: `https://www.spedxchange.com/scolarships`,
  type: 'article',
  siteName: 'SPEDxchange',
  alt: 'SPED Scholarships',
  robots: 'index,follow'
}

export class Scholarships extends Component {
  componentDidMount() {
    this.props.setSEO(seo)
  }

  handleApply = () => {
    if (this.props.auth.authenticated) {
      this.props.openModal('ScholarshipModal', { scholarshipId: 'teacher' })
    } else {
      this.props.openModal('UnauthModal')
    }
  }

  handleSocialClick = (type) => {
    const url = window.location.protocol + '//' + window.location.host + '/scholarships/teacher'
    if (type === 'twitter') {
      window.open(
        `https://twitter.com/intent/tweet?text=SPEDxchange:%202020%20Special%20Education%20Teacher%20Scholarship%20$1,000&amp;url=${url}`,
        'twitter-share-dialog',
        'width=600,height=480'
      )
    }
    if (type === 'linkedin') {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}&amp;title=Special%20Education%20Teacher%20Scholarship%20$1,000`,
        'linkedin-share-dialog',
        'width=600,height=480'
      )
    }
    if (type === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        'facebook-share-dialog',
        'width=600,height=480'
      )
    }
  }

  setEmailLink = () => {
    const url = window.location.protocol + '//' + window.location.host + '/scholarships/teacher'
    return `mailto:?subject=2020%20Special%20Education%20Teacher%20Scholarship%20$1,000&body=I thought you might be interested in reading this SPED Talk article.%0D%0A%0D%0A2020%20Special%20Education%20Teacher%20Scholarship%20$1,000%0D%0A${url}%0D%0A%0D%0A`.replace(
      / /g,
      '%20'
    )
  }

  render() {
    return (
      <>
        <div className='resources'>
          <div>
            <h5 className='section-head mb-3'>SPEDxchange Scholarships</h5>
            <div className='article-wrap'>
              <div className='article'>
                <div>
                  <div>
                    <img src='/assets/img/2022-Scholarship-Winner.png' alt='Special Education Teacher Scholarship' />
                  </div>
                </div>
                <div className='article-figure'>
                  <div className='share'>SHARE</div>
                  <Icon link circular name='twitter' onClick={() => this.handleSocialClick('twitter')} />
                  <Icon link circular name='linkedin' onClick={() => this.handleSocialClick('linkedin')} />
                  <Icon link circular name='facebook' onClick={() => this.handleSocialClick('facebook')} />
                  <a href={this.setEmailLink()}>
                    <Icon link circular name='envelope outline' />
                  </a>
                </div>
                <h1 className='text-primary my-2'>
                  <strong>
                    2022 SPEDxchange Future SPED Teacher Scholarship - <small>$</small>1,000
                  </strong>
                </h1>
                <p>We would love to thank all the scholarship applicants. The application deadline has arrived and we have closed the application process. <strong>We received a record number of applications from amazing future special education superstars. </strong> We’re excited to introduce this year’s SPEDxchange $1K scholarship winner, Hannah Lium! Hannah is pursuing her degree in Special Education with a focus in Deaf/Hard of Hearing Studies at Minot University in Minot, ND, and will be graduating this December 2022.</p>
                <p> <strong>The 2023 SPEDxchange Future SPED Teacher Scholarship will begin accepting applications in the new year..</strong></p>
                {/*<p>*/}
                {/*  SPEDxchange is offering a $1,000 scholarship for undergraduate or graduate students that are*/}
                {/*  interested in working with special needs students.*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*  This award is for undergraduate or graduate students that are pursuing a degree in following special*/}
                {/*  education or a related fields:*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*  <strong>Special Education Teacher</strong>*/}
                {/*  <br />*/}
                {/*  <strong>Special Education Diagnosticians</strong>*/}
                {/*  <br />*/}
                {/*  <strong>Teachers of the Deaf and Hard of Hearing</strong>*/}
                {/*  <br />*/}
                {/*  <strong>Teachers of the Visually Impaired</strong>*/}
                {/*</p>*/}
                {/*<p>Applications will be accepted until November 15, 2022.</p>*/}
                <p>Sincerely, </p>
                <p>John Consalvi CCC-SLP, CEO-Co-Founder</p>
                {/*<p>*/}
                {/*  <em>*/}
                {/*    <strong>Winner will be announced on December 15, 2022.</strong>*/}
                {/*  </em>*/}
                {/*</p>*/}
                {/*<Button color='green' className='mb-3' onClick={this.handleApply}>*/}
                {/*  Apply Today!*/}
                {/*</Button>*/}
                <p>
                  <em>
                    <a href={"https://www.spedxchange.com/news/039448/introducing-the-spedxchange-2022-scholarship-recipient-hannah-lium"}>Read more about Hannah</a>
                  </em>
                </p>
              </div>
            </div>
          </div>
          <hr className='my-3' />
          <div className='pt-3'>
            <h5 className='section-head purple mb-3'>More Scholarship Links</h5>
            <p>Even though SPEDxchange's scholarship has closed, there are plenty of more opportunities for scholarships!</p>
            {scholarshipList &&
              scholarshipList.map((item, idx) => (
                <div key={idx.toString()} className='mb-3'>
                  <a href={item.link} title={item.title} target='_blank' rel='noopener noreferrer'>
                    {item.title}
                  </a>
                  <div>
                    Award: <strong>{item.amount}</strong>
                  </div>
                  <div>
                    Deadline: <strong>{item.deadline}</strong>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    )
  }
}

const mapState = (state) => ({
  auth: state.auth
})

const actions = {
  openModal,
  setSEO
}

export default connect(mapState, actions)(withRouter(Scholarships))
