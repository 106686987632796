import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReCaptcha } from 'react-recaptcha-v3'
import { Button, Checkbox, Form, Segment, TextArea } from 'semantic-ui-react'
import { handlePhone, isValidEmail } from '../../common/util'
import { submitResourcesContact } from '../../common/ui/contact/actions/contactActions'

const initState = {
  mounted: false,
  errors: {},
  errorMessages: {},
  submitting: false,
  displayName: '',
  email: '',
  phone: '',
  title: '',
  school: '',
  checkBoxStudent: false,
  checkBoxTeacher: false,
  checkBoxJob: false,
  checkBoxAdministrator: false,
  checkBoxProfessional: false,
  checkBoxPrivatePractice: false,
  note: ''
}

const validateForm = (form) => {
  const errors = {}
  const errorMessages = {}
  if (!form.displayName) {
    errors['displayName'] = true
    errorMessages['displayName'] = 'Name is required'
  }
  if (!errors['displayName'] && form.displayName.trim().indexOf(' ') < 1) {
    errors['displayName'] = true
    errorMessages['displayName'] = 'Please provide full name'
  }
  if (!form.email) {
    errors['email'] = true
    errorMessages['email'] = 'Email is required'
  }
  if (!errors['email'] && !isValidEmail(form.email)) {
    errors['email'] = true
    errorMessages['email'] = 'Please provide a valid email'
  }
  return { errors: errors, errorMessages: errorMessages }
}

export class ResourcesContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = initState
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate() {
    if (!this.state.mounted && this.props.currentUser) {
      this.setState({
        ...this.state,
        mounted: true,
        displayName: this.props.currentUser.displayName,
        email: this.props.currentUser.email,
        phone: this.props.currentUser.phone
      })
    }
  }

  handleChange(e, { name, value }) {
    const errors = Object.assign({}, this.state.errors)
    const errorMessages = Object.assign({}, this.state.errorMessages)
    errors[name] = false
    errorMessages[name] = ' '
    if (name.indexOf('checkBox') > -1) value = !this.state[name]
    if (name === 'phone') value = handlePhone(value)
    this.setState({ ...this.state, [name]: value, errors: errors })
  }

  handleSubmit() {
    const form = { ...this.state }
    const validation = validateForm(form)
    const submitForm = Object.keys(validation.errors).length < 1
    if (submitForm) {
      this.props.submitResourcesContact(form)
    }
    this.setState({
      ...this.state,
      errors: validation.errors,
      errorMessages: validation.errorMessages,
      submitting: submitForm
    })
  }

  render() {
    const {
      submitting,
      errors,
      errorMessages,
      displayName,
      email,
      phone,
      title,
      school,
      checkBoxStudent,
      checkBoxTeacher,
      checkBoxJob,
      checkBoxAdministrator,
      checkBoxProfessional,
      checkBoxPrivatePractice,
      note
    } = this.state
    const { resoucesContactSubmitted } = this.props
    return (
      <>
        {resoucesContactSubmitted ? (
          <Segment inverted color='purple'>
            <h3 className='my-0'>Thanks!</h3>
            <p>The SPEDxchange team will contact you soon.</p>
          </Segment>
        ) : (
          <Segment inverted color='purple' className='info-form'>
            <h3 className='ovo my-0'>Join the SPEDxchange Community.</h3>
            <div className='mb-3'>
              <strong>Fill out this form and weʼll be in touch!</strong>
            </div>
            <Form inverted size='mini' onSubmit={this.handleSubmit}>
              <ReCaptcha sitekey='6LdfOb8UAAAAAJg87yIa2NJwxwP8ZkJJg18XGG1M' action='contact' />
              <div className='flex-box sm mb-2'>
                <div className='flex-5'>
                  <Form.Input
                    fluid
                    label='Name'
                    name='displayName'
                    title={errorMessages.displayName}
                    value={displayName || ''}
                    onChange={this.handleChange}
                    error={errors.displayName}
                  />
                  <Form.Input
                    fluid
                    label='Email'
                    name='email'
                    title={errorMessages.email}
                    value={email || ''}
                    onChange={this.handleChange}
                    error={errors.email}
                  />
                  <Form.Input
                    fluid
                    label='Phone'
                    name='phone'
                    title={errorMessages.phone}
                    value={phone || ''}
                    onChange={this.handleChange}
                    error={errors.phone}
                  />
                  <Form.Input
                    fluid
                    label='Title'
                    name='title'
                    value={title || ''}
                    onChange={this.handleChange}
                    error={errors.title}
                  />
                  <Form.Input
                    fluid
                    label='School'
                    name='school'
                    value={school || ''}
                    onChange={this.handleChange}
                    error={errors.school}
                  />
                </div>
                <div className='spacer middle' />
                <div className='flex-7'>
                  <div className='mb-2'>
                    <label>I am a (check all that apply)</label>
                  </div>
                  <div className='flex-box sm'>
                    <div className='flex-4'>
                      <div className='mb-3'>
                        <Checkbox
                          label='Student'
                          name='checkBoxStudent'
                          checked={checkBoxStudent}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <Checkbox
                          label='SPED Teacher'
                          name='checkBoxTeacher'
                          checked={checkBoxTeacher}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <Checkbox
                          label='SPED Job Seeker'
                          name='checkBoxJob'
                          checked={checkBoxJob}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='spacer hidden-sm' />
                    <div className='flex-6'>
                      <div className='mb-3'>
                        <Checkbox
                          label='Administrator or Manager'
                          name='checkBoxAdministrator'
                          checked={checkBoxAdministrator}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <Checkbox
                          label='Other SPED Professional'
                          name='checkBoxProfessional'
                          checked={checkBoxProfessional}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='mb-3'>
                        <Checkbox
                          label='SPED Private Practice'
                          name='checkBoxPrivatePractice'
                          checked={checkBoxPrivatePractice}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='tell-us'>
                      <TextArea
                        placeholder='Tell us more'
                        name='note'
                        value={note || ''}
                        style={{ minHeight: 100 }}
                        onChange={this.handleChange}
                      />
                    </div>
                    <Button size='mini' type='submit' disabled={submitting}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Segment>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  resoucesContactSubmitted: state.contact.resoucesContactSubmitted
})

const mapDispatchToProps = {
  submitResourcesContact
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesContactForm)
