import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Icon, Input, Button } from 'semantic-ui-react';

import { closeModal } from '../../../../common/ui/modal/ModalActions';
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions';

class ModalProfileSocial extends Component {
  constructor(props) {
    super(props);
    const initUser = Object.assign({}, this.props.user);
    initUser.instagram = this.props.user.instagram || '';
    initUser.facebook = this.props.user.facebook || '';
    initUser.twitter = this.props.user.twitter || '';
    initUser.website = this.props.user.website || '';
    initUser.linkedin = this.props.user.linkedin || '';
    this.state = initUser;
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  handleSubmit() {
    const form = {
      ...this.props.user.profile,
      ...this.state
    };
    // console.log('form: ', form);
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  render() {
    const { facebook, twitter, linkedin, instagram, website } = this.state;
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>About Me</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='small' onSubmit={this.handleSubmit}>
              <Form.Field>
                <Input iconPosition='left'>
                  <Icon circular name='instagram' />
                  <input
                    id='instagram'
                    name='instagram'
                    value={instagram}
                    maxLength={32}
                    onChange={this.handleChange}
                  />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input iconPosition='left'>
                  <Icon circular name='twitter' />
                  <input id='twitter' name='twitter' value={twitter} maxLength={32} onChange={this.handleChange} />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input iconPosition='left'>
                  <Icon circular name='linkedin' />
                  <input id='linkedin' name='linkedin' value={linkedin} maxLength={32} onChange={this.handleChange} />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input iconPosition='left'>
                  <Icon circular name='facebook' />
                  <input id='facebook' name='facebook' value={facebook} maxLength={32} onChange={this.handleChange} />
                </Input>
              </Form.Field>
              <Form.Field>
                <Input iconPosition='left'>
                  <Icon circular name='linkify' />
                  <input id='website' name='website' value={website} maxLength={32} onChange={this.handleChange} />
                </Input>
              </Form.Field>
              <Button color='green' type='submit'>
                Save
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
});

const actions = {
  closeModal,
  updateUserProfile
};

export default connect(mapState, actions)(ModalProfileSocial);
