import React, { Component } from 'react';

export class ProfessionalsPage extends Component {
  render() {
    return (
      <>
        <h1 className='mb-1'>
          <strong>Special Education Students and Professionals</strong>
        </h1>
        <div className='mt-0'>
          <em>Join our Nationwide community of special education professionals and reap many rewards...</em>
        </div>
        <div className='flex-box-wrap mt-5'>
          {/* jobs search */}
          <div className='column-3'>
            <h3>
              <strong>Simplify your job search and find opportunities perfect for you.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/jobs'>Start Your Search</a>
            <hr />
          </div>

          {/* Resume */}
          <div className='column-3'>
            <h3>
              <strong>Update your professional profile and create the perfect resume.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/profile'>Create Your Resume</a>
            <hr />
          </div>

          {/* Scholarships */}
          <div className='column-3'>
            <h3>
              <strong>Find hundreds of scholarship opportunities in your field.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/scholarships'>Find a Scholarship</a>
            <hr />
          </div>

          {/* Giveaways */}
          <div className='column-3'>
            <h3>
              <strong>Get automatically entered into our weekly materials giveaways.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/profile'>See Our Latest Giveaway</a>
            <hr />
          </div>

          {/* Question Board */}
          <div className='column-3'>
            <h3>
              <strong>Explore science-based discussions related to special education.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/questions'>Join the Conversation</a>
            <hr />
          </div>

          {/* News */}
          <div className='column-3'>
            <h3>
              <strong>Stay up-to-date on the latest special education news.</strong>
            </h3>
            {/*
            <div>
              <img
                src='https://spedxchange.s3.us-east-2.amazonaws.com/news/49666/online-education-and-teletherapy-cover.jpg'
                alt='online-education-and-teletherapy'
              />
            </div>
            <p>
              SPEDxchange is excited to share how one speech-language pathologist embraced technology to make her
              teletherapy sessions innovative and engaging during this time.
            </p>
            */}
            <hr />
            <a href='/news'>Read Our Latest News</a>
            <hr />
          </div>
        </div>
      </>
    );
  }
}

export default ProfessionalsPage;
