import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {closeModal} from "../../../common/ui/modal/ModalActions";

import { Modal, Button } from 'semantic-ui-react';
import HomePage from "./HomePage";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

export class StripeModal extends Component {
    constructor(props) {
        super(props);
        // this.handleJobAction = this.handleJobAction.bind(this);
        this.onRouteChanged = this.onRouteChanged.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.handleCloseModal();
    }

    handleCloseModal() {
        this.props.closeModal();
    }

    render() {
        const stripePromise = loadStripe("pk_live_gamCDUMQCzaGq7sWVYZpJYx100p601Dacl");

        return (
            <Modal size='mini' open={true} onClose={this.handleCloseModal}>
                <Modal.Header>Subscribe to SPEDxchange Plan</Modal.Header>
                <Modal.Description>&nbsp; &nbsp; &nbsp;</Modal.Description>
                <Elements stripe={stripePromise}>
                    <HomePage organization={this.props.org} buttonClick={this.props.typeOfClick} userName={this.props.user.firstName} />
                </Elements>
                <Modal.Actions>
                    <Button onClick={this.handleCloseModal}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = {
    closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StripeModal));
