import React from 'react'
import { connect } from 'react-redux'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import ResetModal from './ResetModal'
import UnauthModal from './UnauthModal'
import UnauthModal2 from './UnauthModal2'
import AskQuestionModal from './AskQuestionModal'
import ContactModal from './ContactModal'
import ScholarshipModal from './ScholarshipModal'
import RegisterAdminModal from './RegisterAdminModal'
import AdminJobModal from '../../../feature/admin/component/jobs/AdminJobModal'
import ReportNewsModal from "../../../feature/report/component/news/ReportNewsModal";
import ModalProfileAbout from '../../../feature/profile/components/modal/ModalProfileAbout'
import ModalProfileCertifications from '../../../feature/profile/components/modal/ModalProfileCertifications'
import ModalProfileEducation from '../../../feature/profile/components/modal/ModalProfileEducation'
import ModalProfileExperience from '../../../feature/profile/components/modal/ModalProfileExperience'
import ModalProfileSocial from '../../../feature/profile/components/modal/ModalProfileSocial'
import ModalProfileSummary from '../../../feature/profile/components/modal/ModalProfileSummary'
import ModalProfileActivities from '../../../feature/profile/components/modal/ModalProfileActivities'
import ReportOrganizationModal from "../../../feature/report/component/organization/ReportOrganizationModal";

import StripeModal from "../../../feature/stripe/components/StripeModal";
import ConfirmCancel from "../../../feature/admin/component/ConfirmCancel";

import JopApplicantModal from "../../../feature/jobs/components/JobApplicantModal";
import AdminJobStatusModal from "../../../feature/admin/component/jobs/AdminJobStatusModal";

const modalLookup = {
  AdminJobModal,
  AdminJobStatusModal,
  ReportNewsModal,
  ReportOrganizationModal,
  LoginModal,
  RegisterModal,
  ResetModal,
  UnauthModal,
  UnauthModal2,
  AskQuestionModal,
  ContactModal,
  ScholarshipModal,
  RegisterAdminModal,
  ModalProfileAbout,
  ModalProfileCertifications,
  ModalProfileEducation,
  ModalProfileExperience,
  ModalProfileSocial,
  ModalProfileSummary,
  ModalProfileActivities,
  StripeModal,
  ConfirmCancel,
  JopApplicantModal
}

const mapState = (state) => ({
  currentModal: state.modals
})

const ModalManager = ({ currentModal }) => {
  let renderedModal

  if (currentModal) {
    const { modalType, modalProps } = currentModal
    const ModalComponent = modalLookup[modalType]
    // console.log('modalProps: ', modalProps);
    renderedModal = <ModalComponent {...modalProps} />
  }
  return <span>{renderedModal}</span>
}

export default connect(mapState)(ModalManager)
