import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {Breadcrumb, Button, Grid, List} from 'semantic-ui-react'
import { clearJob, fetchEvents, fetchOrgAnalytics } from './AdminActions'
import AdminClientSelector from './component/AdminClientSelector'
import AdminTrialMessage from "./component/AdminTrialMessage";

export class Admin extends Component {
  constructor(props) {
    super(props)
    this.handleNewJob = this.handleNewJob.bind(this)
  }

  componentDidUpdate() {
    if (!this.props.events && this.props.org?.name) {
      this.props.fetchOrgAnalytics(this.props.org._id)
      this.props.fetchEvents(this.props.org.name)
    }
  }

  handleNewJob(id) {
    this.props.clearJob()
    this.props.history.push(`/admin/job/new`)
  }

  render() {
    if (!this.props.org || !this.props.events) return <div>Loading...</div>
    const { org, events, orgAnalytics } = this.props

    // Calculate total jobClicks
    let totalJobClicks = 0;
    if (orgAnalytics != undefined) {
      totalJobClicks = orgAnalytics.reduce((total, analytic) => {
        return total + parseInt(analytic.jobClicks);
      }, 0);
    }



    const currentDate = new Date(); // Current date
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // 1 week ago
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14); // 2 weeks ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1); // 1 month ago

    let clicksPastWeek = 0;
    let clicksPastTwoWeeks = 0;
    let clicksPastMonth = 0;

    if (orgAnalytics != undefined) {
      for (const data of orgAnalytics) {
        if (data.timeStamps) {
          for (const timestamp of data.timeStamps) {
            const timestampDate = new Date(timestamp);
            if (timestampDate >= oneWeekAgo && timestampDate <= currentDate) {
              clicksPastWeek++;
            }
            if (timestampDate >= twoWeeksAgo && timestampDate <= oneWeekAgo) {
              clicksPastTwoWeeks++;
            }
            if (timestampDate >= oneMonthAgo && timestampDate <= twoWeeksAgo) {
              clicksPastMonth++;
            }
          }
        }
      }
    }


    return (
      <div className='admin-dashboard'>
        <AdminTrialMessage />
        <br />
        <div className='container'>
          <AdminClientSelector />
          <div className='admin-nav'>
            <div className='flex-box between'>
              <div className='flex-box align-center grow'>
                <Breadcrumb size='large'>
                  <Breadcrumb.Section>Dashboard</Breadcrumb.Section>
                </Breadcrumb>
              </div>
              <Button content='New Job' primary onClick={this.handleNewJob} />
            </div>
          </div>

          <Grid stackable columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <section>
                  <h1>
                    {org.jobs.filter((o) => o.status !== 'Archived').length}
                  </h1>
                  <label>Jobs</label>
                  <div>
                    <strong>
                      {org.jobs.filter((o) => o.status === 'Published').length}
                    </strong>
                    <span>Published</span>
                  </div>
                  <div>
                    <strong>
                      {org.jobs.filter((o) => o.status === 'Draft').length}
                    </strong>
                    <span>Draft</span>
                  </div>
                  <div>
                    <strong>
                      {org.jobs.filter((o) => o.status === 'Expired').length}
                    </strong>
                    <span>Expired</span>
                  </div>
                  <div>
                    <Link to='/admin/jobs'>Manage Jobs</Link>
                  </div>
                </section>
              </Grid.Column>
              <Grid.Column>
                <section>
                  <h1>{totalJobClicks}</h1>
                  <label>Job Clicks</label>
                  <div>
                    <strong>{clicksPastWeek}</strong>
                    <span>Past 7 Days</span>
                  </div>
                  <div>
                    <strong>{clicksPastTwoWeeks}</strong>
                    <span>Past 2 Weeks</span>
                  </div>
                  <div>
                    <strong>{clicksPastMonth}</strong>
                    <span>Past 30 Days</span>
                  </div>
                  {/*<div>*/}
                  {/*  <strong>{events.totals['Job Click'].totalTime}</strong>*/}
                  {/*  <span>Lifetime</span>*/}
                  {/*</div>*/}
                </section>
              </Grid.Column>
              {/*<Grid.Column>*/}
              {/*  <section>*/}
              {/*    <h1>{events.totals['Job Click'].month}</h1>*/}
              {/*    <label>Job Views</label>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Click'].day}</strong>*/}
              {/*      <span>Today</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Click'].week}</strong>*/}
              {/*      <span>Past 7 Days</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Click'].month}</strong>*/}
              {/*      <span>Past 30 Days</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Click'].totalTime}</strong>*/}
              {/*      <span>Lifetime</span>*/}
              {/*    </div>*/}
              {/*  </section>*/}
              {/*</Grid.Column>*/}
              {/*<Grid.Column>*/}
              {/*  <section>*/}
              {/*    <h1>{events.totals['Job Apply Click'].month}</h1>*/}
              {/*    <label>Job Clicks</label>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Apply Click'].day}</strong>*/}
              {/*      <span>Today</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Apply Click'].week}</strong>*/}
              {/*      <span>Past 7 Days</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>{events.totals['Job Apply Click'].month}</strong>*/}
              {/*      <span>Past 30 Days</span>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <strong>*/}
              {/*        {events.totals['Job Apply Click'].totalTime}*/}
              {/*      </strong>*/}
              {/*      <span>Lifetime</span>*/}
              {/*    </div>*/}
              {/*  </section>*/}
              {/*</Grid.Column>*/}
              <Grid.Column>
                <section>
                  <h1>{Array.isArray(org.users) && `${org.users.length}`}</h1>
                  <label>Administrators</label>
                  {Array.isArray(org.users) &&
                  org.users.map((item) => (
                      <div key={item._id}>{item.displayName}</div>
                  ))}
                  <div>
                    <Link to='/admin/users'>Manage Administrators</Link>
                  </div>
                </section>
              </Grid.Column>
              <Grid.Column>
                <section>
                  <h1>
                    {Array.isArray(org.locations) && `${org.locations.length}`}
                  </h1>
                  <label>Locations</label>
                  {Array.isArray(org.locations) &&
                  org.locations.map((item) => (
                      <div key={item._id}>{item.name}</div>
                  ))}
                  <div>
                    <Link to='/admin/locations'>Manage Locations</Link>
                  </div>
                </section>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
              {/*<Grid.Column>*/}
              {/*  <section>*/}
              {/*    <h1>{Array.isArray(org.users) && `${org.users.length}`}</h1>*/}
              {/*    <label>Administrators</label>*/}
              {/*    {Array.isArray(org.users) &&*/}
              {/*      org.users.map((item) => (*/}
              {/*        <div key={item._id}>{item.displayName}</div>*/}
              {/*      ))}*/}
              {/*    <div>*/}
              {/*      <Link to='/admin/users'>Manage Administrators</Link>*/}
              {/*    </div>*/}
              {/*  </section>*/}
              {/*</Grid.Column>*/}
              {/*<Grid.Column>*/}
              {/*  <section>*/}
              {/*    <h1>*/}
              {/*      {Array.isArray(org.locations) && `${org.locations.length}`}*/}
              {/*    </h1>*/}
              {/*    <label>Locations</label>*/}
              {/*    {Array.isArray(org.locations) &&*/}
              {/*      org.locations.map((item) => (*/}
              {/*        <div key={item._id}>{item.name}</div>*/}
              {/*      ))}*/}
              {/*    <div>*/}
              {/*      <Link to='/admin/locations'>Manage Locations</Link>*/}
              {/*    </div>*/}
              {/*  </section>*/}
              {/*</Grid.Column>*/}
              <Grid.Column>&nbsp;</Grid.Column>
            </Grid.Row>
          </Grid>
          {/*<section id='HomeAdminSection'>*/}
          {/*<div className='call-out-box'>*/}
          {/*  <p>We're excited to announce that our technology team is currently developing a powerful new analytics tool that will provide detailed insights into job board information. Keep an eye out for updates on this exciting new feature!</p>*/}
          {/*</div>*/}
          {/*</section>*/}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  loading: state.async.loading,
  loadingName: state.async.loadingName,
  auth: state.auth,
  org: state.admin.org,
  jobs: state.admin.jobs,
  events: state.admin.events,
  orgAnalytics: state.admin.orgAnalytics,
})

const actions = {
  clearJob,
  fetchEvents,
  fetchOrgAnalytics,
}

export default connect(mapState, actions)(withRouter(Admin))
