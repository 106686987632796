import { createReducer } from '../../util/ReducerUtil'
import { initUser } from './AuthActions'
import {
  REGISTER_FAIL,
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOAD_CLIENTS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  TOGGLE_FORGOT_PASSWORD,
  FETCH_SCHOLARSHIP_APPLICATION,
  SET_RECAPTCHA_TOKEN,
  SET_REGISTER_STEP,
  SET_EMPLOYER,
  UPDATE_PROFILE,
} from './AuthContantants'

const initialState = {
  authenticated: localStorage.getItem('token') !== null,
  isAdmin: false,
  isSiteAdmin: false,
  loaded: false,
  loading: false,
  isPasswordForgot: false,
  isPasswordSent: false,
  registerStep: 1,
  isEmployer: false,
  currentUser: {},
  clientList: null,
  clientActive: null,
  scholarshipApplication: {
    school: '',
    major: '',
    graduation: '',
    essay: '',
  },
}

export const trimCurrentUser = (user) => {
  if (!user) return initUser
  const tmp = {...user}
  tmp.organizationId = user.organization
  delete tmp['roles']
  delete tmp['created']
  delete tmp['organization']
  delete tmp['updated']
  delete tmp['__v']
  return tmp
}

const isAdminEmail = (member) => {
  const email = member.toLowerCase()
  return (
    email.indexOf('@spedxchange.com') > -1 ||
    email.indexOf('@jamtikmarketing.com') > -1 ||
    email.indexOf('@kevin-crawford.com') > -1
  )
}

const loadingUser = (state) => {
  return {
    ...state,
    loading: true,
  }
}

const loadClients = (state, payload) => {
  return {
    ...state,
    clientList: payload,
  }
}

const loadUser = (state, payload) => {
  const user = payload.user ? payload.user : payload
  const isAdmin = user.organization && user.organization.length > 0
  const isSiteAdmin = isAdminEmail(user.email)

  return {
    ...state,
    authenticated: localStorage.getItem('token') || false,
    isAdmin: isAdmin,
    isSiteAdmin: isSiteAdmin,
    loading: false,
    isPasswordForgot: false,
    isPasswordSent: false,
    currentUser: trimCurrentUser(user),
    loaded: true
  }
}

const loginSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
    authenticated: true,
    loading: false,
    isPasswordForgot: false,
    isPasswordSent: false,
  }
}

const logoutUser = (state) => {
  localStorage.removeItem('token')
  sessionStorage.removeItem('init')
  return {
    ...state,
    authenticated: false,
    isPasswordForgot: false,
    isPasswordSent: false,
    loading: false,
    currentUser: {},
  }
}

const updateProfile = (state, payload) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      [payload.prop]: payload.value,
    },
  }
}

const toggleForgotPassword = (state) => {
  return {
    ...state,
    isPasswordForgot: !state.isPasswordForgot,
  }
}

const loadScholarshipApplication = (state, payload) => {
  return {
    ...state,
    scholarshipApplication: payload,
  }
}

const setRecaptchaToken = (state, payload) => {
  return {
    ...state,
    recaptchaToken: payload,
  }
}

const setRegisterStep = (state, payload) => {
  return {
    ...state,
    registerStep: payload,
  }
}

const setIsEmployer = (state, payload) => {
  return {
    ...state,
    isEmployer: payload,
  }
}

export default createReducer(initialState, {
  [USER_LOADING]: loadingUser,
  [USER_LOADED]: loadUser,
  [LOAD_CLIENTS]: loadClients,
  [LOGIN_SUCCESS]: loginSuccess,
  [REGISTER_FAIL]: logoutUser,
  [AUTH_ERROR]: logoutUser,
  [LOGIN_FAIL]: logoutUser,
  [LOGOUT]: logoutUser,
  [ACCOUNT_DELETED]: logoutUser,
  [TOGGLE_FORGOT_PASSWORD]: toggleForgotPassword,
  [FETCH_SCHOLARSHIP_APPLICATION]: loadScholarshipApplication,
  [SET_RECAPTCHA_TOKEN]: setRecaptchaToken,
  [SET_REGISTER_STEP]: setRegisterStep,
  [SET_EMPLOYER]: setIsEmployer,
  [UPDATE_PROFILE]: updateProfile,
})
