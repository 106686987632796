import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../common/ui/modal/ModalActions';
import ProfileSection from './ProfileSection/ProfileSection';
import {Button} from "semantic-ui-react";
import {withRouter} from "react-router-dom";


export class ProfilePayment extends Component {
    constructor(props) {
        super(props);
        this.handleCancelSubscriptionClick = this.handleCancelSubscriptionClick.bind(this);
        this.currentDateMonth = this.currentDateMonth.bind(this);
        this.currentDateDay = this.currentDateDay.bind(this);
        this.handleUserPortalRedirection = this.handleUserPortalRedirection.bind(this);
    }

    handleUserPortalRedirection() {
        const stripePortalLink = "https://billing.stripe.com/p/login/6oE8wPaLO3JE5B6fYY"
        window.open(stripePortalLink, '_blank')
    }

    handleCancelSubscriptionClick(){
        this.props.openModal('ConfirmCancel', {
            ...this.props,
            annualClick: true,
        })
    }

    // Return the current month (i.e. 3 (March))
    currentDateMonth(){
        let currentDate = new Date();
        let month = String(currentDate.getMonth() + 1);
        return month;
    }
    // Return the current day (i.e. 15)
    currentDateDay(){
        let currentDate = new Date();
        let day = String(currentDate.getDate());
        return day;
    }
    render() {
        const {org} = this.props;
        if (org === undefined) {
            return (
                <div></div>
            )
        }
        const endDate = org.subscriptionEndDate;
        let formattedEndDate;
        let subscriptionType;
        if(org.subscriptionType === ""){
            subscriptionType = "N/A";
            formattedEndDate = "N/A";
        }
        if(org.subscriptionType === "annual"){
            subscriptionType = "Yearly";
        }
        else if(org.subscriptionType === "monthly"){
            subscriptionType = "Monthly";
        }

        // Handling Date Conversions
        let currentDateMonth = this.currentDateMonth();
        let currentDateDay = this.currentDateDay();

        if(endDate === "" || subscriptionType === "N/A"){
            formattedEndDate = "N/A";
        }
        else{
            let date = new Date(endDate);
            let month = date.getMonth() + 1;
            let day = date.getDate();
            const year = String(date.getFullYear());

            month += (currentDateMonth - month + 1); // Adjusting date UI not updated.
            day += (currentDateDay - day);

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            formattedEndDate = String(month) + "/" + String(day) + "/" + year;
        }

        return (
            <ProfileSection title='Payment'>
                <div className='profile-about text-center'>
                    <strong>Subscription Type: {subscriptionType}</strong>
                    <br />
                    <strong>Subscription Active Till: {formattedEndDate}</strong>
                    <br></br>
                    {
                        org.statusCode === "4" &&
                        <Button className={"primary"} onClick={this.handleUserPortalRedirection}>Visit Payment Portal</Button>
                    }
                    <br/>
                    <Button
                        color={(org.subscription === undefined || org.status === "trial" ? 'blue' : 'red')}
                        onClick= {(org.subscription === undefined || org.status === "trial" ? () => this.props.history.push(`/admin/job/new`) : this.handleCancelSubscriptionClick)}
                        content={(org.subscription === undefined || org.status === "trial" ? 'Subscribe' : 'Cancel Subscription')}>
                    </Button>
                    <br></br>
                </div>
            </ProfileSection>
        );

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
    org: state.admin.org,
});

const mapDispatchToProps = {
    openModal
};

// export default connect(mapStateToProps, mapDispatchToProps)(ProfileAbout);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePayment));
