import axios from 'axios'
import { HEADER_JSON } from '../../common/constants/apiConstants'
import {
  REPORT_ARTICLE_FETCH,
  REPORT_ARTICLE_FETCH_ALL,
  REPORT_ARTICLE_CLEAR,
  REPORT_EVENTS_FETCH,
  REPORT_JOB_FETCH,
  REPORT_JOB_CLEAR,
  REPORT_JOB_SAVE,
  REPORT_ORG_FETCH,
  REPORT_ORG_FETCH_ALL,
  REPORT_USER_FETCH_ALL,
} from './ReportContants'
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from '../../common/actions/async/asyncActions'
import { getTotals } from '../admin/AdminActions'
import {
  FETCH_ARTICLE,
  UPDATE_ARTICLES,
  UPDATE_ARTICLE,
  FETCH_ARTICLES,
} from '../admin/AdminConstants'
import { toastr } from 'react-redux-toastr'

export const fetchAllArticles = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-all-articles'))
      const response = await axios.get(`/api/news`)
      dispatch({ type: REPORT_ARTICLE_FETCH_ALL, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchArticle = (articleId) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-article'))
      const response = await axios.get(`/api/news/${articleId}`)
      dispatch({ type: REPORT_ARTICLE_FETCH, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const clearJob = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_JOB_CLEAR, payload: {} })
    } catch (error) {
      console.log(error)
    }
  }
}

export const clearArticle = () => {
  return (dispatch) => {
    dispatch({ type: REPORT_ARTICLE_CLEAR })
  }
}

export const createArticle = (article, history) => {
  const body = JSON.stringify(article)
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-article'))

      // cover image
      if (article.file) {
        let formData = new FormData()
        formData.append('file', article.fileData)
        await axios.post(`/api/upload/profile/news/${article.uid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      // article image
      if (article.file2) {
        let formData = new FormData()
        formData.append('file', article.fileData2)
        await axios.post(
            `/api/upload/imagetwo/profile/news/${article.uid}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
        )
      }

      // guest blogger image
      if (article.file3) {
        let formData = new FormData()
        formData.append('file', article.fileData3)
        await axios.post(
            `/api/upload/imagethree/profile/news/${article.uid}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
        )
      }

      const newArticle = await axios.post(`/api/news`, body, HEADER_JSON)
      dispatch({ type: FETCH_ARTICLE, payload: newArticle.data })
      const articles = await axios.get(`/api/news/${article.data}`)
      dispatch({ type: FETCH_ARTICLES, payload: articles.data })
      dispatch(asyncActionFinish())
      if (history) {
        history.push(`/admin/job/${newArticle.data._id}`)
      }
      toastr.success('Success!', 'Article Updated')
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const updateArticles = (articles) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ARTICLES,
      payload: articles,
    })
  }
}

export const updateArticle = (article) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ARTICLE,
      payload: article,
    })
  }
}

export const saveOrganization = (organization) => {
  const body = JSON.stringify(organization)
  return async (dispatch) => {
    const savedOrganization = await axios.put(
        `/api/organization/${organization._id}`,
        body,
        HEADER_JSON
    )
    dispatch({ type: FETCH_ARTICLE, payload: savedOrganization.data })
    dispatch(asyncActionFinish())
  }
}

export const closeOrganizationJobs = (organization) => {
  const body = JSON.stringify(organization)
  return async (dispatch) => {
    const savedOrganization = await axios.put(
        `/api/job/close/${organization._id}`,
        body,
        HEADER_JSON
    )
    dispatch({ type: FETCH_ARTICLE, payload: savedOrganization.data })
    dispatch(asyncActionFinish())
  }
}

export const deleteArticle = (article) => {
  return async (dispatch) => {
    await axios.delete(`/api/news/${article._id}`)
    dispatch(asyncActionFinish())
  }
}


export const saveArticle = (article) => {
  const body = JSON.stringify(article)
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())

      // cover image
      if (article.file) {
        let formData = new FormData()
        formData.append('file', article.fileData)
        await axios.post(`/api/upload/profile/news/${article.uid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      // article image
      if (article.file2) {
        let formData = new FormData()
        formData.append('file', article.fileData2)
        await axios.post(
            `/api/upload/imagetwo/profile/news/${article.uid}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
        )
      }

      // guest blogger image
      if (article.file3) {
        let formData = new FormData()
        formData.append('file', article.fileData3)
        await axios.post(
            `/api/upload/imagethree/profile/news/${article.uid}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
        )
      }

      const savedArticle = await axios.put(
          `/api/news/${article._id}`,
          body,
          HEADER_JSON
      )
      dispatch({ type: FETCH_ARTICLE, payload: savedArticle.data })
      const articles = await axios.get(`/api/news/${article.data}`)
      dispatch({ type: FETCH_ARTICLES, payload: articles.data })
      dispatch(asyncActionFinish())
      toastr.success('Success!', 'Article Updated')
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchJob = (jobId) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-job'))
      const response = await axios.get(`/api/jobs/${jobId}`)
      dispatch({ type: REPORT_JOB_FETCH, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const saveJob = (job) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('save-job'))
      const body = JSON.stringify(job)
      const response = await axios.post(
          `/api/job/${job._id}`,
          body,
          HEADER_JSON
      )
      dispatch({ type: REPORT_JOB_SAVE, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchAllOrgs = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-all-orgs'))
      const response = await axios.get(`/api/organization`)
      dispatch({ type: REPORT_ORG_FETCH_ALL, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchOrg = (orgId) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-org'))
      const response = await axios.get(`/api/organization/${orgId}`)
      dispatch({ type: REPORT_ORG_FETCH, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}

export const fetchEvents = (org) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-events'))
      const body = JSON.stringify({ name: org })
      const response = await axios.post(
          `/api/ga/events/name`,
          body,
          HEADER_JSON
      )
      const totals = getTotals(response.data)
      const payload = {
        totals: totals,
        events: response.data,
      }
      dispatch({ type: REPORT_EVENTS_FETCH, payload: payload })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error.message)
      dispatch(asyncActionError())
    }
  }
}

export const fetchUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart('fetch-users'))
      const response = await axios.get(`/api/auth/all`)
      dispatch({ type: REPORT_USER_FETCH_ALL, payload: response.data })
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
    }
  }
}