import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, TextArea, Button } from 'semantic-ui-react';

import { closeModal } from '../../../../common/ui/modal/ModalActions';
import { updateUserProfile } from '../../../../common/ui/auth/AuthActions';

class ModalProfileSummary extends Component {
  constructor(props) {
    super(props);
    const { summary } = this.props.user;
    this.state = { summary: summary };
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const val = e.target.value.replace(/[\r\n\v]+/g, '');
    this.setState({
      ...this.state,
      [e.target.name]: val
    });
  }

  handleCloseModal() {
    this.props.closeModal();
  }

  handleSubmit() {
    const { summary } = this.state;
    const form = {
      ...this.props.user,
      summary: summary
    };
    // console.log('form: ', form);
    if (!form.summary || form.summary.length < 0) {
      return;
    }
    this.props.updateUserProfile(form);
    this.props.closeModal();
  }

  render() {
    let { summary } = this.state;
    return (
      <Modal size='mini' open={true} onClose={this.handleCloseModal}>
        <Modal.Header>Professional Summary</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='small' onSubmit={this.handleSubmit}>
              <Form.Field
                name='summary'
                maxLength={512}
                control={TextArea}
                label='Personal Statement'
                value={summary}
                onChange={this.handleChange}
              />
              <Button color='green' type='submit'>
                Save
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  user: state.auth.currentUser
});

const actions = {
  closeModal,
  updateUserProfile
};

export default connect(mapState, actions)(ModalProfileSummary);
