import React, { Component } from 'react'
import { loadArticles, setNewsPageSEO } from '../newsActions'

import { Icon } from 'semantic-ui-react'
import Loading from '../../../common/ui/loading/Loading'
import { connect } from 'react-redux'
import { openModal } from '../../../common/ui/modal/ModalActions'
import { withRouter } from 'react-router-dom'

const mapState = (state) => ({
  articles: state.news.articles,
  loading: state.async.loading,
  loadingName: state.async.elementName,
})

const actions = {
  loadArticles,
  setNewsPageSEO,
  openModal,
}

class NewsMain extends Component {
  componentDidMount() {
    this.props.loadArticles()
    this.props.setNewsPageSEO()
    if (this.props.match.params.token) {
      this.props.openModal('ResetModal', {
        token: decodeURIComponent(this.props.match.params.token),
      })
    }
  }

  createImageSrc = (uid, name) => {
    return `https://spedxchange.s3.us-east-2.amazonaws.com/news/${uid}/${name}-cover.jpg`
  }

  handleArticleClick(article) {
    let externalLink = article.externalLink // external link string

    // Logic:
    //  1. If external link, then open the link in same tab for SEO
    //  2. If no external link, direct to individual article page
    if (externalLink.length !== 0) {
      window.open(externalLink, '_blank')
    } else {
      this.props.history.push({
        pathname: `/news/${article.uid}/${article.slug}`,
      })
    }
  }

  render() {
    const { articles, loading, loadingName } = this.props
    if (loadingName === 'loadArticles' && loading) return <Loading />
    return (
      <>
        <div className='flex-box-wrap'>
          {articles &&
            articles
              .filter((a) => a.status === 'Published')
              .map((article) => (
                <div
                  key={article._id}
                  className='column-3'
                  onClick={() => this.handleArticleClick(article)}>
                  <div>
                    <img
                      src={this.createImageSrc(article.uid, article.photoURL)}
                      alt={article.photoURL}
                    />
                  </div>
                  <h3>{article.title}</h3>
                  <p>{article.summary}</p>
                  <hr />
                  <div>
                    <Icon color='teal' fitted name='tag' />
                    <span>{article.category.text}</span>
                  </div>
                  <hr />
                </div>
              ))}
        </div>
      </>
    )
  }
}

export default connect(mapState, actions)(withRouter(NewsMain))
