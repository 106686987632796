import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Container, List, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { openModal } from "../../common/ui/modal/ModalActions";

const style = {
    maxWidth: '480px',
}

export class PricingPage extends Component {
    constructor(props) {
        super(props)

        this.handleSubscribeButtonClick = this.handleSubscribeButtonClick.bind(this)
    }

    handleSubscribeButtonClick() {
        let authenticated = this.props.auth.authenticated
        if (!authenticated) {
            this.props.history.push('/postjob')
        } else {
            this.props.history.push('/admin')
        }

    }

    render() {
        return (
            <div style={style}>
                <h2 style={{ fontWeight: 'bold' }}>Pricing</h2>
                <p>
                    <h5>
                        Our job board is great for schools who have a strong HR/recruitment team but are looking to target more SPED staff. The job board is more than a recruitment platform, it’s also a school branding tool.
                    </h5>
                    <h5>
                        Click through one of the options below to sign up your organization.
                    </h5>
                    <section id='PaymentBoxes'>
                        <Container style={{display: 'flex', gap: '10px'}}>
                            <div className='call-out-box' style={{'column-width': '300px'}}>
                                <h4><strong>Quarterly Plan</strong></h4>
                                <List as='ul' className='mb-4' style={{'width': '300px'}}>
                                    <List.Item as='li'>
                                        Unlimited job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Enhanced SEO for all job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Positions are “boosted” through email
                                        marketing and targeted social media ads
                                    </List.Item>
                                    <List.Item as='li'>
                                        Up to 5 admin user accounts
                                    </List.Item>
                                    <List.Item as='li'>
                                        Track views and clicks for job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Direct candidates to link of choice
                                    </List.Item>
                                </List>
                                <h4><strong>$650 Every 3 Months</strong></h4>
                                <Button primary onClick={this.handleSubscribeButtonClick}>
                                    Subscribe for Quarterly Payment
                                </Button>
                            </div>
                            <br />
                            <div className='call-out-box' style={{'column-width': '300px'}}>
                                <h4><strong>Semi-Annual Plan</strong></h4>
                                <List as='ul' className='mb-4' style={{'width': '300px'}}>
                                    <List.Item as='li'>
                                        Unlimited job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Enhanced SEO for all job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Positions are “boosted” through email
                                        marketing and targeted social media ads
                                    </List.Item>
                                    <List.Item as='li'>
                                        Up to 5 admin user accounts
                                    </List.Item>
                                    <List.Item as='li'>
                                        Track views and clicks for job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Direct candidates to link of choice
                                    </List.Item>
                                </List>
                                <h4><strong>$1199 Every 6 Months</strong></h4>
                                <h4><strong><mark>(SAVE $100)</mark></strong></h4>
                                <Button primary onClick={this.handleSubscribeButtonClick}>
                                    Subscribe for Semi-Annual Payment
                                </Button>
                            </div>
                            <br/>
                            <div className='call-out-box' style={{'column-width': '300px'}}>
                                <h4><strong>Yearly Plan</strong></h4>
                                <List as='ul' className='mb-4' style={{'width': '300px'}}>
                                    <List.Item as='li'>
                                        Unlimited job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Enhanced SEO for all job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Positions are “boosted” through email
                                        marketing and targeted social media ads
                                    </List.Item>
                                    <List.Item as='li'>
                                        Up to 5 admin user accounts
                                    </List.Item>
                                    <List.Item as='li'>
                                        Track views and clicks for job postings
                                    </List.Item>
                                    <List.Item as='li'>
                                        Direct candidates to link of choice
                                    </List.Item>
                                </List>
                                <h4><strong>$2,000 Every Year</strong></h4>
                                <h4><strong><mark>(SAVE $600)</mark></strong></h4>
                                <Button primary onClick={this.handleSubscribeButtonClick}>
                                    Subscribe for Yearly Payment
                                </Button>
                            </div>
                        </Container>
                    </section>
                    Any further questions? Feel free to contact our sales representative through the options below.
                    <br />
                    <a href='tel:224-300-6643'><Button primary>
                        <Icon name='phone' />
                        Call
                    </Button></a>
                    <a href="mailto: slockett@spedxchange.com"><Button primary>
                        <Icon name='mail' />
                        Email Us
                    </Button></a>
                    <br />
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.async.loading,
    loadingName: state.async.loadingName,
    auth: state.auth,
    org: state.admin.org,
})

const actions = {
    openModal,
}

export default connect(mapStateToProps, actions)(withRouter(PricingPage))
