import React, { Component } from 'react'

import {Button} from "semantic-ui-react";

export class Custom404 extends Component {
  render() {
    return (
        <>
            <center>
                <section id='HomeAdminSection'>
                    <h1 className='ovo'>
                        Whoops!
                    </h1>
                </section>
                <h1 style={{color: "grey"}}>The page you are looking for might have been removed or temporarily unavailable.</h1>
                <div>
                    <Button primary onClick={() => this.props.history.push('/')}>
                        Back to Home
                    </Button>
                </div>
            </center>
        </>

    )

  }
}

export default Custom404
