import { Button, Icon, Label } from 'semantic-ui-react'
import React, { Component } from 'react'
import {fetchLocalJob, fetchSavedJobs, removeSavedJob, updateUserSaveJob} from '../actions/jobsActions'

import Loading from '../../../common/ui/loading/Loading'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import moment from 'moment/moment.js'
import {openModal} from "../../../common/ui/modal/ModalActions";
import {saveApplicantInfo} from "../actions/jobsActions";
import {toastr} from "react-redux-toastr";
import axios from "axios";

export class JobDetails extends Component {
  constructor(props) {
    super(props)
    this.onApplyClick = this.onApplyClick.bind(this)
    this.onSaveJobClick = this.onSaveJobClick.bind(this)
    this.getSavedJobs = this.getSavedJobs.bind(this)
    this.onJobClick = this.onJobClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchLocalJob(this.props.jobId)
    this.getSavedJobs()
  }

  componentDidUpdate(prev) {
    if (this.props.jobId !== prev.jobId) {
      this.props.fetchLocalJob(this.props.jobId)
      this.getSavedJobs()
    }
  }

  onSaveJobClick(jobID){
    if (!this.props.auth.authenticated) {
      this.props.openModal('UnauthModal2');
    }
    else {
      const{ savedJobs } = this.props.userSavedJobs

      let isSavedJob = false
      for (const savedJob of savedJobs){
        if(savedJob._id === jobID){
          isSavedJob = true
        }
      }

      if(isSavedJob){
        // If job is saved already, then un-save.
        let userID = this.props.auth.currentUser._id
        this.props.removeSavedJob(jobID, userID)
        toastr.success('Job Un-Saved!', 'The job has been removed from your Saved Jobs list.')
      } else {
        // If not already saved, then save the job
        let userID = this.props.auth.currentUser._id
        this.props.updateUserSaveJob(jobID, userID)
        toastr.success('Job Saved!', `To view saved jobs, navigate to the "Saved Jobs" page`)
      }
    }
  }

  onApplyClick() {
    let applicantName = this.props.user.displayName;
    let applicantEmail = this.props.user.email;
    let uid = this.props.user._id;
    if(applicantName !== undefined){
      // Applicant already has SPEDxchange Account
      this.props.saveApplicantInfo(applicantName, applicantEmail, this.props.job._id, uid);
      window.location.href = this.props.job.applyLink;
    }
    else{
      // Applicant does not have a SPEDxchange Account
      this.props.openModal('JopApplicantModal', {
        ...this.props,
      })
    }
    // ReactGA.event({
    //   category: this.props.job.organization.name,
    //   action: 'Job Apply Click',
    //   label: `${this.props.job.title} : ${this.props.job._id}`
    // })
  }

  getSavedJobs() {
    if (this.props.auth.authenticated) {
      let userID = this.props.auth.currentUser._id
      this.props.fetchSavedJobs(userID)
    }
  }

  onJobClick = async (jobID) => {
    try {
      await axios.put(`/api/job/view/${jobID}`);
    } catch (error) {
      console.log(error);
      // Handle error if needed
    }
  };



  render() {
    const { loading, loadingEl } = this.props
    if (
      loading ||
      loadingEl === 'fetch-local-jobs' ||
      !this.props.job ||
      !this.props.job._id ||
      !this.props.org ||
      !this.props.org._id
    )
      return <Loading />

    const { name, location } = this.props.org
    const { remote, jobType, title, description, applyLink, updated, salaryAmount } = this.props.job

    let isFavoritedJob = false

    if (this.props.auth.authenticated && this.props.userSavedJobs.savedJobs) {
      const{ savedJobs } = this.props.userSavedJobs
      for (const savedJob of savedJobs){
        if(savedJob._id === this.props.job._id){
          isFavoritedJob = true
        }
      }
    }


    // ReactGA.event({
    //   category: this.props.job.organization.name,
    //   action: 'Job Click',
    //   label: `${this.props.job.title} : ${this.props.job._id}`
    // })

    this.onJobClick(this.props.job._id)
    return (
      <div className='job-post-full'>
        <div className='preview-wrap'>
          <div className='flex-box between'>
            <h4 className='grow pr-5'>{title}</h4>
            {!isFavoritedJob &&
            <div onClick={() =>{this.onSaveJobClick(this.props.job._id)}} className='bookmark-section'>
              <Icon link size='large' color='blue' name='heart outline' />
              <span className='link'>SAVE</span>
            </div>
            }
            {isFavoritedJob &&
              <div onClick={() =>{this.onSaveJobClick(this.props.job._id)}} className='bookmark-section'>
                <Icon link size='large' color='blue' name='heart' />
                <span className='link'>SAVE</span>
              </div>
            }

          </div>
          {applyLink && (
              <div className='apply-section'>
                <Button
                    color='green'
                    content="Apply"
                    onClick={this.onApplyClick}
                    // href={applyLink}
                  >
                </Button>
              </div>
          )}
          <div className='info'>
            <Label>
              <Icon name='building outline' />
              {name}
            </Label>
            {remote ? (
              <Label>
                <Icon name='map marker alternate' />
                Remote, USA
              </Label>
            ) : (
              <>
                {location.city && location.state && location.city.length > 0 && location.state.length > 0 && (
                  <Label>
                    <Icon name='map marker alternate' />
                    {location.city}, {location.state}
                  </Label>
                )}
              </>
            )}
            <Label>
              <Icon name='clock outline' />
              Posted: {moment(updated).from()}
            </Label>
            <Label>
              <Icon name='user outline' />
              {jobType}
            </Label>
            {salaryAmount && (
                <Label>
                  <Icon name='dollar sign' />
                  {salaryAmount}
                </Label>
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  auth: state.auth,
  loading: state.async.loading,
  loadingEl: state.async.elementName,
  job: state.jobs.job,
  org: state.jobs.job.organization,
  userSavedJobs: state.jobs.userSavedJobs,
})

const mapDispatchToProps = {
  fetchLocalJob,
  saveApplicantInfo,
  updateUserSaveJob,
  removeSavedJob,
  openModal,
  fetchSavedJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails)
